import React from 'react';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import {Comments} from "./Comments";
import {Header} from "./Header";

export const CompanyLayout = ({ children }) => {
  const { companyId } = useParams();

  return (
    <React.Fragment>
      <Header  />
      <Row>
        <Col lg="8" className={'pr-lg-2'}>
          {children}
        </Col>
        <Col lg="4" className={'pr-lg-2'}>
          <Comments companyId={companyId} />
        </Col>
      </Row>
    </React.Fragment>
  );
};
