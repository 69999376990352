import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    phone: '',
    company: null,
    companySelected: null,
    type: null,
    typeSelected: null,
    manager: null,
    managerSelected: null,
    dataStart: null,
    dataEnd: null,
};

const {actions, reducer} = createSlice({
    name: 'companyFilterReleases',
    initialState,
    reducers: {
        setPhone: (state, action) => {
            state.phone = action.payload;
        },
        setCompany: (state, action) => {
            const {payload} = action;
            state.company = convertSelectedToString(payload);
            state.companySelected = payload;
        },
        setType: (state, action) => {
            const {payload} = action;
            state.type = convertSelectedToString(payload);
            state.typeSelected = payload;
        },
        setManager: (state, action) => {
            const {payload} = action;
            state.manager = convertSelectedToString(payload);
            state.managerSelected = payload;
        },
        setRangeDataCreate: (state, action) => {
            const {dataStart, dataEnd} = action.payload;
            state.dataStart = dataStart;
            state.dataEnd = dataEnd;
        },
        clearFilters: (state) => {
            state.phone = ''
            state.company = null
            state.companySelected = null
            state.type = null
            state.typeSelected = null
            state.manager = null
            state.managerSelected = null
            state.dataStart = null
            state.dataEnd = null
        },
    }
});

const {
    setPhone,
    setCompany,
    setType,
    setManager,
    setRangeDataCreate,
    clearFilters
} = actions;

export const onClearAllFilters = () => async dispatch => {
    dispatch(clearFilters());
};

export const onChangeLeadFilterPhone = value => async dispatch => {
    dispatch(setPhone(value));
};

export const onChangeLeadFilterCompany = selected => async dispatch => {
    dispatch(setCompany(selected));
};

export const onChangeLeadFilterType = selected => async dispatch => {
    dispatch(setType(selected));
};

export const onChangeLeadFilterManager = selected => async dispatch => {
    dispatch(setManager(selected));
};

export const onChangeLeadFilterRangeDataCreate = (dataStart, dataEnd) => async dispatch => {
    dispatch(setRangeDataCreate({dataStart, dataEnd}));
};

const convertSelectedToString = selected => {
    let value = null;
    if (selected !== null) {
        let result = [];
        selected.map(item => {
            return result.push(item.value);
        });
        value = result.join(',');
    }
    return value;
};

export default reducer;
