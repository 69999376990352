import React from 'react';
import { Form } from 'reactstrap';
import {InputField, Submit} from "../../../../shared";

export const LoginForm = ({ handleSubmit, submitting, valid }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <InputField name="email" label="Email" />
      <InputField name="password" label="Пароль" type="password" />
      <Submit title="Войти" block disabled={submitting || !valid} />
    </Form>
  );
};
