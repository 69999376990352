import React from 'react';
import {
    CardHeader,
    Col,
    Row
} from 'reactstrap';
import {ButtonIcon} from '../../../../shared';

export const Header = ({title, totalCount, onClickAddBtn}) => {

    return (
        <CardHeader className={'bg-light'}>
            <Row className="align-items-center">
                <Col className={'col-auto'}><b className={'mr-2'}>{title}</b><sup>{totalCount}</sup></Col>
                <Col>
                </Col>
                <Col className={`text-right`}>
                    <ButtonIcon
                        icon="plus"
                        transform="shrink-3 down-2"
                        color="falcon-default"
                        size="sm"
                        className="mx-2"
                        onClick={onClickAddBtn}
                    >
                        Добавить
                    </ButtonIcon>
                </Col>
            </Row>
        </CardHeader>
    );
};
