import React, {Fragment} from 'react';
import {
    Card,
    CardBody,
} from 'reactstrap';
import {
    ImagesUploadField,
    InputField,
    QuillEditorField,
} from "../../../../../shared";

export const Content = () => {
    return (
        <Fragment>
            <Card className={'mb-2'}>
                <CardBody>
                    <ImagesUploadField name={'images'}/>
                </CardBody>
            </Card>
            <Card className={'mb-2'}>
                <CardBody>
                    <InputField
                        name={'PROPERTY_SUBTITLE_VALUE'}
                        bsSize={'sm'}
                        label={'Подзаголовок'}
                        className={'mb-2'}
                    />
                    <QuillEditorField name={'DETAIL_TEXT'}/>
                </CardBody>
            </Card>
        </Fragment>
    )
}
