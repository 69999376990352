import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchLeadsList} from "../../../../state/ducks/lead";
import {LeadsTable} from "./LeadsTable";
import {Card} from "reactstrap";
import {Header} from "./Header";
import API from "../../../../api";
import {Dialog, InputField} from "../../../../shared";
import * as Yup from "yup";
import useValidationSchema from "../../../../helpers/use-validation-schema";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const LeadForm = () => {
    return (
        <React.Fragment>
            <InputField
                name="AMOUNT"
                type="number"
                placeholder="Сумма покупки"
            />
            <InputField
                name="COMMENT"
                type="textarea"
                placeholder="Комментарий"
            />

        </React.Fragment>
    );
};

export const IndexPage = () => {
    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = useState(false);
    const [initialValues, setInitialValues] = useState({
        total: '',
        comment: '',
    });

    const {
        phone,
        company,
        type,
        manager,
        dataStart,
        dataEnd,
    } = useSelector(state => state.leadFilter);

    const {
        isLoaded,
        totalCount,
        currentPage,
        perPage,
        leads,
    } = useSelector(state => state.lead);

    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        dispatch(fetchLeadsList(
            currentPage,
            perPage,
            phone,
            company,
            dataStart,
            dataEnd,
            manager,
            type,
        ));
    }, [
        dispatch,
        currentPage,
        perPage,
        phone,
        company,
        dataStart,
        dataEnd,
        manager,
        type,
    ]);

    const handleTableChange = (eventType, tableData) => {
        const {page, sizePerPage} = tableData;
        switch (eventType) {
            case 'pagination':
                dispatch(
                    fetchLeadsList(
                        page,
                        sizePerPage,
                        phone,
                        company,
                        dataStart,
                        dataEnd,
                        manager,
                        type,
                    )
                );
                break;
            default:
                console.log('Не обработано событие таблицы ' + eventType);
        }
    };

    const onEdit = async id => {
        const result = await API.ewayLead.get(id);
        setInitialValues(result)
        setOpenDialog(true);
    }

    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    const schema = Yup.object().shape({
        COMMENT: Yup.string(),
    });

    const validate = useValidationSchema(schema);

    const onSubmit = async values => {
        await API.ewayLead.update(values);
        dispatch(
            fetchLeadsList(
                currentPage,
                perPage,
                phone,
                company,
                dataStart,
                dataEnd,
                manager,
                type,
            )
        );
        setOpenDialog(false);
    };

    const onDelete = async id => {
        const result = window.confirm(`Вы действительно хотите удалить лида?`)
        if (result) {
            await API.ewayLead.delete(id)
            dispatch(
                fetchLeadsList(
                    currentPage,
                    perPage,
                    phone,
                    company,
                    dataStart,
                    dataEnd,
                    manager,
                    type,
                )
            );
        }
    }

    const exportLeads = async () => {
        const request = await API.ewayLead.export(
            phone,
            company,
            dataStart,
            dataEnd,
            manager,
            type,
        );
        if (Array.isArray(request) && request.length > 0) {
            const ws = XLSX.utils.json_to_sheet(request);
            const wb = {Sheets: {data: ws}, SheetNames: ['data']};
            const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, 'leads' + fileExtension);
        }
    }

    const onClickReport = async () => {
        const response = await API.ewayLead.report(
            dataStart,
            dataEnd,
        );
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.zip');
        document.body.appendChild(link);
        link.click();
    }


    return (
        <Card className="mb-3">
            <Dialog
                title={'Добавление поискового запроса'}
                isOpen={openDialog}
                closeDialog={onCloseDialog}
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                formComponent={<LeadForm/>}
            />
            <Header title={'Лиды'}
                    isSelected={isSelected}
                    items={leads}
                    totalCount={totalCount}
                    onClickExport={exportLeads} onClickReport={onClickReport} enableReportBtn={dataStart && dataEnd}/>
            <LeadsTable
                title={'Лиды'}
                items={leads}
                page={currentPage}
                sizePerPage={perPage}
                totalSize={totalCount}
                loading={isLoaded}
                setIsSelected={setIsSelected}
                handleTableChange={handleTableChange}
                onEdit={onEdit}
                onDelete={onDelete}
            />
        </Card>
    );
};
