import React, {createRef} from 'react';
import {Button, CardBody} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import {
    cityFormatter, companyFormatter, createDataFormatter, managerFormatter,
    offerFormatter,
    selectRow,
} from "./helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTrashAlt, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {phoneFormatter} from "../../../../../helpers/table-column-formaters";
import {commentFormatter, offerTypeFormatter} from "./helpers/formaters";
import {useSelector} from "react-redux";
import { pageButtonRenderer, sizePerPageRenderer, paginationTotalRenderer } from '../../../../../helpers/paginations';

const LeadsTable = ({
                        setIsSelected,
                        items,
                        page,
                        sizePerPage,
                        totalSize,
                        loading,
                        handleTableChange,
                        onEdit,
                        onDelete,
                    }) => {
    let table = createRef();

    const {isAdmin, isSuperManager} = useSelector(state => state.auth);

    const onSelect = () => {
        setImmediate(() => {
            setIsSelected(!!table.current.selectionContext.selected.length);
        });
    };

    const statusFormatter = (cell) => {
        switch (cell) {
            case 3:
                return <FontAwesomeIcon style={{color: 'yellow'}} icon={faExclamationCircle}/>
            case 2:
                return <FontAwesomeIcon className={'text-danger'} icon={faExclamationCircle}/>
            case 1:
                return <FontAwesomeIcon className={'text-success'} icon={faExclamationCircle}/>
            default:
                return ''
        }
    };

    const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <React.Fragment>
                {formatExtraData.viewDeleteBtn &&
                <Button
                    color="falcon-danger"
                    size={'sm'}
                    onClick={() => {
                        formatExtraData.onDeleteClick(row.id);
                    }}
                    className="mr-1 mb-1"
                >
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </Button>
                }
                <Button
                    color="falcon-success"
                    size={'sm'}
                    onClick={() => {
                        formatExtraData.onEditClick(row.id);
                    }}
                    className="mr-1 mb-1"
                >
                    <FontAwesomeIcon icon={faPencilAlt}/>
                </Button>
            </React.Fragment>
        );
    };

    const columns = [
        {
            dataField: 'status',
            text: '',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
            formatter: statusFormatter,
        },
        {
            dataField: 'name',
            text: 'Имя',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            dataField: 'phone',
            headerClasses: 'border-0',
            text: 'Телефон',
            classes: 'border-0 py-2 align-middle',
            formatter: phoneFormatter,
        },
        {
            dataField: 'city',
            text: 'Город',
            formatter: cityFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            dataField: 'offer',
            text: 'Предложение',
            formatter: offerFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
        },
        {
            dataField: 'company',
            text: 'Компания',
            formatter: companyFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
        },
        {
            dataField: 'created',
            text: 'Время',
            formatter: createDataFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            dataField: 'type',
            text: 'Тип',
            formatter: offerTypeFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            dataField: 'amount',
            text: 'Сумма покупки',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            hidden: (!isAdmin && !isSuperManager),
            dataField: 'manager',
            formatter: managerFormatter,
            text: 'Менеджер',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            text: '',
            dataField: 'comment',
            formatter: commentFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
            formatExtraData: {
                onEditClick: id => onEdit(id),
            },
        },
        {
            dataField: '',
            headerClasses: 'border-0',
            text: '',
            classes: 'border-0 py-2 align-middle',
            formatter: actionFormatter,
            formatExtraData: {
                onDeleteClick: id => onDelete(id),
                onEditClick: id => onEdit(id),
                viewDeleteBtn: isAdmin
            },
            align: 'right'
        },
    ];

    return <PaginationProvider
        pagination={paginationFactory({
            custom: true,
            page,
            sizePerPage,
            totalSize,
            sizePerPageRenderer,
            pageButtonRenderer,
            paginationTotalRenderer,
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }]
        })}
    >
        {({paginationProps, paginationTableProps}) => {
            return (
                <React.Fragment>
                    <CardBody className="p-0">
                        <div className="table-responsive">
                            <BootstrapTable
                                remote
                                loading={loading}
                                ref={table}
                                bootstrap4
                                keyField="id"
                                data={items}
                                columns={columns}
                                selectRow={selectRow(onSelect, !isAdmin)}
                                bordered={false}
                                classes="table-dashboard table-hover table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                                rowClasses={'btn-reveal-trigger border-top border-200'}
                                headerClasses="bg-200 text-900 border-y border-200"
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                            />
                        </div>
                    </CardBody>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                        <style>{'.pagination{ margin: 0}'}</style>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </React.Fragment>
            );
        }}
    </PaginationProvider>
};

export {LeadsTable}
