import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';

const initialState = {
  items: [],
  isLoaded: false,
  totalCount: 0,
  pageCount: 0,
  currentPage: 1,
  perPage: 50,
  list: []
};
const { actions, reducer } = createSlice({
  name: 'serpTaskReleases',
  initialState,
  reducers: {
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    setPagination: (state, action) => {
      const {page = 1, sizePerPage = 50} = action.payload
      state.currentPage = page;
      state.perPage = sizePerPage;
    },
    setItems: (state, action) => {
      state.items = action.payload['hydra:member'];
      state.totalCount = action.payload['hydra:totalItems'];
    },
    setList: (state, action) => {
      state.list = action.payload;
    }
  }
});

const { setIsLoaded, setItems, setPagination } = actions;

export const setSerpTaskPagination = (page, sizePerPage) => async dispatch => {
  dispatch(setPagination({page, sizePerPage}));
};

export const fetchSerpTaskItems = (page, sizePerPage, order) => async dispatch => {
  dispatch(setIsLoaded(true));
  const request = await API.serpTask.index(page, sizePerPage, order);
  dispatch(setItems(request));
  dispatch(setIsLoaded(false));
};

export default reducer;
