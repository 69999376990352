import instance from '../axiosInstance';

export const companyAgreement = {
    async index(companyId) {
        return await instance().get(
            `/api/agreements`,
            {
                params: {
                    company: companyId
                }
            }
        );
    },
    async create(companyId, values) {
        return await instance().post(`/api/agreements`, values);
    },
    async update(agreementId, values) {
        return await instance().put(agreementId, values);
    },
    async get(agreementId) {
        return await instance().get(agreementId);
    },
    async delete(agreementId) {
        return await instance().delete(agreementId);
    },
    async getFile(agreementId) {
        return await instance().get(`${agreementId}/get-file`, {responseType: "arraybuffer"});
    }
};
