import React, { useEffect, useState } from 'react';
import { LeadCountCard } from './LeadCountCard';
import API from '../../../api';
import { useSelector } from 'react-redux';

export const LeadCountReport = () => {
  const [countLeadCard, setCountLeadCard] = useState(null);

  const {isAdmin, isSuperManager} = useSelector(state => state.auth);

  useEffect(() => {
   /* API.ewayReport.card().then(response => {
      setCountLeadCard(response.data);
    });*/
  }, []);

  const getData = periodName => {
    if (countLeadCard && periodName in countLeadCard) {
      return countLeadCard[periodName];
    }
    return null;
  };

  return (
    <div className="card-deck">
      <LeadCountCard
        id={'all-offer-day'}
        title="Скачано сегодня"
        types={[0, 1, 2, 4]}
        subtitle="Вчера"
        data={getData('day')}
      />
      <LeadCountCard
        id={'all-offer-month'}
        title="За текущий месяц"
        types={[0, 1, 2, 4]}
        subtitle="Прошлый месяц"
        data={getData('month')}
      />
      {(isSuperManager || isAdmin) && (
        <LeadCountCard
          id={'admitad-offer-day'}
          title="Скачано сегодня"
          types={[3, 5]}
          subtitle="Вчера"
          data={getData('day')}
        />
      )}
      {(isSuperManager || isAdmin) && (
        <LeadCountCard
          id={'admitad-offer-month'}
          title="За текущий месяц"
          types={[3, 5]}
          subtitle="Прошлый месяц"
          data={getData('month')}
        />
      )}
    </div>
  );
};
