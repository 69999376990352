import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {Avatar, Flex} from "../../shared";

const nameFormatter = (dataField, { name, patronymic, last_name }) => {
  let avatarName = _.isEmpty(name) ? '' : name[0] + _.isEmpty(last_name) ? '' : last_name[0];
  return (
    <Link to="/users/detail">
      <Media tag={Flex} align="center">
        <Avatar size={'xl'} name={avatarName} isExact={true} />
        <Media body className="ml-2">
          <h5 className="mb-0 fs--1">
            {name} {patronymic} {last_name}
          </h5>
        </Media>
      </Media>
    </Link>
  );
};

const emailFormatter = email => <a href={`mailto:${email}`}>{email}</a>;
const phoneFormatter = phone => <a href={`tel:${phone}`}>{phone}</a>;

const actionFormatter = (dataField, { id }) => (
  // Control your row with this id
  <UncontrolledDropdown>
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right className="border py-2">
      <DropdownItem onClick={() => console.log('Edit: ', id)}>Изменить</DropdownItem>
      <DropdownItem onClick={() => console.log('Delete: ', id)} className="text-danger">
        Удалить
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export { nameFormatter, emailFormatter, phoneFormatter, actionFormatter };
