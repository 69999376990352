import * as React from 'react';
import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';

import {ImageCropperField, InputField, QuillEditorField} from "../../../../shared";

export const ContentForm = ({handleSubmit, submitting, valid, submitLabel = 'Сохранить'}) => {

    return (
        <Form onSubmit={handleSubmit}>
            <Row className={'mb-2'}>
                <Col xs={'3'}>
                    <ImageCropperField name={'IMAGE'}/>
                </Col>
                <Col>
                    <Card className="mb-2">
                        <CardBody>
                            <Row>
                                <Col>
                                    <InputField
                                        label={'Ссылка на страницу с адресами'}
                                        name={'PROPERTY_ADDRESS_LINK_VALUE'}
                                    />
                                    <InputField
                                        label={'Емайл для отправки оповещения в компанию'}
                                        name={'PROPERTY_EMAIL_VALUE'}
                                    />
                                </Col>
                                <Col xs={'auto'} className={'text-right'}>
                                    <Button type={'submit'}
                                            color="falcon-success"
                                            size="sm"
                                            disabled={submitting || !valid}>
                                        {submitLabel}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QuillEditorField
                                        name={'DETAIL_TEXT'}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};
