import {createSlice} from '@reduxjs/toolkit';
import API from '../../api';

const initialState = {
    items: [],
    isLoaded: false,
    totalCount: 0,
    pageCount: 0,
    currentPage: 1,
    perPage: 50,
    list: [],
    filter: {},
    selectedStatuses: [],
    countQuery: 0,
    competition: 0,
    visitors: 0
};
const {actions, reducer} = createSlice({
    name: 'companyReleases',
    initialState,
    reducers: {
        setIsLoaded: (state, action) => {
            state.isLoaded = action.payload;
        },
        setPagination: (state, action) => {
            const {page = 1, sizePerPage = 50} = action.payload
            state.currentPage = page;
            state.perPage = sizePerPage;
        },
        setItems: (state, action) => {
            const {items = [], pagination} = action.payload
            state.items = items;
            state.totalCount = pagination.total;
            state.pageCount = pagination.pages;
            state.perPage = pagination.per_page;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        setStatuses: (state, action) => {
            state.selectedStatuses = action.payload;
        }
    }
});

const {setIsLoaded, setItems, setList, setStatuses, setPagination} = actions;

export const setCompaniesPagination = (page, sizePerPage) => async dispatch => {
    dispatch(setPagination({page, sizePerPage}));
};

export const fetchCompanyItems = (page, sizePerPage, order, filter) => async dispatch => {
    dispatch(setIsLoaded(true));
    const request = await API.company.index(page, sizePerPage, order, filter);
    dispatch(setItems(request));
    dispatch(setIsLoaded(false));
};

export const fetchCompanyList = () => async dispatch => {
    const response = await API.ewayCompany.list();
    let result = [];
    response.map(({id, name}) => {
        return result.push({value: id, label: name});
    })
    dispatch(setList(result));
};

export const setSelectedStatuses = statuses => async dispatch => {
    dispatch(setStatuses(statuses));
};

export default reducer;
