import React from 'react';
import {Link} from 'react-router-dom';

export const offerFormatter = cell => {
    if (cell !== null && typeof cell === 'object') {
        const {ID, NAME} = cell
        const label = NAME && NAME.length > 11 ? NAME.substr(0,11) + '...' : NAME
        return <Link to={`/offers/${ID}`}>{label}</Link>
    }
    return ''
};