import React from 'react';
import { Form } from 'reactstrap';
import {DateField, InputField, PhoneField, Submit} from "../../../../shared";

export const SignUpForm = ({ handleSubmit, submitting, valid }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <InputField name="email" label="Email" type="email" />
      <PhoneField name="phone" />
      <InputField name="name" label="Имя" />
      <InputField name="patronymic" label="Отчество" />
      <InputField name="last_name" label="Фамилия" />
      <DateField name="birthdate" label="Дата рождения" />
      <InputField name="password" label="Пароль" type="password" />
      <InputField name="password_confirm" label="Подтвердите пароль" type="password" />
      <Submit title="Зарегистрировать" block disabled={submitting || !valid} />
    </Form>
  );
};
