import React from 'react';
import {
    CardHeader,
    Col,
    Form,
    Input,
    Row
} from 'reactstrap';
import {ButtonIcon} from '../../../../shared';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {categoriesSetFilterName} from "../../../../state/ducks/categories";

export const Header = ({title, onClickAddBtn}) => {
    const dispatch = useDispatch();
    const {name, total} = useSelector(({categories}) => categories);

    const onChangeName = (value) => {
        dispatch(categoriesSetFilterName(value))
    }

    return (
        <CardHeader className={'bg-light'}>
            <Row className="align-items-center">
                <Col className={'col-auto'}><b className={'mr-2'}>{title}</b><sup>{total}</sup></Col>
                <Col>
                    <Form inline className="search-box">
                        <Input
                            type="search"
                            placeholder="Поиск..."
                            aria-label="Поиск"
                            className="rounded-pill search-input"
                            onChange={({target}) => onChangeName(target.value)}
                            value={name}
                        />
                        <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon"/>
                    </Form>
                </Col>
                <Col className={`text-right`}>
                    <ButtonIcon
                        icon="plus"
                        transform="shrink-3 down-2"
                        color="falcon-default"
                        size="sm"
                        className="mx-2"
                        onClick={onClickAddBtn}
                    >
                        Добавить
                    </ButtonIcon>
                </Col>
            </Row>
        </CardHeader>
    );
};
