import instance from '../importInstance';

export const ewayCompany = {
    async getDataCompany(id) {
        return await instance().get(
            `reports/company-data/${id}`
        )
    },
    async get(id) {
        const response = await instance().get(
            'eway', {
                params: {
                    action: 'amigo:api.manager.company.view',
                    id,
                }
            }
        )
        return response.data
    },
    async create(id, values) {
        const body = new FormData()
        Object.keys(values).map((key) => {
            return body.append(key, values[key])
        })
        const response = await instance().post(
            'eway/publication-company',
            body,
            {
                params: {
                    id,
                }
            }
        )
        return response.data
    },
    async update(values) {
        const body = new FormData()
        Object.keys(values).map((key) => {
            return body.append(key, values[key])
        })
        const response = await instance().post(
            'eway',
            body,
            {
                params: {
                    action: 'amigo:api.manager.company.update',
                }
            }
        )
        return response.data
    },
    async list() {
        const response = await instance().get(
            'eway', {
                params: {
                    action: 'amigo:api.manager.company.list',
                }
            }
        )
        return response.data
    },
    async import() {
        return await instance().get(
            'eway', {
                params: {
                    action: 'amigo:api.manager.company.import',
                }
            }
        )
    },
    async activate(id, status) {
        return await instance().get(
            'eway/activate-company',
            {
                params: {
                    id,
                    status
                }
            }
        )
    },
    async assignManager(id, manager) {
        return await instance().get(
            'eway',
            {
                params: {
                    action: 'amigo:api.manager.company.assignManager',
                    id,
                    manager
                }
            }
        )
    },
};
