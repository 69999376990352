import React from 'react';

import arrayMutators from 'final-form-arrays';
import {Form} from 'react-final-form';
import {Emails} from './Emails';
import API from '../../../../../../api';
import {Button, Col, FormGroup, Row} from 'reactstrap';
import {Field} from './Field';
import {Phones} from './Phones';
import * as Yup from 'yup';
import useValidationSchema from '../../../../../../helpers/use-validation-schema';

export const ContactForm = ({
                                companyId,
                                onClose,
                                onSave,
                                initialValues = {},
                                showCancel = true
                            }) => {
    const onSubmit = async values => {

        let {id, name, position, phones = [], emails = []} = values;

        phones = phones.filter(item => {
            return item !== null && typeof item === 'object' && Object.keys(item).length > 0;
        });

        emails = emails.filter(item => {
            return item !== null && typeof item === 'object' && Object.keys(item).length > 0;
        });

        const details = phones.concat(emails)

        const result = {
            name,
            position,
            details
        };

        if (id) {
            await API.companyContact.update(companyId, id, result);
        } else {
            await API.companyContact.create(companyId, result);
        }
        onSave();
    };

    const schema = Yup.object().shape({
        name: Yup.string().required('Обязательное поле')
    });

    const validate = useValidationSchema(schema);

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            mutators={{
                ...arrayMutators
            }}
            render={({
                         handleSubmit,
                         form: {
                             mutators: {push, pop}
                         }, // injected from final-form-arrays above
                         pristine,
                         form,
                         submitting
                     }) => {
                const onClickCancel = () => {
                    form.reset();
                    onClose();
                };

                return (
                    <form
                        onSubmit={async event => {
                            const error = await handleSubmit(event);
                            if (error) {
                                return error;
                            }
                            form.reset();
                            onClose();
                        }}
                    >
                        <div>
                            <FormGroup>
                                <Field
                                    name="name"
                                    placeholder="Имя Фамилия"
                                    disabled={submitting}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    name="position"
                                    placeholder="Должность"
                                    disabled={submitting}
                                />
                            </FormGroup>
                        </div>

                        <Phones pop={pop} push={push} submitting={submitting}/>
                        <Emails pop={pop} push={push} submitting={submitting}/>

                        <Row className="justify-content-between mb-3">
                            <Col className="col">
                                {showCancel && (
                                    <Button
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={onClickCancel}
                                        disabled={submitting}
                                    >
                                        Отменить
                                    </Button>
                                )}
                            </Col>
                            <Col className="col-auto">
                                <Button
                                    color="falcon-success"
                                    size="sm"
                                    type="submit"
                                    disabled={submitting || pristine}
                                >
                                    Сохранить
                                </Button>
                            </Col>
                        </Row>
                    </form>
                );
            }}
        />
    );
};
