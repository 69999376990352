import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {BasicLayout} from './layouts';
import {ForgetPasswordPage, PasswordResetPage, SignInPage, SignUpPage} from "./pages";

export const AuthRoute = ({component: RouteComponent, layout, ...rest}) => {
    return (
        <Route
            {...rest}
            render={() => (
                <BasicLayout>
                    <RouteComponent/>
                </BasicLayout>
            )}
        />
    );
};

export const Root = () => {
    const {url} = useRouteMatch()
    return (
        <Switch>
            <AuthRoute path={`${url}/sign-in`} exact component={SignInPage}/>
            <AuthRoute path={`${url}/sign-up`} exact component={SignUpPage}/>
            <AuthRoute path={`${url}/forget-password`} exact component={ForgetPasswordPage}/>
            <AuthRoute path={`${url}/password-reset`} exact component={PasswordResetPage}/>
            {/*Redirect*/}
            <Redirect to="/errors/404"/>
        </Switch>
    );
};
