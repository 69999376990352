import * as React from 'react';
import {Button} from 'reactstrap';
import {Link, useRouteMatch} from "react-router-dom";

export const MenuItem = ({to, label}) => {
    const {url} = useRouteMatch()
    return (
        <Button
            tag={Link}
            color="falcon-default"
            active={to === url}
            className="mx-1"
            size="sm"
            to={to}
            icon="chevron-left">
            {label}
        </Button>
    );
};
