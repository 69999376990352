import instance from '../axiosInstance';

export const user = {
  async index(page, sizePerPage, sort) {
    return await instance().get(`/users`, {
      params: {
        page,
        'per-page': sizePerPage,
        sort
      }
    });
  },
  async create(values) {
    return await instance().post(`/users`, values);
  },
  async update(id, values) {
    return await instance().put(`/users/${id}`, values);
  },
  async get(id) {
    return await instance().get(`/users/${id}`);
  },
  async activate(id) {
    return await instance().post(`/users/${id}/activate`);
  },
  async block(id) {
    return await instance().post(`/users/${id}/block`);
  },
  async changePassword(values) {
    return await instance().post(`/user/change-password`, values);
  }
};
