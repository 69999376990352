import instance from '../importInstance';

export const ewayLead = {
    async index(
        page,
        per_page,
        phone = null,
        company = null,
        dataStart = null,
        dataEnd = null,
        manager = null,
        type = null,
    ) {
        const response = await instance().get(
            'eway', {
                params: {
                    action: 'amigo:api.manager.lead.index',
                    page,
                    per_page,
                    phone,
                    company,
                    dateStart: dataStart,
                    dateEnd: dataEnd,
                    manager,
                    type,
                }
            }
        )
        return response.data
    },
    async export(
        phone = null,
        company = null,
        dataStart = null,
        dataEnd = null,
        manager = null,
        type = null,
    ) {
        const response = await instance().get(
            'eway', {
                params: {
                    action: 'amigo:api.manager.lead.export',
                    phone,
                    company,
                    dateStart: dataStart,
                    dateEnd: dataEnd,
                    manager,
                    type,
                }
            }
        )
        return response.data
    },
    async get(id) {
        const response = await instance().get(
            'eway', {
                params: {
                    action: 'amigo:api.manager.lead.view',
                    id,
                }
            }
        )
        return response.data
    },
    async report(
        dataStart = null,
        dataEnd = null,

    ) {
        const response = await instance().get(
            'reports/leads-excel-export-on-company', {
                responseType: 'blob',
                params: {
                    dateStart: dataStart,
                    dateEnd: dataEnd,
                }
            }
        )
        return response
    },
    async update(values) {
        const response = await instance().post(
            'eway',
            values,
            {
                params: {
                    action: 'amigo:api.manager.lead.update',
                }
            }
        )
        return response.data
    },
    async delete(id) {
        const response = await instance().get(
            'eway', {
                params: {
                    action: 'amigo:api.manager.lead.delete',
                    id,
                }
            }
        )
        return response.data
    },

};
