import * as React from 'react';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    ListGroup, ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader
} from 'reactstrap';
import {ButtonIcon} from "../../../../../../shared";
import {useState} from "react";
import API from "../../../../../../api";
import {fetchCompany} from "../../../../../../state/ducks/company";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

export const Manager = ({companyId, manager}) => {
    const dispatch = useDispatch();
    const {full_name} = manager;
    const [isOpen, setIsOpen] = useState(false);
    const [managers, setManagers] = useState([]);

    const {isAdmin, isSuperManager} = useSelector(state => state.auth);

    const closeDialog = () => {
        setIsOpen(false)
    }

    const openDialog = async () => {
        //const {items = []} = await API.manager.index();
        setManagers([])
        setIsOpen(true)
    }

    const assign = async (managerId) => {
        const response = await API.manager.assignCompany(companyId, managerId);
        if (response.hasOwnProperty('success') && response.success) {
            dispatch(fetchCompany(companyId));
        } else {
            toast.error('Ошибка назначения менеджера')
        }
        setIsOpen(false)
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen}>
                <ModalHeader toggle={closeDialog}>Назначить менеджера</ModalHeader>
                <ModalBody className="m-0">
                    <ListGroup>
                        {
                            managers.map(({id, fullName}, index) => {
                                return <ListGroupItem
                                    key={index}
                                    onClick={() => assign(id)}
                                    action>
                                    {fullName}
                                </ListGroupItem>
                            })
                        }
                    </ListGroup>
                </ModalBody>
            </Modal>
            <InputGroup>
                <Input value={full_name} disabled={true} placeholder={'Менеджер'}/>
                <InputGroupAddon addonType="append">
                    <ButtonIcon
                        icon="exchange-alt"
                        transform="shrink-3 down-2"
                        color="falcon-default"
                        size="sm"
                        disabled={!isAdmin && !isSuperManager}
                        onClick={openDialog}
                    />
                </InputGroupAddon>
            </InputGroup>
        </React.Fragment>
    );
};
