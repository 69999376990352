import listReducer from './ducks/list'
import formReducer from './ducks/form'

import * as list from './ducks/list'
import * as form from './ducks/form'

export {list, form}

export default {
    'Offers/list': listReducer,
    'Offers/form': formReducer
}



