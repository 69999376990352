import * as React from 'react';
import {Button, Card, CardBody, Col, Form, Nav, Navbar, Row} from 'reactstrap';
import {useHistory} from "react-router-dom";
import {ImageCropperField, InputField, QuillEditorField, ButtonIcon} from "../../../../shared";

export const ContentForm = ({values, handleSubmit, submitting, valid, isLoading = false}) => {

    const {id, name} = values

    const history = useHistory();

    const handleClickBack = () => {
        history.push(`/brands`);
    };

    const getTitle = () => {
        if (id){
            return 'Редактирование бренда: ' + name
        }
        return 'Добавление нового бренда'
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Navbar color="white" light className={'mb-2'}>
                <Nav navbar className="mr-3">
                    <ButtonIcon
                        icon="chevron-left"
                        transform="shrink-3 down-2"
                        color="falcon-default"
                        size="sm"
                        className="mx-2"
                        onClick={handleClickBack}
                    >
                        Назад
                    </ButtonIcon>
                </Nav>
                <div className={'text-primary'}>
                    {getTitle()}
                </div>
                <Nav navbar className="align-items-center ml-auto">
                    <Button type={'submit'} color="falcon-success" size="sm" disabled={submitting || !valid || isLoading}>
                        Сохранить
                    </Button>
                </Nav>
            </Navbar>
            <Row className={'mb-2'}>
                <Col xs={'3'}>
                    <ImageCropperField name={'logo'}/>
                </Col>
                <Col>
                    <Card className="mb-2">
                        <CardBody>
                            <Row>
                                <Col className={'text-right'}>
                                    <InputField name={'name'}/>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <QuillEditorField height={425} name={'description'}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};
