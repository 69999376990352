import React from 'react';
import {
    Card,
    CardBody,
    Col,
    Row,
} from 'reactstrap';
import {
    InputField,
    SelectField,
} from "../../../../../shared";

export const Config = ({brands, categories, companies}) => {
    const types = [
        {
            value: '',
            label: ''
        },
        {
            value: 'coupon',
            label: 'Купон'
        },
        {
            value: 'promo',
            label: 'Промокод'
        },
        {
            value: 'fast',
            label: 'Быстрокод'
        },
        {
            value: 'admitad',
            label: 'Admitad'
        },
        {
            value: 'draft',
            label: 'Черновик'
        },
        {
            value: 'free',
            label: 'Free'
        }
    ]

    return (
        <Card className={'mb-2'}>
            <CardBody>
                <Row>
                    <Col>
                        <SelectField
                            name="companyId"
                            label={'Компания'}
                            isDisabled={false}
                            isClearable={false}
                            options={companies}
                        />
                        <SelectField
                            name="categories"
                            label={'Категории'}
                            options={categories}
                            isMulti={true}
                        />
                        <SelectField
                            name="brands"
                            label={'Бренды'}
                            options={brands}
                            isMulti={true}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={'number'}
                            name={'discount'}
                            label={'Скидка'}
                        />
                        <SelectField
                            name="type"
                            disabled={false}
                            label={'Тип'}
                            options={types}/>
                        <InputField
                            name={'promoCode'}
                            label={'Промокод'}
                        />
                    </Col>
                    <Col>
                        <InputField
                            name="referralLink"
                            bsSize={''}
                            label={'Реферальная ссылка'}
                        />

                        <React.Fragment>
                            <InputField
                                name={'promoCodeLabel'}
                                bsSize={''}
                                label={'Как называется поле ввода промокода на сайте компании'}
                            />
                        </React.Fragment>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
