import React, {Fragment} from 'react';
import { Label } from 'reactstrap';
import { Field as FinalFormField } from 'react-final-form';
import {DocumentFileInput} from "./DocumentFileInput";

export const FileField = ({ name, label }) => {

  return (
    <Fragment>
      {label && <Label>{label}</Label>}
      <FinalFormField name={name}>
        {({ input, meta }) => (
          <Fragment>
            <DocumentFileInput
              {...input}
              invalid={meta.error && meta.touched}
            />
            {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
          </Fragment>
        )}
      </FinalFormField>
    </Fragment>
  );
};
