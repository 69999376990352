import { auth } from './actions/auth';
import { categories } from './actions/categories';
import { user } from './actions/user';
import { company } from './actions/company';
import { offers } from './actions/offers';
import { lead } from './actions/lead';
import { serpTask } from './actions/serpTask';
import { companyComment } from './actions/companyComment';
import { companyContact } from './actions/companyContact';
import { companyLegals } from './actions/companyLegals';
import { companyAgreement } from './actions/companyAgreement';
import { manager } from './actions/manager';
import { ewayReport } from './actions/ewayReport';
import { ewaySms } from './actions/ewaySms';
import { ewayAuth } from './actions/ewayAuth';
import { ewayOffer } from './actions/ewayOffer';
import { ewayLead } from './actions/ewayLead';
import { projects } from './actions/projects';
import { brands } from './actions/brands';
import { ewayCompany } from './actions/ewayCompany';

const API = {
  auth,
  categories,
  user,
  company,
  offers,
  lead,
  serpTask,
  companyComment,
  companyContact,
  companyLegals,
  companyAgreement,
  manager,
  ewayReport,
  ewaySms,
  ewayAuth,
  ewayOffer,
  ewayLead,
  projects,
  brands,
  ewayCompany,
};

export default API;
