import React from "react";
import {SelectRowInput} from "./SelectRowInput";

export const selectRow = (onSelect, hidden) => {
    return {
        hideSelectColumn: hidden,
        mode: 'checkbox',
        columnClasses: 'py-2 align-middle',
        clickToSelect: false,
        selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
        selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
        headerColumnStyle: { border: 0, verticalAlign: 'middle' },
        selectColumnStyle: { border: 0, verticalAlign: 'middle' },
        onSelect: onSelect,
        onSelectAll: onSelect
    }
};
