import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import {LoginForm} from '../../components';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useValidationSchema from '../../../../helpers/use-validation-schema';
import API from '../../../../api';
import { Form } from 'react-final-form';
import { setUser } from '../../../../state/ducks/auth';
import { useDispatch } from 'react-redux';

export const SignInPage = ({dev = false}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    password: '',
    rememberMe: false
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Обязательное поле')
      .email('Неправильный формат E-mail'),
    password: Yup.string().required('Обязательное поле')
  });

  const validate = useValidationSchema(schema);

  const onSubmit = async values => {
    const { email, password, rememberMe } = values;
    const response = await API.auth.login(email, password, rememberMe);
    if (response){
      console.log(response)
      const { token } = response;
      dispatch(setUser(token, {}));
      history.push('/');
    }
  };

  return (
    <Fragment>
      <Row className="text-left justify-content-between">
        <Col xs="auto">
          <h5>Авторизуйтесь</h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600" />
        </Col>
      </Row>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values, submitting, validating, valid }) => {
          return (
            <LoginForm
              handleSubmit={handleSubmit}
              values={values}
              submitting={submitting}
              validating={validating}
              valid={valid}
            />
          );
        }}
      />
      {
        dev &&
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Link className="fs--1" to={`/auth/sign-up`}>
              Зарегистрировать аккаунт.
            </Link>
          </Col>
          <Col xs="auto">
            <Link className="fs--1" to={`/auth/forget-password`}>
              Забыли пароль?
            </Link>
          </Col>
        </Row>
      }
    </Fragment>
  );
};
