import React from 'react';
import {faCheckSquare, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {STATUS} from "../../constants";
import {ButtonIcon} from "../../../../shared";

export const ActionFormatter = (cell, row, rowIndex, formatExtraData) => {
    const {id, status} = row

    const isBlocked = () => status === STATUS.BLOCKED

    return <ButtonIcon
        icon={isBlocked() ? faWindowClose : faCheckSquare}
        color={isBlocked() ? 'danger' : 'success'}
        size={'sm'}
        onClick={() => {
            if (isBlocked()) {
                formatExtraData.onActivateClick(id);
            } else {
                formatExtraData.onBlockClick(id);
            }
        }}
        className="mr-1"
    />
};