import React from 'react';
import {Button, Media} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

export const Agreement = ({agreement, onClickEdit}) => {

    const {createdAt} = agreement

    return (
        <Media className="mb-1 p-2 hover-actions-trigger align-items-center border">
            <Media body className="ml-3">
                Соглашение от {moment(createdAt).format('DD.MM.YYYY')}
                <div className={'hover-actions r-0 absolute-vertical-center'}>
                    <Button
                        color={'falcon-primary'}
                        size="sm"
                        onClick={() => onClickEdit(agreement['@id'])}
                    >
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                </div>
            </Media>
        </Media>
    );
};
