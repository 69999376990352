import axios from 'axios';
import {toast} from 'react-toastify';

export default (history = null) => {
    const baseURL =
        process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://dev.e-way.market';

    if (localStorage.token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
    }

    const axiosInstance = axios.create({baseURL: baseURL});

    axiosInstance.interceptors.response.use(
        ({data}) =>
            new Promise(resolve => {
                resolve(data);
            }),
        error => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            //const {data, status, config} = error.response
            //if (status === 424) {
            //    if (data.hasOwnProperty('detail')){
            //        toast.error(data.detail);
            //    }else {
            //        toast.error(data.error.message)
            //    }
            //}
            //if (status === 500) {
            //    toast.error(data.message);
            //}
            //if (status === 403 || status === 401) {
            //    localStorage.removeItem('token');
//
            //    if (history) {
            //        history.push('/auth/sign-in');
            //    } else {
            //        if (config.url === '/login_check') {
            //            toast.error('Неверный логин или пароль')
            //        }else {
            //            window.location = '/auth/sign-in';
            //        }
            //    }
            //} else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
           // }
        }
    );
    return axiosInstance;
};
