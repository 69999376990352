import React from 'react';
import {Button, Form, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Form as FinalForm} from 'react-final-form';

export const Dialog = ({
                           title,
                           isOpen,
                           closeDialog,
                           onSubmit,
                           initialValues,
                           validate,
                           formComponent: FormComponent,
                           size = null
                       }) => {
    return (
        <Modal isOpen={isOpen} size={size}>
            <ModalHeader toggle={closeDialog}>{title}</ModalHeader>
            <FinalForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
                render={({handleSubmit, submitting, valid}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <ModalBody className="m-3">{FormComponent}</ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={closeDialog}>
                                    Закрыть
                                </Button>{' '}
                                <Button type={'submit'} color="primary" disabled={submitting || !valid}>
                                    Сохранить
                                </Button>
                            </ModalFooter>
                        </Form>
                    );
                }}
            />
        </Modal>
    );
};
