import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { Loader } from '../../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompany } from '../../../state/ducks/company';
import {Company} from "../components";

export const InfoPage = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const isLoading = useSelector(state => state.company.isLoading);
  const company = useSelector(state => state.company.company);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
  }, [dispatch, companyId]);

  return <React.Fragment>{isLoading ? <Loader /> : <Company company={company} />}</React.Fragment>;
};
