import React from 'react';
import {Card, CardHeader, Col, Row} from 'reactstrap';
import {Dialog, HeaderCreateBtn} from "../../../../shared";
import {UserForm} from "./UserForm";
import {Table} from "./Table";

function getDialogLabel(data){
    const {id} = data
    return id ? 'Изменение пользователя' : 'Добавление пользователя'
}

export const UserTable = ({users, columns, loading, validate, user, isOpen, onCreate, onSubmit, closeDialog}) => {
    return (
        <Card className="mb-3">
            <Dialog
                title={getDialogLabel(user)}
                isOpen={isOpen}
                closeDialog={closeDialog}
                onSubmit={onSubmit}
                initialValues={user}
                validate={validate}
                formComponent={<UserForm/>}
            />
            <CardHeader className={'bg-light'}>
                <Row className="align-items-center">
                    <Col className={'col-auto'}>Менеджеры</Col>
                    <Col/>
                    <Col className={`text-right`}>
                        <HeaderCreateBtn onClick={onCreate}/>
                    </Col>
                </Row>
            </CardHeader>
            <Table loading={loading} users={users} columns={columns}/>
        </Card>
    );
};
