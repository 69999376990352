import {createSlice} from '@reduxjs/toolkit';
import API from '../../api';
import {DEFAULT_PER_PAGE, INIT_PAGINATION} from "../../config";

const initialState = {
    brands: [],
    loading: false,
    total: 0,
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    name: ''
};

const {actions, reducer} = createSlice({
    name: 'brandsReleases',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setBrands: (state, action) => {
            const {
                items = [], pagination = INIT_PAGINATION
            } = action.payload;

            const {page, pages} = pagination

            state.brands = items;
            state.page = (page > pages && pages) ? pages : page;
            state.perPage = pagination.per_page;
            state.total = pagination.total;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setPagination: (state, action) => {
            const {page = 1, perPage = DEFAULT_PER_PAGE} = action.payload;
            state.page = page;
            state.perPage = perPage;
        },
    }
});

const {setLoading, setBrands, setPagination, setName} = actions;

export const fetchBrands = () => async (dispatch, getState) => {
    const {brands} = getState()
    const {name, page, perPage} = brands
    dispatch(setLoading(true));
    const request = await API.brands.index({name, page, per_page: perPage});
    dispatch(setBrands(request));
    dispatch(setLoading(false));
};
export const brandsSetPagination = (page, perPage) => async dispatch => {
    dispatch(setPagination({page, perPage}))
}

export const brandSetFilterName = (name) => async dispatch => {
    dispatch(setName(name))
}

export const brandsChangeActivate = (id, active) => async dispatch => {
    const result = window.confirm(
        active ? 'Деактивировать бренд?' : 'Активировать бренд?'
    )
    if (result) {
        if (active) {
            await API.brands.deactivate(id)
        } else {
            await API.brands.activate(id)
        }
        dispatch(fetchBrands())
    }
}

export const brandsFetchDelete = (id, name) => async dispatch => {
    const result = window.confirm(
        `Вы действительно хотите удалить бренд ${name}?`
    )
    if (result) {
        await API.brands.delete(id)
        dispatch(fetchBrands())
    }
}

export default reducer;
