import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {Field as FinalFormField} from 'react-final-form';

export const InputField = ({name, label, type = 'text', placeholder = null}) => {
    if (!placeholder) {
        placeholder = label;
    }
    return (
        <FormGroup>
            {label && <Label>{label}</Label>}
            <FinalFormField name={name}>
                {({input, meta}) => {

                    const handleChange = (e) => {
                        console.log(e)
                        if (type === 'number') {
                            const value = e.target.value !== '' ? Number(e.target.value) : ''
                            input.onChange(value);
                        } else {
                            input.onChange(e.target.value);
                        }
                    };

                    return <React.Fragment>
                        <Input
                            {...input}
                            onChange={handleChange}
                            type={type}
                            placeholder={placeholder}
                            invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
                    </React.Fragment>
                }}
            </FinalFormField>
        </FormGroup>
    );
};
