import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from "../../../../api";
import {fetchUserList} from "../../../../state/ducks/user";
import {ActionFormatter, NameFormatter, RoleFormatter, UserTable} from "../../components";
import * as Yup from "yup";
import useValidationSchema from "../../../../helpers/use-validation-schema";

export const IndexPage = () => {

    const {isAdmin} = useSelector(state => state.auth);

    const history = useHistory();

    if (!isAdmin) {
        history.push('/');
    }

    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.isLoaded);
    const users = useSelector(state => state.user.items);

    const initialValues = {
        id: null,
    };

    const [isOpen, setOpenDialog] = useState(false);
    const [user, setUser] = useState(initialValues);

    useEffect(() => {
        dispatch(fetchUserList());
    }, [dispatch]);

    const columns = [
        {
            dataField: 'name',
            text: 'Имя',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
            formatter: NameFormatter,
            events: {
                onClick: (e, column, columnIndex, row) => {
                    const {id} = row
                    openUser(id).then()
                }
            }
        },
        {
            dataField: 'role',
            headerClasses: 'border-0',
            text: 'Роль',
            classes: 'border-0 py-2 align-middle',
            formatter: RoleFormatter,
            events: {
                onClick: (e, column, columnIndex, row) => {
                    const {id} = row
                    openUser(id).then()
                }
            }
        },
        {
            width: 100,
            dataField: '',
            text: '',
            formatter: ActionFormatter,
            formatExtraData: {
                onBlockClick: id => blockUser(id),
                onActivateClick: id => activateUser(id)
            },
            align: 'right'
        }
    ];

    const blockUser = async id => {
        if (window.confirm('Заблокировать пользователя?')) {
            await API.user.block(id)
            dispatch(fetchUserList());
        }
    };

    const activateUser = async id => {
        if (window.confirm('Активировать пользователя?')) {
            await API.user.activate(id)
            dispatch(fetchUserList());
        }
    };

    const openUser = async id => {
        const userResponse = await API.user.get(id)
        setUser(userResponse);
        setOpenDialog(true);
    };

    const onCreate = async () => {
        setOpenDialog(true);
    };

    const closeDialog = () => {
        setOpenDialog(false);
        setUser(initialValues);
    };

    const onSubmit = async values => {
        const { id } = values;
        if (id) {
            await API.user.update(id, values);
        } else {
            await API.user.create(values);
        }
        dispatch(fetchUserList());
        closeDialog();
    };

    const schema = Yup.object().shape({
        email: Yup.string()
            .required('Обязательное поле')
            .email('Неправильный формат E-mail'),
        firstName: Yup.string()
            .required('Обязательное поле'),
        lastName: Yup.string()
            .required('Обязательное поле'),
        role: Yup.string()
            .required('Обязательное поле')
    });

    const validate = useValidationSchema(schema);

    return <UserTable
        users={users}
        loading={loading}
        columns={columns}
        validate={validate}
        user={user}
        isOpen={isOpen}
        onCreate={onCreate}
        onSubmit={onSubmit}
        closeDialog={closeDialog}
    />;
};
