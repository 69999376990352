import * as React from 'react';
import { Card, CardBody } from 'reactstrap';
import {FalconCardHeader} from '../../../../../shared';

export const DomainInfo = ({ company }) => {
  const {
    count_query_coupon_en,
    count_query_coupon_ru,
    count_query_promo_en,
    count_query_promo_ru,
    count_query_total,
    visitors,
    competition
  } = company;

  return (
    <Card className={'mb-2'}>
      <FalconCardHeader title="Статистика" titleTag="h6" className="py-2" />
      <CardBody>
        <div className="react-bootstrap-table">
          <table className="table table-bordered table-dashboard table-hover table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap">
            <tbody>
              <tr>
                <td className="font-weight-semi-bold">Количество лидов всего</td>
                <td className="font-weight-semi-bold">?</td>
              </tr>
              <tr>
                <td className="font-weight-semi-bold">Среднее количество лидов в месяц</td>
                <td className="font-weight-semi-bold">?</td>
              </tr>
              <tr>
                <td className="font-weight-semi-bold">Посещаемость сайта</td>
                <td className="font-weight-semi-bold">{visitors}</td>
              </tr>
              <tr>
                <td className="font-weight-semi-bold">Конкуренция</td>
                <td className="font-weight-semi-bold">{competition}</td>
              </tr>
              <tr>
                <td className="font-weight-semi-bold">Общее количество запросов</td>
                <td className="font-weight-semi-bold">{count_query_total}</td>
              </tr>
              <tr>
                <td>Запрос наименование en купон</td>
                <td>{count_query_coupon_en}</td>
              </tr>
              <tr>
                <td>Запрос наименование ru купон</td>
                <td>{count_query_coupon_ru}</td>
              </tr>
              <tr>
                <td>Запрос наименование en промокод</td>
                <td>{count_query_promo_en}</td>
              </tr>
              <tr>
                <td>Запрос наименование ru промокод</td>
                <td>{count_query_promo_ru}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};
