import instance from '../axiosInstance';

const URI = '/api/companies'

export const company = {
  async index(page, sizePerPage, sort, filter) {
    let params = {
      page,
      'itemsPerPage': sizePerPage,
      sort
    };
    for (let key in filter) {
      if (filter.hasOwnProperty(key)) {
        params[`f[${key}]`] = filter[key];
      }
    }
    return await instance().get(URI, { params });
  },
  async publicationInit(id) {
    return await instance().get(`${URI}/${id}/publication`);
  },
  async publish(id, values) {
    return await instance().post(`${URI}/${id}/publish`, values);
  },
  async create(values) {
    return await instance().post(`${URI}`, values);
  },
  async get(id) {
    return await instance().get(`${URI}/${id}`);
  },
  async update(id, values) {
    return await instance().put(`${URI}/${id}`, values);
  },
  async actions(id) {
    return await instance().get(`${URI}/${id}/actions`);
  },
  async startCheck(id, values) {
    return await instance().post(`${URI}/${id}/start_check`, values);
  },
  async list() {
    return await instance().get(`/eway/company`);
  },
  async markToTrash(id) {
    return await instance().post(`/company/${id}/mark-to-trash`);
  },
  async restoreFromTrash(id) {
    return await instance().post(`/company/${id}/restore-from-trash`);
  },
  async delete(id) {
    return await instance().post(`/company/${id}/mark-delete`);
  },
  async markToTrashGroup(values) {
    return await instance().post(`/company/mark-to-trash`, values);
  },
  async restoreFromTrashGroup(values) {
    return await instance().post(`/company/restore-from-trash`, values);
  },
  async deleteGroup(values) {
    return await instance().post(`/company/delete`, values);
  },
  async refusal(id, values) {
    return await instance().post(`/company/${id}/refusal`, values);
  },
  async cancelRefusal(id, values) {
    return await instance().post(`/company/${id}/cancel-refusal`, values);
  }
};
