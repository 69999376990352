import instance from '../axiosInstance';

const URL = 'eway?action=amigo:api.manager.';

export const ewayReport = {
  async managerChart() {
    return await instance().get(`${URL}report.managerChart`);
  },
  async card() {
    return await instance().get(`${URL}report.card`);
  }
};
