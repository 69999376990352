import React from 'react';
import { Button, FormGroup } from 'reactstrap';

export const Submit = ({
  title,
  color = 'primary',
  type = 'submit',
  className = 'mt-3',
  disabled = false,
  ...rest
}) => {
  return (
    <FormGroup>
      <Button color={color} type={type} {...rest} className={className} disabled={disabled}>
        {title}
      </Button>
    </FormGroup>
  );
};
