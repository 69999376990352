import {createSlice} from '@reduxjs/toolkit'
import API from '../../../api';
import {toast} from "react-toastify";

const initialState = {
    item: {PROPERTY_BRANDS_VALUE: [], IMAGES: []},
    selectedCategories: [],
    images: [],
    isLoaded: false,
    isNew: true,
    open: false,
    image: null,
    newImage: null,
    sections: [],
    brands: [],
}

const {actions, reducer} = createSlice({
    name: 'offerReleases',
    initialState,
    reducers: {
        setIsLoaded: (state, action) => {
            state.isLoaded = action.payload
        },
        setItem: (state, action) => {
            const item = action.payload
            item.PROPERTY_DRAFT_VALUE = item.PROPERTY_DRAFT_VALUE === '1'
            item.PROPERTY_FAST_PROMO_CODE_VALUE =
                item.PROPERTY_FAST_PROMO_CODE_VALUE === '1'
            item.PROPERTY_FREE_PROMO_CODE_VALUE =
                item.PROPERTY_FREE_PROMO_CODE_VALUE === '1'
            const {IMAGES, SECTIONS} = item
            state.isNew = !('ID' in item)

            const images = []
            Object.keys(IMAGES).map((id) => {
                return images.push({
                    id,
                    image: IMAGES[id],
                    file: null,
                    isNew: false,
                })
            })

            state.images = images
            state.newImage = null
            state.item = item
            state.selectedCategories = SECTIONS
            state.isLoaded = false
            state.open = true
        },
        clearItem: (state) => {
            state.item = {PROPERTY_BRANDS_VALUE: [], IMAGES: []}
            state.selectedCategories = []
            state.images = []
            state.isNew = true
        },
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setImages: (state, action) => {
            state.images = action.payload
        },
        setSections: (state, action) => {
            state.sections = action.payload
        },
        setBrands: (state, action) => {
            state.brands = action.payload
        },
        deleteImage: (state, action) => {
            state.images = state.images.filter((item) => {
                return item.id !== action.payload
            })
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategories = action.payload
        },
    },
})

export const {
    setItem,
    setIsLoaded,
    setOpen,
    setImages,
    deleteImage,
    setSelectedCategory,
    clearItem,
    setSections,
    setBrands,
} = actions

export const openOfferDialog = (id) => async (dispatch) => {
    dispatch(clearItem())
    dispatch(setIsLoaded(true))
    try {
        const categories = await API.ewayOffer.categoriesTree()
        let categoriesResult = [];
        for (let categoryId in categories) {
            if (categories.hasOwnProperty(categoryId)) {
                const {ID, NAME, CHILDS} = categories[categoryId]
                categoriesResult.push({value: ID, label: NAME});
                if (CHILDS) {
                    for (let childCategoryId in CHILDS) {
                        if (CHILDS.hasOwnProperty(childCategoryId)) {
                            const {ID, NAME} = CHILDS[childCategoryId]
                            categoriesResult.push({value: ID, label: '- ' + NAME});
                        }
                    }
                }
            }
        }
        dispatch(setSections(categoriesResult))
        const brands = await API.ewayBrands.list();
        let brandsResult = [];
        for (let brandId in brands) {
            if (brands.hasOwnProperty(brandId)) {
                const {ID, NAME} = brands[brandId]
                brandsResult.push({value: ID, label: NAME});
            }
        }
        dispatch(setBrands(brandsResult))
        let request = {PROPERTY_BRANDS_VALUE: [], IMAGES: []}
        if (id > 0) {
            request = await API.ewayOffer.get(id)
            const {SECTIONS, PROPERTY_BRANDS_VALUE} = request
            request['sections'] = getValueLabels(SECTIONS, categoriesResult)
            request['brands'] = getValueLabels(PROPERTY_BRANDS_VALUE, brandsResult)
        }
        dispatch(setItem(request))
    } catch (e) {
        dispatch(setIsLoaded(false))
        toast.error('Ошибка получения предложения')
    }
}

function getValueLabels(values, options) {
    const result = [];
    if (values && Array.isArray(values)) {
        values.map(value => {
            return result.push(options.find(o => {
                return o.value === value
            }))
        })
    }
    return result
}

export const fetchSaveOffer = (item, images) => async (dispatch) => {
    dispatch(setIsLoaded(true))
    try {
        const action = 'ID' in item ? 'update' : 'create'
        const request = await API.ewayOffer[action](item, images)
        if ('errors' in request) {
            dispatch(setIsLoaded(false))
            request.errors.map((error) => toast.error(error))
        } else {
            dispatch(setIsLoaded(false))
            dispatch(clearItem())
            dispatch(setOpen(false))
        }
    } catch (e) {
        dispatch(setIsLoaded(false))
        toast.error('Ошибка сохранения предложения')
    }
}

export const closeOfferDialog = () => (dispatch) => {
    dispatch(setOpen(false))
}

export const setOfferImages = (images) => (dispatch) => {
    console.log(images)
    dispatch(setImages(images))
}

export const deleteOfferImage = (id) => (dispatch) => {
    dispatch(deleteImage(id))
}

export default reducer
