import React from 'react'
import {Button, Card, CardBody, CardImg, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import noPhoto from '../../../../../assets/img/no-photo.png';

export const ImageCropperInput = ({value, onChange, label = 'Загрузить изображение', aspectRatio = 1, guides = true}) => {

    const cropper = React.createRef()
    const inputRef = React.createRef()

    const [valueSrc, setImageSrc] = React.useState(null)
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onChangeImage = (e) => {
        e.preventDefault()
        let files
        if (e.dataTransfer) {
            files = e.dataTransfer.files
        } else if (e.target) {
            files = e.target.files
        }
        const reader = new FileReader()
        handleClickOpen()
        reader.onload = () => {
            setImageSrc(reader.result)
        }
        if (files.length > 0) {
            reader.readAsDataURL(files[0])
        }
    }

    const cropImage = () => {
        if (!cropper) {
            return
        }
        const canvas = cropper.current.cropper.getCroppedCanvas()
        if (typeof canvas === 'undefined') {
            return
        }
        onChange(canvas.toDataURL())
        handleClose()
        inputRef.current.value = null
        setImageSrc(null)
    }

    const selectFileClick = (e) => {
        e.preventDefault()
        inputRef.current.click()
    }

    return (
        <React.Fragment>
            <Card>
                <CardImg top width="100%" src={value ? value : noPhoto} alt="Изображение"/>
                <CardBody>
                    <Button onClick={selectFileClick} block>{label}</Button>
                </CardBody>
            </Card>
            <input
                type="file"
                ref={inputRef}
                onChange={onChangeImage}
                hidden accept="image/png, image/gif, image/jpeg"
            />
            <Modal isOpen={open} onClose={handleClose}>
                <ModalHeader>{label}</ModalHeader>
                <ModalBody>
                    <Cropper
                        style={{height: 450}}
                        aspectRatio={aspectRatio}
                        guides={guides}
                        src={valueSrc}
                        ref={cropper}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={cropImage}>Загрузить</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}
