export const version = '0.0.1';
export const navbarBreakPoint = 'xl';

export const DEFAULT_PER_PAGE = 50
export const INIT_PAGINATION = {
    count: 0,
    page: 1,
    pages: 0,
    per_page: DEFAULT_PER_PAGE,
    total: 0,
}