import React, {useState} from "react";
import {Tooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment} from "@fortawesome/free-solid-svg-icons";

const CommentIcon = ({companyId, comment, onClick}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const target = `company-comment-${companyId}`;

    return (
        <React.Fragment>
            <Tooltip placement="right" isOpen={tooltipOpen} target={target} toggle={toggle}>
                <div dangerouslySetInnerHTML={{__html: comment}}/>
            </Tooltip>
            <FontAwesomeIcon id={target} icon={faComment} color={'#2c7be5'}  onClick={onClick}/>
        </React.Fragment>
    );
};

export const commentFormatter = (dataField, {id}, rowIndex, formatExtraData) => {

    const handleClick = () => {
        formatExtraData.onEditClick(id);
    }

    if (dataField) {
        return <CommentIcon companyId={id}
                            comment={dataField}
                            onClick={handleClick}/>;
    }
    return <div>{''}</div>;
};
