import * as React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useEffect, useState} from 'react';
import API from '../../../../../api';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import useValidationSchema from '../../../../../helpers/use-validation-schema';
import {CommentsForm} from './CommentsForm';
import {UserComment} from './UserComment';
import {AuthorComment} from './AuthorComment';
import {CardChatPane} from './CardChatPane';
import {StatusAction} from "./StatusAction";

export const Comments = ({companyId}) => {
    const [comments, setComments] = useState([]);

    const fetchComments = companyId => {
        API.company.actions(companyId).then((response) => {
            setComments(response);
        });
    };

    useEffect(() => {
        fetchComments(companyId);
    }, [companyId]);

    const initialValues = {
        message: ''
    };

    const schema = Yup.object().shape({
        message: Yup.string().required('Обязательное поле')
    });

    const validate = useValidationSchema(schema);

    const onSubmit = async value => {
        const {message} = value;
        try {
            await API.companyComment.create(companyId, {message});
            fetchComments(companyId);
        } catch (e) {
            toast.error('Ошибка добавления комментария');
        }
    };
    return (
        <Card className="card-chat overflow-hidden">
            <CardBody className="d-flex p-0 h-100">
                <div className="card-chat-content">
                    <CardChatPane>
                        {comments.map(({comment, action}, index) => {
                            if (action) {
                                const {set_status} = action
                                if (set_status) {
                                    return <StatusAction key={index} action={action}/>;
                                }

                                return <div key={index}>action</div>;
                            }
                            const {isMe} = comment
                            if (isMe) {
                                return <AuthorComment key={index} comment={comment}/>
                            }
                            return <UserComment key={index} comment={comment}/>
                        })}
                    </CardChatPane>
                    <CommentsForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validate={validate}
                    />
                </div>
            </CardBody>
        </Card>
    );
};

