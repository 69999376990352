import * as React from 'react';
import {Button, Card, CardBody, CardFooter, Col, Row} from 'reactstrap';
import {Form} from 'react-final-form';
import {InputField} from "../../../../shared";
import API from "../../../../api";
import {toast} from "react-toastify";
import * as Yup from "yup";
import useValidationSchema from "../../../../helpers/use-validation-schema";

const initialValues = {
    current: '',
    password: '',
    confirm: '',
}

export const ChangePasswordForm = () => {

    const schema = Yup.object().shape({
        current: Yup.string().required('Обязательное поле'),
        password: Yup.string().required('Обязательное поле'),
        confirm: Yup.string().required('Обязательное поле'),
    });
    const validate = useValidationSchema(schema);
    const onSubmit = async values => {
        const {success, errors} = await API.user.changePassword(values);
        if (success){
            toast.success('Текущий пароль успешно изменен');
        }else {
            for (let error in errors){
                if (errors.hasOwnProperty(error)){
                    toast.error(errors[error]);
                }
            }
        }
    };

    return (
        <Card className={'mb-2'}>
            <Form
                initialValues={initialValues}
                validate={validate}
                onSubmit={onSubmit}
                render={({handleSubmit, submitting, form, pristine}) => (
                    <form
                        onSubmit={async event => {
                            const error = await handleSubmit(event);
                            if (error) {
                                return error;
                            }
                            form.reset();
                        }}
                    >
                        <CardBody>
                            <InputField name="current" type={'password'} placeholder="Текущий пароль"
                                        disabled={submitting}/>
                            <InputField name="password" type={'password'} placeholder="Новый пароль"
                                        disabled={submitting}/>
                            <InputField name="confirm" type={'password'} placeholder="Подтверждение пароля"
                                        disabled={submitting}/>
                        </CardBody>
                        <CardFooter className="bg-light py-2">
                            <Row className="flex-between-center">
                                <Col xs="auto">
                                </Col>
                                <Col xs="auto">
                                    <Button
                                        type="submit"
                                        color="falcon-success"
                                        size="sm"
                                        disabled={submitting || pristine}
                                    >
                                        Сохранить
                                    </Button>
                                </Col>
                            </Row>
                        </CardFooter>
                    </form>
                )}
            />
        </Card>
    );
};
