import React, {useEffect} from 'react';

import {useParams} from 'react-router-dom';
import {Card} from "reactstrap";
import {OffersTableHeader} from "../components";
import {OffersTable} from "../../Offers/components";
import {useDispatch, useSelector} from "react-redux";
import {list} from "../../Offers/store";

export const OffersPage = () => {
    const {companyId} = useParams();

    const dispatch = useDispatch()

    const {page, perPage} = useSelector(list.selectors.pagination)

    useEffect(() => {
        dispatch(list.methods.fetchOffers(companyId))
    }, [dispatch, page, perPage])

    return <Card className="mb-3">
        <OffersTableHeader/>
        <OffersTable />
    </Card>;
};
