import * as React from 'react';
import {Card, CardBody, Collapse} from 'reactstrap';
import {ButtonIcon, FalconCardHeader} from '../../../../../shared';
import {useEffect, useState} from 'react';
import API from '../../../../../api';
import {ContactForm} from './ContactForm';
import {Contact} from './Contact';

export const Contacts = ({companyId}) => {
    const [persons, setPersons] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const fetchContacts = async companyId => {
        const {items = []} = await API.companyContact.index(companyId)

        items.map(item => {
            const {details = []} = item
            item['phones'] = details.map(valueItem => {
                const {phone, comment} = valueItem
                return phone ? {phone, comment} : false
            })

            item['emails'] = details.map(valueItem => {
                const {email, comment} = valueItem
                return email ? {email, comment} : false
            })

            return item
        })

        setPersons(items);
        setIsOpen(items.length === 0);
    };

    useEffect(() => {
        fetchContacts(companyId).then();
    }, [companyId]);

    const onClose = () => {
        setIsOpen(false);
    };

    const onSave = () => {
        fetchContacts(companyId).then();
    };

    return (
        <Card className="mb-2">
            <FalconCardHeader title="Контакты" titleTag="h6" className="py-2">
                <ButtonIcon
                    icon={isOpen ? 'minus' : 'plus'}
                    transform="shrink-3 down-2"
                    color={isOpen ? 'falcon-danger' : 'falcon-default'}
                    size="sm"
                    onClick={() => setIsOpen(!isOpen)}
                />
            </FalconCardHeader>
            <CardBody className="py-2">
                <Collapse isOpen={isOpen}>
                    <ContactForm
                        companyId={companyId}
                        onClose={onClose}
                        onSave={() => onSave()}
                        showCancel={false}
                    />
                </Collapse>
                {persons.map((person, index) => (
                    <Contact
                        companyId={companyId}
                        person={person}
                        key={'person-' + index}
                        onSave={() => onSave()}
                    />
                ))}
            </CardBody>
        </Card>
    );
};
