import React, {useEffect, useState} from 'react';
import {Card, CardBody,} from 'reactstrap';
import {ButtonIcon, FalconCardHeader} from '../../../../../shared';
import API from '../../../../../api';
import {Agreement} from "./Agreement";
import {AgreementsForm} from "./AgreementsForm";

export const Agreements = ({companyId}) => {
    const [agreements, setAgreements] = useState([]);
    const [legals, setLegals] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    const fetchAgreements = companyId => {
        API.companyAgreement.index(companyId).then((response) => {
            const items = response['hydra:member']
            setAgreements(items);
        });
    };

    useEffect(() => {
        //fetchAgreements(companyId);
    }, [companyId]);

    const onClose = () => {
        setIsOpen(false);
        setInitialValues({})
    };

    const onOpen = async (agreementId) => {
        const legalsResponse =  await API.companyLegals.index(companyId)
        let legalsItems = legalsResponse['hydra:member'].map(legalItem => {
            return {
                value: legalItem['@id'],
                label: legalItem['name']
            }
        })
        legalsItems.unshift({
            value: '',
            label: '',
        })
        setLegals(legalsItems)
        if (agreementId) {
            const response = await API.companyAgreement.get(agreementId)
            setInitialValues(response);
        }
        setIsOpen(true);
    };

    const onSave = () => {
        //fetchAgreements(companyId);
    };

    return (
        <Card className="mb-2">
            <FalconCardHeader title="Соглашение" titleTag="h6" className="py-2">
                <ButtonIcon
                    icon={'plus'}
                    transform="shrink-3 down-2"
                    color={'falcon-default'}
                    size="sm"
                    onClick={() => onOpen(null)}
                />
            </FalconCardHeader>
            <CardBody className="py-2">
                <AgreementsForm
                    initialValues={initialValues}
                    legals={legals}
                    companyId={companyId}
                    onClose={onClose}
                    onSave={() => onSave()}
                    showCancel={false}
                    isOpen={isOpen}
                />
                {agreements.map((agreement, index) => (
                    <Agreement
                        companyId={companyId}
                        agreement={agreement}
                        key={'agreement-' + index}
                        onSave={() => onSave()}
                        onClickEdit={onOpen}
                    />
                ))}
            </CardBody>
        </Card>
    );
}