import React from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import { Col, Input, Row } from 'reactstrap';
import { Thumb } from './Thumb';
import { Track } from './Track';

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
`;
export const IntervalSlider = ({
  label,
  min,
  max,
  currentMin,
  currentMax,
  interval,
  onChangeInterval,
  onChangeMinValue,
  onChangeMaxValue
}) => {
  function handleChangeMinValue(value, intervalMax, maxValue) {
    const currentMaxInterval = Math.round((maxValue / 100) * intervalMax);
    if (value > currentMaxInterval) {
      value = currentMaxInterval;
    }
    let minNewValue = 0;
    if (maxValue > 0) {
      minNewValue = Math.round((value / maxValue) * 100);
      if (minNewValue > 100) {
        minNewValue = 100;
      }
    }
    onChangeMinValue(value);
    onChangeInterval([minNewValue, intervalMax]);
  }

  function handleChangeMaxValue(value, intervalMin, minValue, maxValue) {
    const currentMinInterval = Math.round((maxValue / 100) * intervalMin);
    if (value < currentMinInterval) {
      value = currentMinInterval;
    }
    if (value > maxValue) {
      value = maxValue;
    }
    let maxNewValue = 0;
    if (maxValue > 0) {
      maxNewValue = Math.round((value / maxValue) * 100);
      if (maxNewValue > 100) {
        maxNewValue = 100;
      }
    }
    if (value === maxValue) {
      value = '';
    }
    onChangeMaxValue(value);
    onChangeInterval([minValue, maxNewValue]);
  }

  function handleChangeSlider(maxValue, val) {
    let newMinValue = Math.round((max / 100) * val[0]);
    let newMaxValue = Math.round((max / 100) * val[1]);
    if (newMaxValue >= maxValue) {
      newMaxValue = '';
    }
    if (newMinValue <= 0) {
      newMinValue = '';
    }

    onChangeMinValue(newMinValue);
    onChangeMaxValue(newMaxValue);
    onChangeInterval(val);
  }

  return (
    <div className={'p-2'}>
      <div>{label}</div>
      <Row noGutters className={'mb-1'}>
        <Col xs={5}>
          <Input
            name={'min'}
            value={currentMin}
            className={'w-100'}
            bsSize={'sm'}
            onChange={e => handleChangeMinValue(e.target.value, interval[1], max)}
          />
        </Col>
        <Col xs={{ size: 5, offset: 2 }}>
          <Input
            name={'max'}
            value={currentMax}
            className={'w-100'}
            bsSize={'sm'}
            onChange={e => handleChangeMaxValue(e.target.value, interval[0], min, max)}
          />
        </Col>
      </Row>
      <StyledSlider
        className="mt-2"
        value={interval}
        ariaLabel={['Lower thumb', 'Upper thumb']}
        ariaValuetext={state => `Thumb value ${state.valueNow}`}
        pearling
        minDistance={1}
        onChange={val => handleChangeSlider(max, val)}
        renderTrack={Track}
        renderThumb={Thumb}
      />
    </div>
  );
};
