import React from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import ImageUploader from 'react-images-upload';
import {Image} from './Image';
import './Images.css';
import {Field as FinalFormField} from "react-final-form";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 1;

const getItemStyle = (isDragging, draggableStyle) => ({
    width: '150px',
    height: '153px',
    minHeight: '153px',
    userSelect: 'none',
    border: '1px solid #edf2f9',
    padding: grid,
    margin: `0 ${grid}px 0 0`,
    background: isDragging ? 'rgba(44,123,229,.1)' : '#ffffff',
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    minHeight: '153px',
    border: '1px solid #edf2f9',
    background: isDraggingOver ? '#edf2f9' : '#ffffff',
    display: 'flex',
    padding: grid,
    overflow: 'auto'
});

const ImagesUploadField = () => {
    return (
        <FinalFormField name={'images'}>
            {({input, meta}) => {

                const handleChange = (e) => {
                    const data = Array.isArray(e) ? e.map(({id = null, image, name}) => ({id, image, name})) : []
                    input.onChange(data);
                };

                return <React.Fragment>
                    <Input
                        {...input}
                        onChange={handleChange}
                        invalid={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
                </React.Fragment>
            }}
        </FinalFormField>
    )
};

export {ImagesUploadField};

const Input = ({value = [], onChange}) => {

    const [images, setImages] = React.useState(() => {
        return Array.isArray(value) ? value : []
    });

    const changeImages = (i) => {
        setImages(i)
        onChange(i)
    }

    const [countNewItems, setCountNewItems] = React.useState(0);

    const readFile = (file, index) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const image = e.target.result;
                const {name} = file
                const draggableId = 'draggable-' + index
                resolve({draggableId, name, image});
            };
            reader.readAsDataURL(file);
        });
    };

    const onChangeUploader = files => {
        const allFilePromises = [];

        for (let i = 0; i < files.length; i++) {
            allFilePromises.push(readFile(files[i], images.length + countNewItems + i));
        }

        Promise.all(allFilePromises).then(newFilesData => {
            newFilesData.splice(0, countNewItems);
            changeImages([...images, ...newFilesData])
            setCountNewItems(countNewItems + newFilesData.length);
        });
    };

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const itemsResult = reorder(images, result.source.index, result.destination.index);
        changeImages(itemsResult)
    }

    const onRemoveImage = id => {
        const data = images.filter((item) => {
            return item.draggableId !== id
        })

        changeImages(data)
    };

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {images.map((item, index) => (
                                <Draggable key={item.draggableId} draggableId={item.draggableId} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            <Image item={item} onRemove={onRemoveImage}/>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <ImageUploader
                withIcon={false}
                onChange={onChangeUploader}
                imgExtension={['.jpeg', '.jpg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
                withPreview={false}
                buttonText={'Загрузить изображения'}
                withLabel={false}
                buttonClassName={'btn btn-falcon-success btn-sm'}
            />
        </React.Fragment>
    );
}
