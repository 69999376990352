import {Button} from "reactstrap";
import React from "react";

export const pageButtonRenderer = ({ page, active, disabled, title, onPageChange }) => {
    const handleClick = e => {
        e.preventDefault();
        onPageChange(page);
    };
    return (
        <Button
            key={page}
            onClick={handleClick}
            disabled={disabled || active}
            title={title}
            color={active ? 'primary' : 'falcon-primary'}
            size="sm"
            className={'ml-2'}
        >
            {page}
        </Button>
    );
};

export const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div>
        {options.map(option => {
            const active = currSizePerPage === `${option.page}`;
            return (
                <Button
                    key={option.text}
                    type="button"
                    disabled={active}
                    onClick={() => onSizePerPageChange(option.page)}
                    color={active ? 'primary' : 'falcon-primary'}
                    size="sm"
                    className={'ml-2'}
                >
                    {option.text}
                </Button>
            );
        })}
    </div>
);

export const paginationTotalRenderer = (from, to, size) => {
    return size === 0 ? (
        <React.Fragment>{''}</React.Fragment>
    ) : (
        <div className="">
            с {from} по {to} из {size}
        </div>
    );
};