import instance from '../axiosInstance';

const URI = '/api/offers'

export const offers = {
  async index(params) {
    return await instance().get(URI, {params});
  },
  async references() {
    return await instance().get(`${URI}/references`);
  },
  async get(id) {
    return await instance().get(`${URI}/${id}`);
  },
  async create(values) {
    return await instance().post(`${URI}`, values);
  },
  async update(id, values) {
    return await instance().put(`${URI}/${id}`, values);
  },
  async delete(id) {
    return await instance().delete(`${URI}/${id}`);
  },
  async activate(id) {
    return await instance().post(`${URI}/${id}/activate`);
  },
  async deactivate(id) {
    return await instance().post(`${URI}/${id}/deactivate`);
  },
};
