import React, {useEffect, useState} from 'react';

import * as Yup from 'yup';
import {Form} from 'react-final-form';
import useValidationSchema from "../../../../helpers/use-validation-schema";
import {ContentForm} from "../../components";
import {useHistory, useParams} from "react-router-dom";
import API from "../../../../api";

const initialValues = {
    name: '',
    detailText: '',
    image: '',
    active: true
};

export const UpdatePage = () => {
    const history = useHistory();
    const {categoryId} = useParams();

    const [initialItem, setInitialItem] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData(){
            setIsLoading(true)
            const response = await API.categories.get(categoryId)
            setInitialItem(response)
            setIsLoading(false)
        }
        fetchData().then()
    }, [categoryId]);


    const schema = Yup.object().shape({
        name: Yup.string().required('Обязательное поле')
    });

    const validate = useValidationSchema(schema);

    const onSubmit = async values => {
        const {id} = values
        await API.categories.update(id, values)
        history.push(`/categories/`);
    };

    return (
        <React.Fragment>
            <Form
                initialValues={initialItem}
                onSubmit={onSubmit}
                validate={validate}
                render={({handleSubmit, values, submitting, validating, valid}) => {
                    return (
                        <ContentForm
                            isLoading={isLoading}
                            handleSubmit={handleSubmit}
                            values={values}
                            submitting={submitting}
                            validating={validating}
                            valid={valid}
                        />
                    );
                }}
            />
        </React.Fragment>
    );
};
