import * as React from 'react';
import { Button } from 'reactstrap';
import {Dialog, InputField} from '../../../../../../shared';
import { useState } from 'react';
import * as Yup from 'yup';
import useValidationSchema from '../../../../../../helpers/use-validation-schema';
import API from '../../../../../../api';
import { fetchCompany } from '../../../../../../state/ducks/company';
import { useDispatch } from 'react-redux';

export const CompanyRefusal = ({ id, status }) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const schema = Yup.object().shape({
    comment: Yup.string().required('Обязательное поле')
  });

  const isRefused = () => {
    return status === 4;
  };

  const validate = useValidationSchema(schema);

  const onCancelRefusalSubmit = async values => {
    await API.company.cancelRefusal(id, values);
    setOpenDialog(false);
    dispatch(fetchCompany(id));
  };

  const onRefusalSubmit = async values => {
    await API.company.refusal(id, values);
    setOpenDialog(false);
    dispatch(fetchCompany(id));
  };

  const initialValues = {
    comment: ''
  };

  return (
    <React.Fragment>
      <Dialog
        title={isRefused() ? 'Отменить отказ' : 'Оформить отказ'}
        isOpen={openDialog}
        closeDialog={onCloseDialog}
        onSubmit={isRefused() ? onCancelRefusalSubmit : onRefusalSubmit}
        initialValues={initialValues}
        validate={validate}
        formComponent={<InputField name="comment" label="Комментарий" />}
      />
      <Button
        color={'falcon-' + (isRefused() ? 'primary' : 'danger')}
        size="sm"
        className="ml-1"
        onClick={() => setOpenDialog(true)}
      >
        {isRefused() ? 'Отменить отказ' : 'Отказ'}
      </Button>
    </React.Fragment>
  );
};
