import * as React from 'react';
import moment from 'moment';

export const AuthorComment = ({ comment }) => {

  const { text, created } = comment;
  return (
    <div className="d-flex p-3">
      <div className="flex-1 d-flex justify-content-end">
        <div className="w-100 w-xxl-75">
          <div className="hover-actions-trigger d-flex flex-end-center">
            <div className="chat-message p-2 rounded-lg bg-primary text-white">{text}</div>
          </div>
          <div className="text-400 fs--2 text-right">
            {moment(created).format('DD.MM.YYYY HH:mm')}
          </div>
        </div>
      </div>
    </div>
  );
};
