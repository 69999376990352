import React from 'react';
import {
    Col, Form, ModalBody, ModalFooter,
    Row,
} from 'reactstrap';
import {
    ButtonSubmit,
    InputField,
    SelectField,
} from "../../../../../shared";
import {PrevButton} from "../../PrevButton";

const types = [
    {
        value: '',
        label: ''
    },
    {
        value: 'coupon',
        label: 'Купон'
    },
    {
        value: 'promo',
        label: 'Промокод'
    },
    {
        value: 'fast',
        label: 'Быстрокод'
    },
    {
        value: 'admitad',
        label: 'Admitad'
    },
    {
        value: 'draft',
        label: 'Черновик'
    },
    {
        value: 'free',
        label: 'Free'
    }
]

export const OfferConfigForm = ({handleSubmit, submitting, valid, brands, categories, onClickPrev}) => {
    return (
        <Form onSubmit={handleSubmit}>
            <ModalBody className="m-3">
                <Row>
                    <Col>
                        <SelectField
                            name="categories"
                            label={'Категории'}
                            options={categories}
                            isMulti={true}
                        />
                        <SelectField
                            name="brands"
                            label={'Бренды'}
                            options={brands}
                            isMulti={true}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={'number'}
                            name={'discount'}
                            label={'Скидка'}
                        />
                        <SelectField
                            name="type"
                            label={'Тип'}
                            options={types}/>
                        <InputField
                            name={'promoCode'}
                            label={'Промокод'}
                        />
                    </Col>
                    <Col>
                        <InputField
                            name="referralLink"
                            bsSize={''}
                            label={'Реферальная ссылка'}
                        />

                        <React.Fragment>
                            <InputField
                                name={'promoCodeLabel'}
                                bsSize={''}
                                label={'Как называется поле ввода промокода на сайте компании'}
                            />
                        </React.Fragment>
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter className={'d-flex justify-content-between'}>
                <PrevButton onClick={onClickPrev}/>
                <ButtonSubmit label={'Продолжить'} disabled={submitting || !valid}/>
            </ModalFooter>
        </Form>

    );
};