import React from 'react';
import {
    Card, CardBody,
    Form, ModalBody, ModalFooter,
} from 'reactstrap';
import {
    ButtonSubmit, ImagesUploadField,
    InputField, QuillEditorField,
} from "../../../../../shared";
import {PrevButton} from "../../PrevButton";

export const OfferContentForm = ({handleSubmit, submitting, valid, onClickPrev}) => {
    return (
        <Form onSubmit={handleSubmit}>
            <ModalBody className="m-3">
                <Card className={'mb-2'}>
                    <CardBody>
                        <ImagesUploadField name={'images'}/>
                    </CardBody>
                </Card>
                <Card className={'mb-2'}>
                    <CardBody>
                        <InputField
                            name={'subTitle'}
                            bsSize={'sm'}
                            label={'Подзаголовок'}
                            className={'mb-2'}
                        />
                        <QuillEditorField name={'description'}/>
                    </CardBody>
                </Card>

            </ModalBody>
            <ModalFooter className={'d-flex justify-content-between'}>
                <PrevButton onClick={onClickPrev}/>
                <ButtonSubmit label={'Опубликовать'} disabled={submitting || !valid}/>
            </ModalFooter>
        </Form>
    );
};