import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {OffersTable} from "../components";
import {TableHeader} from "../components/TableHeader";
import {Card} from "reactstrap";
import {list} from "../store";

export const IndexPage = () => {

    const dispatch = useDispatch()

    const {page, perPage} = useSelector(list.selectors.pagination)

    useEffect(() => {
        dispatch(list.methods.fetchOffers())
    }, [dispatch, page, perPage])

    return (
        <Card className="mb-3">
            <TableHeader/>
            <OffersTable/>
        </Card>
    );
}