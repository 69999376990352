import * as React from 'react';
import { useState } from 'react';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CheckParamIcon = ({ companyId, checkParam }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const target = `company-check-param-${companyId}`;
  let icon = faCircle;
  let label = 'Не проверялся';
  let color = 'default';
  if (checkParam === 'process') {
    icon = faCheckCircle;
    label = 'В процессе';
  }
  if (checkParam === 'end') {
    icon = faCheckCircle;
    label = 'Проверен';
    color = '#00d27a';
  }
  return (
    <React.Fragment>
      <Tooltip placement="right" isOpen={tooltipOpen} target={target} toggle={toggle}>
        {label}
      </Tooltip>
      <FontAwesomeIcon id={target} icon={icon} color={color} />
    </React.Fragment>
  );
};
