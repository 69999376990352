import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {faCheckCircle, faCircle, faComment} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'reactstrap';
import moment from 'moment';
import {fetchCompanyItems, setCompaniesPagination} from '../../../state/ducks/companies';
import {Dialog, InputField} from '../../../shared';
import * as Yup from 'yup';
import useValidationSchema from '../../../helpers/use-validation-schema';
import API from '../../../api';
import {toast} from 'react-toastify';
import {CompaniesTable, CheckParamIcon} from "../components";

const checkParamFormatter = (dataField, {id}) => {
    return <CheckParamIcon companyId={id} checkParam={dataField}/>;
};

const PublicParamIcon = ({companyId, publicParam}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const target = `company-public-param-${companyId}`;
    let icon = faCircle;
    let label = 'Не опубликован';
    let color = 'default';

    if (publicParam === 'not_active') {
        icon = faCheckCircle;
        label = 'Опубликован - не активен';
    }
    if (publicParam === 'published') {
        icon = faCheckCircle;
        label = 'Опубликован - активен';
        color = '#00d27a';
    }
    return (
        <React.Fragment>
            <Tooltip placement="right" isOpen={tooltipOpen} target={target} toggle={toggle}>
                {label}
            </Tooltip>
            <FontAwesomeIcon id={target} icon={icon} color={color}/>
        </React.Fragment>
    );
};

const publicParamFormatter = (dataField, {id}) => {
    return <PublicParamIcon companyId={id} publicParam={dataField}/>;
};

const CommentIcon = ({companyId, comment}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const target = `company-comment-${companyId}`;

    return (
        <React.Fragment>
            <Tooltip placement="right" isOpen={tooltipOpen} target={target} toggle={toggle}>
                <div dangerouslySetInnerHTML={{__html: comment}}/>
            </Tooltip>
            <FontAwesomeIcon id={target} icon={faComment} color={'#2c7be5'}/>
        </React.Fragment>
    );
};

const commentFormatter = (dataField, {id}) => {
    if (dataField) {
        return <CommentIcon companyId={id} comment={dataField}/>;
    }
    return <div>{''}</div>;
};

const statusFormatter = dataField => {
    let status;
    switch (dataField) {
        case 'looked':
            status = 'Просмотрена';
            break;
        case 'in_work':
            status = 'В работе';
            break;
        case 'lpr':
            status = 'ЛПР';
            break;
        case 'rejection':
            status = 'Отказ';
            break;
        case 'signing_agreement':
            status = 'Подписание';
            break;
        case 'signed_agreement':
            status = 'Договор';
            break;
        case 'mark_deleted':
            status = 'На удаление';
            break;
        default:
            status = 'Новая';
    }
    return <div style={{width: 100}}>{status}</div>;
};

const createdAtFormatter = dataField => {
    return dataField ? moment(dataField).format('DD.MM.YYYY') : '';
};

const checkDataFormatter = dataField => {
    return dataField ? moment(dataField).format('DD.MM.YYYY') : '';
};

const htmlFormatter = dataField => {
    return <div dangerouslySetInnerHTML={{__html: dataField}}/>;
};

const hostFormatter = (dataField, {global_status}) => {
    let colorClass = 'text-primary';
    if (global_status === 20) {
        colorClass = 'text-danger';
    }
    if (global_status === 15) {
        colorClass = 'text-warning';
    }
    return <div className={colorClass}>{dataField}</div>;
};

const AddHostForm = () => {
    return (
        <React.Fragment>
            <InputField name="domain" label="Домен"/>
        </React.Fragment>
    );
};

export const IndexPage = () => {
    const {isAdmin, isSuperManager} = useSelector(state => state.auth);

    const [visibleWordstatTitle, setVisibleWordstatTitle] = useState(() => {
        return localStorage.getItem('companyShowWordstatTitle') === 'true'
    });

    const onChangeVisibleWordstatTitle = () =>{
        localStorage.setItem('companyShowWordstatTitle', !visibleWordstatTitle ? 'true' : 'false')
        setVisibleWordstatTitle(!visibleWordstatTitle)
    }

    const columns = [
        {
            dataField: 'publicationStatus',
            text: '',
            formatter: publicParamFormatter
        },
        {
            dataField: 'publicationDate',
            text: 'Дата публикации',
            formatter: createdAtFormatter
        },
        {
            dataField: 'status',
            text: 'Статус',
            formatter: statusFormatter
        },
        {
            dataField: 'createdAt',
            text: 'Добавлен',
            formatter: createdAtFormatter
        },
        {
            dataField: 'domain',
            text: 'Домен',
            formatter: hostFormatter,
            classes: 'border-0 py-2 align-middle',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setWorkedRowIndex(rowIndex);
                    window.open('//' + row.host, '_blank');
                },
                onMouseEnter: () => {
                    setIsNoActionRowColumn(true);
                },
                onMouseOut: () => {
                    setIsNoActionRowColumn(false);
                }
            }
        },
        {
            hidden: !isAdmin || !visibleWordstatTitle,
            dataField: 'wordstat_title',
            text: 'Заголовок в поисковой выдаче',
            formatter: htmlFormatter
        },
        {
            dataField: 'name',
            text: 'Название',
        },
        {
            dataField: 'promoCodeTitle',
            text: 'Промокод'
        },
        {
            dataField: 'visitors',
            text: 'Посетители',
            sort: true
        },
        {
            dataField: 'competition',
            text: 'Конкуренция',
            sort: true
        },
        {
            dataField: 'countQuery',
            text: 'Запросов всего',
            sort: true
        },
        {
            dataField: 'checkStatus',
            text: '',
            formatter: checkParamFormatter
        },
        {
            dataField: 'checkDate',
            text: 'Дата проверки',
            formatter: checkDataFormatter
        },
        {
            hidden: !isAdmin && !isSuperManager,
            dataField: 'manager',
            text: 'Менеджер',
        },
        {
            dataField: 'comment',
            text: '',
            formatter: commentFormatter
        },
        {
            dataField: 'action',
            text: ''
        }
    ];

    const dispatch = useDispatch();
    const history = useHistory();
    const filterName = useSelector(state => state.companyFilter.name);
    const filterStatus = useSelector(state => state.companyFilter.status);
    const filterParamPublic = useSelector(state => state.companyFilter.paramPublic);
    const filterParamCheck = useSelector(state => state.companyFilter.paramCheck);
    const filterManager = useSelector(state => state.companyFilter.manager);

    const {
        rangeDataCreateStart,
        rangeDataCreateEnd,
        rangeDataCheckStart,
        rangeDataCheckEnd,
        rangeDataPublicStart,
        rangeDataPublicEnd,
        countQueryIntervalMinValue,
        countQueryIntervalMaxValue,
        competitionIntervalMinValue,
        competitionIntervalMaxValue,
        visitorsIntervalMinValue,
        visitorsIntervalMaxValue
    } = useSelector(state => state.companyFilter);

    const loading = useSelector(state => state.companies.isLoaded);
    const totalSize = useSelector(state => state.companies.totalCount);
    const page = useSelector(state => state.companies.currentPage);
    const sizePerPage = useSelector(state => state.companies.perPage);
    const companies = useSelector(state => state.companies.items);

    const [order, setOrder] = useState(null);
    const [workedRowIndex, setWorkedRowIndex] = useState(null);
    const [isNoActionRowColumn, setIsNoActionRowColumn] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);

    const buildColumns = (
        columns,
        classes = 'border-0 py-2 align-middle',
        headerClasses = 'border-0'
    ) => {
        columns.map(column => {
            if (!('classes' in column)) {
                column['classes'] = classes;
            }
            if (!('headerClasses' in column)) {
                column['headerClasses'] = headerClasses;
            }
            return column;
        });
        return columns;
    };

    useEffect(() => {
        dispatch(
            fetchCompanyItems(page, sizePerPage, order, {
                name: filterName,
                status: filterStatus,
                public: filterParamPublic,
                check: filterParamCheck,
                manager_id: filterManager,
                created_start: rangeDataCreateStart,
                created_end: rangeDataCreateEnd,
                check_start: rangeDataCheckStart,
                check_end: rangeDataCheckEnd,
                public_start: rangeDataPublicStart,
                public_end: rangeDataPublicEnd,
                query_min: countQueryIntervalMinValue,
                query_max: countQueryIntervalMaxValue,
                competition_min: competitionIntervalMinValue,
                competition_max: competitionIntervalMaxValue,
                visitors_min: visitorsIntervalMinValue,
                visitors_max: visitorsIntervalMaxValue
            })
        );
    }, [
        dispatch,
        page,
        sizePerPage,
        order,
        filterName,
        filterStatus,
        filterParamPublic,
        filterParamCheck,
        filterManager,
        rangeDataCreateStart,
        rangeDataCreateEnd,
        rangeDataCheckStart,
        rangeDataCheckEnd,
        rangeDataPublicStart,
        rangeDataPublicEnd,
        countQueryIntervalMinValue,
        countQueryIntervalMaxValue,
        competitionIntervalMinValue,
        competitionIntervalMaxValue,
        visitorsIntervalMinValue,
        visitorsIntervalMaxValue
    ]);

    const handleTableChange = (eventType, tableData) => {
        setWorkedRowIndex(null);
        const {page, sizePerPage, sortField, sortOrder} = tableData;
        switch (eventType) {
            case 'pagination':
                dispatch(setCompaniesPagination(page, sizePerPage))
                break;
            case 'sort':
                setOrder(sortOrder === 'desc' ? '-' + sortField : sortField);
                break;
            default:
                console.log('Не обработано событие таблицы ' + eventType);
        }
    };

    const rowEvents = {
        onClick: (e, row) => {
            if (!isNoActionRowColumn) {
                history.push(`/companies/${row.id}`);
            }
        }
    };

    const rowClasses = (row, rowIndex) => {
        let classes = 'btn-reveal-trigger border-top border-200 cursor-pointer';
        if (rowIndex === workedRowIndex) {
            classes += ' eway-worked-row';
        }
        return classes;
    };

    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    function onOpenDialog() {
        setOpenDialog(true);
    }

    const schema = Yup.object().shape({
        domain: Yup.string().required('Обязательное поле')
    });

    const validate = useValidationSchema(schema);

    const onSubmit = async values => {
        const response = await API.company.create(values);
        console.log(response)
        dispatch(
            fetchCompanyItems(page, sizePerPage, order, {
                name: filterName,
                status: filterStatus,
                public: filterParamPublic,
                check: filterParamCheck,
                manager_id: filterManager
            })
        );
        onCloseDialog();
        toast.success(`Компания с доменом: ${values.domain} добавлена`)
    };

    const initialValues = {
        host: ''
    };

    return (
        <React.Fragment>
            <Dialog
                title={'Добавление компании'}
                isOpen={openDialog}
                closeDialog={onCloseDialog}
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                formComponent={<AddHostForm/>}
            />
            <style>{'.eway-worked-row{background-color: rgba(0, 0, 0, 0.05);}'}</style>
            <CompaniesTable
                title={'Компании'}
                items={companies}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                loading={loading}
                columns={buildColumns(columns)}
                rowEvents={rowEvents}
                handleTableChange={handleTableChange}
                rowClasses={rowClasses}
                onClickAddBtn={onOpenDialog}
                visibleWordstatTitle={visibleWordstatTitle}
                onChangeVisibleWordstatTitle={onChangeVisibleWordstatTitle}
            />
        </React.Fragment>
    );
};
