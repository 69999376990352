import instance from '../axiosInstance';

export const auth = {
  async initialized() {
    return await instance().post(`/auth/me`);
  },
  async login(email: any, password: any, rememberMe: any) {
    return await instance().post(`/login_check`, {
      username: email,
      password,
      rememberMe
    });
  },
  async logout() {
    return await instance().post(`/auth/sign-out`);
  },
  async signup(values: undefined) {
    return await instance().post(`/auth/sign-up`, values);
  }
};
