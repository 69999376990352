import { createSlice } from '@reduxjs/toolkit'
import API from "../../../api";
import {toast} from "react-toastify";

const initialState = {
  tree: [],
  isLoaded: false,
}
const { actions, reducer } = createSlice({
  name: 'offerCategoriesReleases',
  initialState,
  reducers: {
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload
    },
    setTree: (state, action) => {
      state.tree = action.payload
      state.isLoaded = false
    },
  },
})

export const { setTree, setIsLoaded } = actions

export const fetchOfferCategoriesTree = () => async (dispatch) => {
  dispatch(setIsLoaded(true))
  try {
    const response = await API.ewayOffer.categoriesTree()
    let result = [];
    for (let brandId in response) {
      if(response.hasOwnProperty(brandId)){
        const {ID, NAME} = response[brandId]
        result.push({ value: ID, label: NAME });
      }
    }
    dispatch(setTree(result))
  } catch (e) {
    dispatch(setIsLoaded(false))
    toast.error('Ошибка получения предложений')
  }
}

export default reducer
