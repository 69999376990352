export const ROLE = {
    USER: "ROLE_USER",
    MANAGER: "ROLE_USER",
    SUPER_MANAGER: "ROLE_SUPER_MANAGER",
    ADMIN: "ROLE_ADMIN"
}

export const ROLE_LABELS = [
    {
        value: ROLE.USER,
        label: 'Пользователь'
    },
    {
        value: ROLE.MANAGER,
        label: 'Менеджер'
    },
    {
        value: ROLE.SUPER_MANAGER,
        label: 'Суперменеджер'
    },
    {
        value: ROLE.ADMIN,
        label: 'Администратор'
    },
]
