import React, { useEffect } from 'react';

import {ContentForm} from "../components";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {fetchCompany} from "../../../state/ducks/company";
import {Form} from "react-final-form";
import * as Yup from "yup";
import useValidationSchema from "../../../helpers/use-validation-schema";
import API from "../../../api";
import {toast} from "react-toastify";

export const ContentPage = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { companyId } = useParams();
  const isLoading = useSelector(state => state.company.isLoading);
  const content = useSelector(state => state.company.content);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
  }, [dispatch, companyId]);


  const schema = Yup.object().shape({
    //name: Yup.string().required('Обязательное поле')
  });

  const validate = useValidationSchema(schema);

  const onSubmit = async (values) => {

    const {IMAGE} = values

    const companyContent = {...values}

    if (IMAGE && IMAGE.indexOf('data:image') === 0){
      companyContent['NEW_IMAGE'] = IMAGE
    }

    const response = await API.ewayCompany.update(companyContent)
    if ('errors' in response && response['errors'].length > 0){
      if ('errors' in response && response['errors'].length > 0) {
        response['errors'].map(error => toast.error(error))
      }
    }else {
      history.push(`/companies/${companyId}`);
    }
  };

  return (
    <React.Fragment>
      {!isLoading &&
      <Form
          initialValues={content}
          onSubmit={onSubmit}
          validate={validate}
          render={({handleSubmit, values, submitting, validating, valid}) => {
            return (
                <ContentForm
                    handleSubmit={handleSubmit}
                    values={values}
                    submitting={submitting}
                    validating={validating}
                    valid={valid}
                />
            );
          }}
      />}
    </React.Fragment>
  );
};
