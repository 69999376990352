import React, {useEffect, useState} from 'react';
import {fetchSerpTaskItems} from '../../../../state/ducks/serpTask';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import {Dialog, ButtonIcon, SelectField, InputField} from '../../../../shared';
import * as Yup from 'yup';
import useValidationSchema from '../../../../helpers/use-validation-schema';
import {pageButtonRenderer, sizePerPageRenderer, paginationTotalRenderer} from '../../../../helpers/paginations';
import API from '../../../../api';
import {toast} from 'react-toastify';

const SerpTaskForm = () => {
    const engineOptions = [
        {value: '', label: ''},
        {value: 'yandex', label: 'Yandex'},
        {value: 'google', label: 'Google'}
    ];

    const deviceOptions = [
        {value: '', label: ''},
        {value: 'mobile', label: 'Мобильное устройство'},
        {value: 'desktop', label: 'Компьютер'}
    ];

    return (
        <React.Fragment>
            <InputField
                name="keywords"
                type="textarea"
                label="Поисковые запросы (каждый с новой строки)"
                placeholder="Поисковые запросы"
            />
            <SelectField name="engine" options={engineOptions} label="Поисковая система"/>
            <SelectField name="device" options={deviceOptions} label="Устройство"/>
        </React.Fragment>
    );
};

/*const keywordsFormatter = (dataField, {keywords}) => {
    return (
        <React.Fragment>
            {keywords.map((keyword, index) => (
                <div key={index}>{keyword.query}</div>
            ))}
        </React.Fragment>
    );
};*/

const statusFormatter = (dataField, {stage}) => {
    const iconClass = stage === 15 ? 'fs--1 text-success' : 'fs--1';
    return <FontAwesomeIcon icon="circle" className={iconClass}/>;
};

const managerFormatter = (dataField, {createdBy}) => {
    if (!createdBy) {
        return '';
    }
    const {full_name} = createdBy;
    return full_name;
};

const dataFormatter = dataField => {
    if (!dataField) {
        return '';
    }
    return (
        <React.Fragment>
            <div>{moment(dataField).format('DD.MM.YYYY')}</div>
            <div className="fs--2">{moment(dataField).format('HH:mm')}</div>
        </React.Fragment>
    );
};

export const IndexPage = () => {

    const {isAdmin, isSuperManager} = useSelector(state => state.auth);

    const columns = [
        {
            dataField: 'status',
            text: 'Статус',
            formatter: statusFormatter
        },
        {
            dataField: 'keywords',
            text: 'Ключевые запросы',
        },
        {
            dataField: 'created',
            text: 'Создан',
            formatter: dataFormatter
        },
        {
            dataField: 'started',
            text: 'Запущен',
            formatter: dataFormatter
        },
        {
            dataField: 'updated',
            text: 'Обновлен',
            formatter: dataFormatter
        },
        {
            dataField: 'engine',
            text: 'Поисковик'
        },
        {
            dataField: 'device',
            text: 'Устройство'
        },
        {
            dataField: 'regionId',
            text: 'Регион'
        },
        {
            dataField: 'newKeywordsCount',
            text: 'Новых ключей'
        },
        {
            dataField: 'checkedKeywordsCount',
            text: 'Проверено новых'
        },
        {
            hidden: !isAdmin && !isSuperManager,
            dataField: 'amount',
            text: 'Стоимость'
        },
        {
            dataField: 'countCompaniesAdded',
            text: 'Компаний',
        },
        {
            hidden: !isAdmin && !isSuperManager,
            dataField: 'createdBy',
            text: 'Менеджер',
            formatter: managerFormatter
        }
    ];

    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(state => state.serpTask.isLoaded);
    const totalSize = useSelector(state => state.serpTask.totalCount);
    const page = useSelector(state => state.serpTask.currentPage);
    const sizePerPage = useSelector(state => state.serpTask.perPage);
    const tasks = useSelector(state => state.serpTask.items);
    const [order, setOrder] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        dispatch(fetchSerpTaskItems(page, sizePerPage, order));
    }, [dispatch, page, sizePerPage, order]);

    const rowEvents = {
        onClick: (e, row) => {
            history.push(`/serp-task/${row.id}`);
        }
    };

    const handleTableChange = (eventType, tableData) => {
        const {page, sizePerPage, sortField, sortOrder} = tableData;
        switch (eventType) {
            case 'pagination':
                dispatch(fetchSerpTaskItems(page, sizePerPage, order));
                break;
            case 'sort':
                setOrder(sortOrder === 'desc' ? '-' + sortField : sortField);
                break;
            default:
                console.log('Не обработано событие таблицы ' + eventType);
        }
    };

    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    function onOpenDialog() {
        setOpenDialog(true);
    }

    const schema = Yup.object().shape({
        keywords: Yup.string().required('Обязательное поле'),
        device: Yup.string().required('Обязательное поле'),
        engine: Yup.string().required('Обязательное поле')
    });

    const validate = useValidationSchema(schema);

    const onSubmit = async values => {

        let {keywords, engine, device} = values;
        keywords = keywords
            .split('\n')
            .map(item => item.trim())
            .filter(item => item.length);
        await API.serpTask.create({keywords, engine, device, regionId: 0, language: 'ru'});
        dispatch(fetchSerpTaskItems(page, sizePerPage, order));
        setOpenDialog(false);
        toast.success('Запрос отправлен')
    };

    const initialValues = {
        keywords: '',
        engine: '',
        device: ''
    };

    return (
        <React.Fragment>
            <Dialog
                title={'Добавление поискового запроса'}
                isOpen={openDialog}
                closeDialog={onCloseDialog}
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                formComponent={<SerpTaskForm/>}
            />
            <Card className="mb-3">
                <CardHeader className={'bg-light'}>
                    <Row className="align-items-center">
                        <Col className={'col-auto'}><b
                            className={'mr-2'}>{'Запросы поисковой выдачи'}</b><sup>{totalSize}</sup></Col>
                        <Col className={`text-right`}>
                            <ButtonIcon
                                icon="plus"
                                transform="shrink-3 down-2"
                                color="falcon-default"
                                size="sm"
                                className="mx-2"
                                onClick={() => onOpenDialog()}
                            >
                                Отправить запрос
                            </ButtonIcon>
                        </Col>
                    </Row>
                </CardHeader>

                <PaginationProvider
                    pagination={paginationFactory({
                        custom: true,
                        page,
                        sizePerPage,
                        totalSize,
                        sizePerPageRenderer,
                        pageButtonRenderer,
                        paginationTotalRenderer,
                        sizePerPageList: [{
                            text: '50', value: 50
                        }, {
                            text: '100', value: 100
                        }, {
                            text: '200', value: 200
                        }]
                    })}
                >
                    {({paginationProps, paginationTableProps}) => {
                        return (
                            <React.Fragment>
                                <CardBody className="p-0">
                                    <div className="table-responsive">
                                        <BootstrapTable
                                            remote
                                            loading={loading}
                                            rowEvents={rowEvents}
                                            bootstrap4
                                            keyField="id"
                                            data={tasks}
                                            columns={columns}
                                            bordered={false}
                                            classes="table-dashboard table-hover table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                                            rowClasses="btn-reveal-trigger border-top border-200"
                                            headerClasses="bg-200 text-900 border-y border-200"
                                            onTableChange={handleTableChange}
                                            {...paginationTableProps}
                                        />
                                    </div>
                                </CardBody>
                                <div className="card-footer d-flex align-items-center justify-content-between">
                                    <style>{'.pagination{ margin: 0}'}</style>
                                    <SizePerPageDropdownStandalone {...paginationProps} />
                                    <PaginationTotalStandalone {...paginationProps} />
                                    <PaginationListStandalone {...paginationProps} />
                                </div>
                            </React.Fragment>
                        );
                    }}
                </PaginationProvider>
            </Card>
        </React.Fragment>
    );
};
