import React, {createRef} from 'react';
import { CardBody} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import { pageButtonRenderer, sizePerPageRenderer, paginationTotalRenderer } from '../../../../helpers/paginations';

export const BrandsTable = ({
                                items,
                                page,
                                sizePerPage,
                                totalSize,
                                loading,
                                columns,
                                rowEvents,
                                handleTableChange,
                                cellEdit,
                            }) => {
    let table = createRef();

    return (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
                sizePerPageRenderer,
                pageButtonRenderer,
                paginationTotalRenderer,
                sizePerPageList: [{
                    text: '50', value: 50
                }, {
                    text: '100', value: 100
                }, {
                    text: '200', value: 200
                }]
            })}
        >
            {({paginationProps, paginationTableProps}) => {
                return (
                    <React.Fragment>
                        <CardBody className="p-0">
                            <div className="table-responsive">
                                <BootstrapTable
                                    remote
                                    loading={loading}
                                    rowEvents={rowEvents}
                                    ref={table}
                                    cellEdit={cellEdit}
                                    bootstrap4
                                    keyField="id"
                                    data={items}
                                    columns={columns}
                                    bordered={false}
                                    classes="table-dashboard table-hover table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                                    rowClasses={'btn-reveal-trigger border-top border-200 cursor-pointer'}
                                    headerClasses="bg-200 text-900 border-y border-200"
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}

                                />
                            </div>
                        </CardBody>
                        <div className="card-footer d-flex align-items-center justify-content-between">
                            <style>{'.pagination{ margin: 0}'}</style>
                            <SizePerPageDropdownStandalone {...paginationProps} />
                            <PaginationTotalStandalone {...paginationProps} />
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </React.Fragment>
                );
            }}
        </PaginationProvider>

    );
};
