import React, {useEffect} from 'react';
import {Form as FinalForm} from "react-final-form";
import {Form} from "../Form";
import {useHistory} from "react-router-dom";
import {form} from '../../store'
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import useValidationSchema from "../../../../helpers/use-validation-schema";

export const OfferForm = ({offerId}) => {

    const dispatch = useDispatch()

    const {loading, companies, categories, brands, item} = useSelector(form.selectors.formData)

    useEffect(() => {
        dispatch(form.methods.init(offerId))
    }, [dispatch, offerId])

    const history = useHistory();

    const onSubmit = (values) => {
        console.log(values)
        //dispatch(form.methods.submit(values, history))
    }

    const schema = Yup.object().shape({
        companyId: Yup.string()
            .required('Обязательное поле'),
        discount: Yup.number()
            .positive('Только положительное число')
            .integer('Только целое число')
            .required('Обязательное поле'),
        type: Yup.string()
            .required('Обязательное поле'),
    });

    const validateSchema = useValidationSchema(schema);

    const onClickBack = () => {
        history.goBack();
    }

    return <FinalForm
        initialValues={item}
        onSubmit={onSubmit}
        validate={validateSchema}
        render={({handleSubmit, values, submitting, validating, valid}) => {
            return (
                <Form
                    brands={brands}
                    companies={companies}
                    categories={categories}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    values={values}
                    submitting={submitting}
                    validating={validating}
                    valid={valid}
                    onClickBack={onClickBack}
                />
            );
        }}
    />
};
