import React from 'react';
import { Card, CardBody } from 'reactstrap';

import corner1 from '../../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../../assets/img/illustrations/corner-3.png';

import { TypeLeadIcon } from './TypeLeadIcon';
import {Background} from "../../../../shared";

const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};

const getContentClassNames = color => {
  const contentClassNames = 'display-4 fs-4 mb-2 font-weight-normal text-sans-serif';
  if (color === 'success') return contentClassNames;
  return `${contentClassNames} text-${color}`;
};

export const LeadCountCard = ({ id, title, types, subtitle, data }) => {
  const color = 'success';
  const getCountInPeriodForTypes = (period, typeLead = null) => {
    let count = 0;
    if (data && Array.isArray(data) && data.length > period) {
      const periodData = data[period];
      types.forEach(element => {
        if (periodData && periodData instanceof Object && element in periodData) {
          if (!typeLead || typeLead === element) {
            count += periodData[element];
          }
        }
      });
    }
    return count;
  };

  const getCountInPeriodForType = (period, typeLead) => {
    if (data && Array.isArray(data) && data.length > period) {
      const periodData = data[period];

      if (periodData && periodData instanceof Object && typeLead in periodData) {
        return periodData[typeLead];
      }
    }
    return 0;
  };

  return (
    <React.Fragment>
      <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
        <Background image={getImage(color)} className="bg-card" />
        <CardBody className="position-relative">
          <div className="row justify-content-between">
            <div className="col-auto">
              <h6>{title}</h6>
            </div>
            <div className="col-auto">
              {types.map(typeLead => {
                const countInPeriodForType = getCountInPeriodForType(0, typeLead);
                const iconId = id + '-icon-type-' + typeLead;
                return (
                  <TypeLeadIcon
                    key={iconId}
                    id={iconId}
                    typeLead={typeLead}
                    count={countInPeriodForType}
                  />
                );
              })}
            </div>
          </div>
          <div className={getContentClassNames(color)}>{getCountInPeriodForTypes(0)}</div>
          <div>
            {getCountInPeriodForTypes(1)}
            <span className={'ml-2 fs--1'}>{subtitle}</span>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
