import {createSlice} from '@reduxjs/toolkit';
import API from '../../api';

const initialState = {
    company: {},
    content: {},
    isLoading: false
};

const {actions, reducer} = createSlice({
    name: 'companyReleases',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setCompanyContent: (state, action) => {
            state.content = action.payload;
        }
    }
});

const {setIsLoading, setCompany, setCompanyContent} = actions;

export const fetchCompany = id => async dispatch => {
    dispatch(setIsLoading(true));
    const response = await API.company.get(id);
    const {external_id} = response
    let companyContent = {}
    if (external_id){
        companyContent = await API.ewayCompany.get(external_id)
    }
    dispatch(  setCompanyContent(companyContent));
    dispatch(setCompany(response));
    dispatch(setIsLoading(false));
};

export const fetchMarkFromTrashCompany = id => async dispatch => {
    dispatch(setIsLoading(true));
    const response = await API.company.markToTrash(id);
    dispatch(setCompany(response));
    dispatch(setIsLoading(false));
};

export const fetchRestoreFromTrashCompany = id => async dispatch => {
    dispatch(setIsLoading(true));
    const response = await API.company.restoreFromTrash(id);
    dispatch(setCompany(response));
    dispatch(setIsLoading(false));
};

export const fetchDeleteCompany = id => async dispatch => {
    dispatch(setIsLoading(true));
    const response = await API.company.get(id);
    dispatch(setCompany(response));
    dispatch(setIsLoading(false));
};

export default reducer;
