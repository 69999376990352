import React from 'react';
import styled from 'styled-components';

const StyledThumb = styled.div`
  margin-top: -4px;
  height: 14px;
  width: 14px;
  color: #fff;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(43 45 80 / 10%) 0px 0px 0px 1px, rgb(43 45 80 / 8%) 0px 2px 5px 0px,
    rgb(0 0 0 / 7%) 0px 1px 1.5px 0px, rgb(0 0 0 / 8%) 0px 1px 2px 0px;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  cursor: grab;
  z-index: 0 !important;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #e6e6e6;
    border-color: #dfdfdf;
  }
`;

export const Thumb = props => <StyledThumb {...props}>{''}</StyledThumb>;
