import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field as FinalFormField } from 'react-final-form';
import InputMask from 'react-input-mask';

export const PhoneField = ({ name, label = 'Телефон', type = 'tel', placeholder = null }) => {
  if (!placeholder) {
    placeholder = label;
  }
  return (
    <FormGroup>
      <Label>{label}</Label>
      <FinalFormField name={name}>
        {({ input, meta }) => {
          return (
            <React.Fragment>
              <ProneInputMask
                {...input}
                type={type}
                placeholder={placeholder}
                invalid={meta.error}
              />
              {meta.error && <small className={'text-danger'}>{meta.error}</small>}
            </React.Fragment>
          );
        }}
      </FinalFormField>
    </FormGroup>
  );
};

const ProneInputMask = props => (
  <InputMask mask="+7(999)999-99-99" value={props.value} onChange={props.onChange}>
    {inputProps => <Input {...inputProps} type="tel" />}
  </InputMask>
);
