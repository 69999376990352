import * as React from 'react';

export const CardChatPane = ({ children }) => (
  <div className="card-chat-pane">
    <div className="chat-content-header">
      <div className="row flex-between-center">
        <div className="col-6 col-sm-8 d-flex align-items-center">
          <div className="min-w-0">
            <h5 className="mb-0 text-truncate fs-0">Лента событий</h5>
          </div>
        </div>
      </div>
    </div>
    <div className="chat-content-body">
      <div className="chat-content-scroll-area scrollbar perfect-scrollbar">{children}</div>
    </div>
  </div>
);
