import auth from './ducks/auth';
import brands from './ducks/brands';
import categories from './ducks/categories';
import user from './ducks/user';
import companies from './ducks/companies';
import company from './ducks/company';

import offerCategories from './ducks/offers/categories'
import lead from './ducks/lead';
import leadFilter from './ducks/leadFilter';
import serpTask from './ducks/serpTask';
import companyFilter from './ducks/companyFilter';
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';

import {offers} from '../modules/Offers'

console.log(offers)

const middleware = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
});

export const store = configureStore({
    reducer: {
        auth,
        brands,
        categories,
        user,
        companies,
        company,
        companyFilter,
        offerCategories,
        lead,
        leadFilter,
        serpTask,
        ...offers
    },
    middleware,
    devTools: process.env.NODE_ENV !== 'production'
});