import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initialized } from '../../state/ducks/auth';
import { Loader } from '../../shared';

export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const dispatch = useDispatch();
  const isAppInit = useSelector(state => state.auth.isAppInit);
  const isAuthorized = useSelector(state => state.auth.isAuthorized);

  if (!isAppInit) {
    dispatch(initialized());
    return <Loader />;
  }

  if (!isAuthorized) {
    return <Redirect to={'/auth/sign-in'} />;
  }

  return <Route {...rest} component={RouteComponent} />;
};
