import React, {useState} from 'react';
import {ButtonIcon} from "../ButtonIcon";
import API from "../../api";
import {count} from "echarts/lib/component/dataZoom/history";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


const types = {
    0: 'Купон',
    1: 'Промокод',
    2: 'Быстрокод',
    3: 'Адмитад',
    4: 'Черновик',
    5: 'Free'
};

const Import = () => {

    const [companyImporting, setCompanyImporting] = useState(false);
    const [companyKey, setCompanyKey] = useState(0);
    const [status, setStatus] = useState('');

    const getManagers = async () => {
        const {items} = await API.manager.index();
        const result = {}
        items.map(item => {
            result[item.external_id] = item.fullName
            return true
        })
        return result
    }

    const getCategoriesData = (categories, result = {}) => {
        for (let key in categories) {
            const {ID, NAME, CHILDS} = categories[key]
            result[ID] = NAME
            if (CHILDS) {
                result = getCategoriesData(CHILDS, result)
            }
        }
        return result
    }

    const getCategories = async () => {
        const data = await API.ewayOffer.categoriesTree();
        return getCategoriesData(data)
    }

    const getOffersData = async (id, categories) => {
        const {data} = await API.ewayOffer.index(1, 500, id);
        let offerType = null
        let leads = 0
        let sections = []
        for (let key in data) {
            offerType = data[key].type
            leads += data[key].leadsCount

            const {SECTIONS} = await API.ewayOffer.get(data[key].ID);
            SECTIONS.map(section => {
                sections.push(categories[section])
            })
        }
        sections = sections.filter((val, i, ar) => ar.indexOf(val) === i);
        return {
            type: offerType ? types[offerType] : '',
            categories: sections.join(', '),
            leads
        };
    }

    const agreementDataToString = ({conditions = [], file = []}) => {

        let agreementConditions = conditions.map(({agent_commission, user_discount}) => {
            return `Скидка: ${user_discount}, Агенту: ${agent_commission}`
        })

        return {
            agreementConditions: agreementConditions.join(', '),
            agreementFiles: file.join(', ')
        }
    }

    const getAgreementsAndContacts = async (id) => {
        let {agreements = [], contacts = []} = await API.ewayCompany.getDataCompany(id);

        contacts = contacts.map(({name, emails, phones}) => {
            return name + ': '
            + (emails.length > 0 ? 'Email: ' + emails.join(', ') + ' ' : '')
            + (phones.length > 0 ? 'телефон: ' + phones.join(', ') : '')
        })

        let agreement = agreements.pop()
        agreement = agreement ? agreement : {}

        return {
            contacts: contacts.join(' '),
            ...agreementDataToString(agreement)
        }

    }


    const importCompanies = async () => {

        setCompanyImporting(true)
        setStatus('В работе')
        const managers = await getManagers()
        const categories = await getCategories()

        const {data} = await API.ewayCompany.import();

        const companies = []

        for (let key in data) {
            setCompanyKey(key)

            const {id, name, manager, dateCreate} = data[key]


            const agreementsAndContacts = await getAgreementsAndContacts(id)

            const leads = await API.ewayLead.index(
                1,
                1,
                '',
                id,
                '01.10.2021',
                '31.12.2021'
            )

            const offersData = await getOffersData(id, categories)

            let company = {
                id,
                name,
                ...offersData,
                leads4: leads.items.total,
                ...agreementsAndContacts,
                manager: managers[manager],
                created: dateCreate
            }
            companies.push(company)
        }
        setCompanyKey(0)
        const ws = XLSX.utils.json_to_sheet(companies);
        const wb = {Sheets: {data: ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const dataBlob = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(dataBlob, 'companies' + fileExtension);
        setStatus(`Обработано ${count(companies)} компаний`)
        setCompanyImporting(false)
    }


    return (
        <React.Fragment>
            <ButtonIcon
                color="falcon-default"
                size="sm"
                icon="redo"
                transform="shrink-3"
                disabled={companyImporting}
                onClick={() => importCompanies()}
            >
                Отчет {companyKey}
            </ButtonIcon>
            <div>
                {status}
            </div>
        </React.Fragment>
    );
};
export {Import};
