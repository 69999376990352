import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API from '../../../../api';
import { Card, CardBody, Col, Nav, Navbar, Row } from 'reactstrap';
import {ButtonIcon , Loader} from '../../../../shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';

const Manager = ({ manager }) => {
  const { full_name } = manager;
  return (
    <React.Fragment>
      Менеджер: <strong>{full_name}</strong>
    </React.Fragment>
  );
};

const Status = ({ stage }) => {
  const iconClass = stage === 15 ? 'fs--1 text-success' : 'fs--1';
  return <FontAwesomeIcon icon="circle" className={iconClass} />;
};

const htmlFormatter = dataField => {
  return <div dangerouslySetInnerHTML={{ __html: dataField }} />;
};

const domainColumns = [
  {
    dataField: 'host',
    text: 'Домен'
  },
  {
    dataField: 'title',
    text: 'Title',
    formatter: htmlFormatter
  },
  {
    dataField: 'snippet',
    text: 'Snippet',
    formatter: htmlFormatter
  }
];

const Keyword = ({ keyword }) => {
  const { count, items, query, relatedKeywords } = keyword;

  return (
    <Card className={'mb-2'}>
      <CardBody>
        <div>
          Запрос: <strong>{query}</strong>
        </div>
        <div className="fs--1">Количество в поиске: {count}</div>
        <BootstrapTable
          keyField="id"
          data={items}
          classes="table-dashboard table-hover table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
          columns={domainColumns}
        />
        <div>
          <h5>Связанные поисковые запросы</h5>
          <ul>
            {relatedKeywords.map((relatedKeyword, index) => (
              <li key={index}>{relatedKeyword.query}</li>
            ))}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

const SerpTask = ({ serpTask }) => {
  const {
    device,
    engine,
    regionId,
    checkedKeywordsCount,
    newKeywordsCount,
    keywords,
    stage,
    created,
    started,
    updated,
    amount,
    createdBy
  } = serpTask;

  const history = useHistory();

  const handleClickBack = () => {
    history.goBack();
  };

  const formatDate = value => {
    return value;
  };

  return (
    <React.Fragment>
      <Navbar color="white" light className={'mb-2'}>
        <Nav navbar className="mr-3">
          <ButtonIcon
            icon="chevron-left"
            transform="shrink-3 down-2"
            color="falcon-default"
            size="sm"
            className="mx-2"
            onClick={handleClickBack}
          >
            Назад
          </ButtonIcon>
        </Nav>
        <div>
          <Status stage={stage} /> Топ 100 поисковой выдачи
        </div>
        <div className="align-items-center ml-auto">
          {createdBy && <Manager manager={createdBy} />}
        </div>
      </Navbar>
      <Card className={'mb-2'}>
        <CardBody>
          <Row>
            <Col>
              <div>Создан: {formatDate(created)}</div>
              <div>Запущен: {formatDate(started)}</div>
              <div>Обновлен: {formatDate(updated)}</div>
            </Col>
            <Col>
              <div>Поисковая система: {engine}</div>
              <div>Устройство: {device}</div>
              <div>Регион: {regionId}</div>
            </Col>
            <Col>
              <div>Новых поисковых запросов: {newKeywordsCount}</div>
              <div>Проверено новых поисковых запросов: {checkedKeywordsCount}</div>
              <div>Стоимость: {amount}</div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {keywords.map((keyword, index) => (
        <Keyword key={index} keyword={keyword} />
      ))}
    </React.Fragment>
  );
};

export const ViewPage = () => {
  const { serpTaskId } = useParams();
  const [serpTask, setSerpTask] = useState(null);

  useEffect(() => {
    API.serpTask.get(serpTaskId).then(response => {
      setSerpTask(response);
    });
  }, [serpTaskId]);

  const getContent = () => {
    if (!serpTask) {
      return <Loader />;
    }
    return <SerpTask serpTask={serpTask} />;
  };

  return <React.Fragment>{getContent()}</React.Fragment>;
};
