import React from 'react';
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

export const FilterDropdownButton = ({countFilter, onClick, onClearClick}) => {
    return <React.Fragment>
        {countFilter > 0 &&
        <Button
            style={{
                top: -5,
                position: 'absolute',
                right: -12,
                fontSize: '0.45rem',
                paddingLeft: 7,
                paddingRight: 7,
            }}
            className={'rounded-capsule'}
            onClick={onClearClick}
            color={'danger'}>
            <FontAwesomeIcon icon={faTrashAlt}/>
        </Button>
        }
        <button
            className="btn btn-falcon-default rounded-capsule pl-3 pr-3 ml-2"
            type="button"
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faFilter}/>
        </button>
    </React.Fragment>
}
