import {createSlice} from '@reduxjs/toolkit';
import {DEFAULT_PER_PAGE, INIT_PAGINATION} from "../../../../../config";

const initialState = {
    items: [],
    loading: false,
    total: 0,
    page: 1,
    perPage: DEFAULT_PER_PAGE,
};

const {actions, reducer} = createSlice({
    name: 'Offers/list',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setItems: (state, action) => {
            const {
                items = [], pagination = INIT_PAGINATION
            } = action.payload;
            const {page, pages} = pagination
            state.items = items;
            state.page = (page > pages && pages) ? pages : page;
            state.perPage = pagination.per_page;
            state.total = pagination.total;
        },
        setPagination: (state, action) => {
            console.log('setPagination',  action.payload)
            const {page = 1, perPage = DEFAULT_PER_PAGE} = action.payload;
            state.page = page;
            state.perPage = perPage;
        },
    },
});

export const {setLoading, setItems, setPagination} = actions;

export default reducer;




