import React from 'react';
import {Col, Form, Input, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {DataRangePicker} from './DataRangePicker';
import {
    onChangeLeadFilterCompany,
    onChangeLeadFilterManager,
    onChangeLeadFilterPhone,
    onChangeLeadFilterRangeDataCreate,
    onChangeLeadFilterType, onClearAllFilters
} from "../../../../../../state/ducks/leadFilter";
import {FilterDropdown} from "../../../../../../shared/FilterDropdown";

const types = [
    {value: 4, label: 'Черновик'},
    {value: 3, label: 'Адмитад'},
    {value: 1, label: 'Промокод'},
    {value: 2, label: 'Быстрокод'},
    {value: 0, label: 'Купон'},
    {value: 5, label: 'Free'},
]

export const Filter = () => {
    const dispatch = useDispatch();
    const companies = useSelector((state) => state.lead.companies)
    const managers = useSelector((state) => state.lead.managers)

    const {
        phone,
        companySelected,
        typeSelected,
        managerSelected,
        dateStart,
        dateEnd,
    } = useSelector(state => state.leadFilter);


    const countFilterSelected = () => {
        let result = 0
        if (phone) {
            result++
        }
        if (companySelected) {
            result++
        }
        if (typeSelected) {
            result++
        }
        if (managerSelected) {
            result++
        }
        if (dateStart) {
            result++
        }
        if (dateEnd) {
            result++
        }
        return result
    }

    const clearAllFilters = () => {
        dispatch(onClearAllFilters())
    }

    const {isAdmin, isSuperManager} = useSelector(state => state.auth);

    const onChangePhone = event => {
        dispatch(onChangeLeadFilterPhone(event.target.value));
    };

    const onChangeCompany = selected => {
        dispatch(onChangeLeadFilterCompany(selected));
    };

    const onChangeType = selected => {
        dispatch(onChangeLeadFilterType(selected));
    };

    const onChangeManager = selected => {
        dispatch(onChangeLeadFilterManager(selected));
    };

    const onChangeDataCreatePeriod = (start, end) => {
        dispatch(
            onChangeLeadFilterRangeDataCreate(
                start.format('DD.MM.YYYY'),
                end.format('DD.MM.YYYY')
            )
        );
    };

    const onClearDataCreatePeriod = () => {
        dispatch(onChangeLeadFilterRangeDataCreate(null, null));
    };

    return (
        <React.Fragment>
            <Row noGutters>
                <Col xs={'auto'}>
                    <Form inline className="search-box">
                        <div style={{marginTop: -8, marginBottom: -8}}>
                            <Select
                                isMulti
                                value={companySelected}
                                className={'m-1  search-input'}
                                onChange={onChangeCompany}
                                options={companies}
                                placeholder={'Компания'}
                                isClearable
                            />
                            <FontAwesomeIcon
                                icon="search"
                                className="position-absolute text-400 search-box-icon"
                            />
                        </div>
                    </Form>
                </Col>
                <Col xs={'auto'}>
                    <FilterDropdown countFilter={countFilterSelected()} onClearClick={clearAllFilters}>
                            <div className="m-1">
                                <Input
                                    value={phone}
                                    type="search"
                                    placeholder="Телефон..."
                                    aria-label="Поиск"

                                    onChange={onChangePhone}
                                />
                            </div>
                            <Select
                                isMulti
                                value={typeSelected}
                                className={'m-1'}
                                onChange={onChangeType}
                                options={types}
                                placeholder={'Тип'}
                                isClearable
                            />
                            {(isAdmin || isSuperManager) && (
                                <Select
                                    isMulti
                                    value={managerSelected}
                                    className={'m-1'}
                                    onChange={onChangeManager}
                                    options={managers}
                                    placeholder={'Менеджер'}
                                    isClearable
                                />
                            )}
                            <DataRangePicker
                                label="Дата"
                                startData={dateStart}
                                endData={dateEnd}
                                onChangeDataPeriod={onChangeDataCreatePeriod}
                                onClearDataPeriod={onClearDataCreatePeriod}
                            />
                    </FilterDropdown>
                </Col>
            </Row>
        </React.Fragment>
    );
};
