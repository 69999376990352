import instance from '../axiosInstance';

const URI = '/api/projects'

export const projects = {
  async index() {
    return await instance().get(URI);
  },
  async create(values) {
    return await instance().post(`${URI}`, values);
  },
  async update(id, values) {
    return await instance().put(`${URI}/${id}`, values);
  },
  async delete(id) {
    return await instance().delete(`${URI}/${id}`);
  },
};
