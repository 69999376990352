import React from 'react';
import {FormGroup, Label} from 'reactstrap';
import {Field as FinalFormField} from 'react-final-form';
import Select from "react-select";

export const SelectField = ({
                                name,
                                options,
                                label,
                                placeholder = null,
                                isDisabled = false,
                                isClearable = true,
                                isMulti = false
                            }) => {
    if (!placeholder) {
        placeholder = label;
    }
    return (
        <FormGroup>
            <Label>{label}</Label>
            <FinalFormField name={name}>
                {({input, meta}) => {

                    const handleChange = (option) => {
                        if (isMulti) {
                            input.onChange(option ? option.map(x => x.value): null);
                        } else {
                            input.onChange(option ? option.value : '');
                        }
                    };

                    const getValue = () => {
                        if (isMulti){
                            return options ? options.filter((option) => {
                                return input.value.indexOf(option.value) > -1
                            }) : []
                        }else {
                            return options ? options.find((option) => option.value === input.value) : ""
                        }
                    }

                    return <React.Fragment>
                        <Select
                            {...input}
                            onChange={handleChange}
                            value={getValue()}
                            className={'m-1'}
                            options={options}
                            placeholder={placeholder}
                            isClearable={isClearable}
                            isDisabled={isDisabled}
                            isMulti={isMulti}
                        />
                        {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
                    </React.Fragment>
                }}
            </FinalFormField>
        </FormGroup>
    );
};
