import React, {Fragment, useState} from 'react';
import {ButtonIcon, Import} from "../../../shared";
import API from "../../../api";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";

export const SettingsPage = () => {

    const [companyImporting, setCompanyImporting] = useState(false);

    const importCompanies = async () => {
        setCompanyImporting(true)
        const {counts} = await API.ewayCompany.import();
        const {count, added, updated, notSave, emptyWebsite} = counts
        toast.info(`Получено ${count} компаний`)
        if (added > 0) {
            toast.success(`Добавлено ${added} компаний`)
        }
        if (updated > 0) {
            toast.info(`Обновлено ${updated} компаний`)
        }
        if (notSave > 0) {
            toast.warn(`Не сохранено ${notSave} компаний`)
        }
        if (emptyWebsite > 0) {
            toast.error(`Не заполнен сайт у ${emptyWebsite} компаний`)
        }
        setCompanyImporting(false)
    }

    return <Fragment>
        <Helmet>
            <title>Настройки</title>
        </Helmet>

        <Import/>
        <h5>Настройки</h5>
        <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="redo"
            transform="shrink-3"
            disabled={companyImporting}
            onClick={() => importCompanies()}
        >
            Импорт компаний E-way
        </ButtonIcon>
    </Fragment>;
};
