import instance from '../axiosInstance';

export const companyContact = {
    async index(companyId) {
        return await instance().get(
            `/api/companies/${companyId}/contacts`
        );
    },
    async create(companyId, values) {
        return await instance().post(`/api/companies/${companyId}/contacts`, values);
    },
    async update(companyId, contactId, values) {

        return await instance().put(`/api/companies/${companyId}/contacts/${contactId}`, values);
    },
    async get(companyId, values) {
        const {id} = values;
        return await instance().get(`/api/company/${companyId}/contacts/${id}`, values);
    },
    async delete(companyId, values) {
        const {id} = values;
        return await instance().delete(`/api/company/${companyId}/contacts/${id}`, values);
    }
};
