import instance from '../importInstance';

export const manager = {
  async index() {
    return await instance().get(`/manager`);
  },
  async assignCompany(company_id, manager_id) {
    return await instance().post(`/manager/assign-company`,{
      company_id,
      manager_id
    });
  }
};
