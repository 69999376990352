import * as React from 'react';
import {Button, Modal, ModalHeader} from "reactstrap";
import {Form} from "react-final-form";
import {ContentForm} from "./ContentForm";
import {Fragment, useEffect, useState} from "react";
import * as Yup from "yup";
import useValidationSchema from "../../../../helpers/use-validation-schema";
import {OfferConfigForm} from "./OfferConfigForm";
import API from "../../../../api";
import {OfferContentForm} from "./OfferContentForm";
import {fetchCompany} from "../../../../state/ducks/company";
import {useDispatch} from "react-redux";

export const Publication = ({companyId}) => {

    const [isOpen, setIsOpen] = useState(false);

    const closeDialog = () => {
        setIsOpen(false);
    };

    function openDialog() {
        setIsOpen(true);
    }

    return (
        <Fragment>
            <Modal isOpen={isOpen} size={'xl'}>
                <Dialog companyId={companyId} onClose={closeDialog}/>
            </Modal>
            <Button
                onClick={() => openDialog()}
                color="falcon-success"
                className="mx-1"
                size="sm"
                icon="chevron-left">
                Опубликовать
            </Button>
        </Fragment>
    );
};


const Dialog = ({companyId, onClose}) => {

    const dispatch = useDispatch();

    const [step, setStep] = useState(1)
    const [company, setCompany] = useState({})
    const [offerConfig, setOfferConfig] = useState({})
    const [offerContent, setOfferContent] = useState({})
    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])

    const fetchPublicationInit = async companyId => {
        try {
            const {
                offerConfig,
                offerContent,
                brands,
                categories
            } = await API.company.publicationInit(companyId)
            setOfferConfig(offerConfig)
            setOfferContent(offerContent)
            setBrands(brands)
            setCategories(categories)
        } catch (e) {
            onClose()
        }
    };

    useEffect(() => {
        fetchPublicationInit(companyId).then();
    }, [companyId]);


    const onCompanySubmit = values => {
        setCompany(values)
        setStep(2)
    }

    const onOfferConfigSubmit = values => {
        setOfferConfig(values)
        setStep(3)
    }

    const onOfferContentSubmit = async values => {
        setOfferContent(values)
        const data = {company, offerConfig, offerContent: values}
        await API.company.publish(companyId, data)
        dispatch(fetchCompany(companyId));
        onClose()
    }

    const onClickPrev = () => {
        setStep(step - 1 < 1 ? 1 : step - 1)
    }

    return <Fragment>
        <ModalHeader toggle={onClose}>Опубликовать компанию</ModalHeader>
        {step === 1 && <CompanyForm company={company} onSubmit={onCompanySubmit}/>}
        {
            step === 2 &&
            <OfferConfig
                offerConfig={offerConfig}
                onSubmit={onOfferConfigSubmit}
                brands={brands}
                categories={categories}
                onClickPrev={onClickPrev}
            />
        }
        {
            step === 3 &&
            <OfferContent
                offerContent={offerContent}
                onSubmit={onOfferContentSubmit}
                onClickPrev={onClickPrev}
            />
        }
    </Fragment>
}

const OfferConfig = ({offerConfig, brands, categories, onSubmit, onClickPrev}) => {

    const schema = Yup.object().shape({});

    const validate = useValidationSchema(schema);

    return <Form
        initialValues={offerConfig}
        onSubmit={onSubmit}
        validate={validate}
        render={({handleSubmit, submitting, valid}) => {
            return (
                <OfferConfigForm
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                    valid={valid}
                    brands={brands}
                    categories={categories}
                    onClickPrev={onClickPrev}
                />
            );
        }}
    />
}


const OfferContent = ({offerContent, onSubmit, onClickPrev}) => {

    const schema = Yup.object().shape({});

    const validate = useValidationSchema(schema);

    return <Form
        initialValues={offerContent}
        onSubmit={onSubmit}
        validate={validate}
        render={({handleSubmit, submitting, valid}) => {
            return (
                <OfferContentForm
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                    valid={valid}
                    onClickPrev={onClickPrev}
                />
            );
        }}
    />
}


const CompanyForm = ({company, onSubmit}) => {

    const schema = Yup.object().shape({
        addressLink: Yup.string().required('Обязательное поле')
    });

    const validate = useValidationSchema(schema);

    return <Form
        initialValues={company}
        onSubmit={onSubmit}
        validate={validate}
        render={({handleSubmit, submitting, valid}) => {
            return (
                <ContentForm
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                    valid={valid}
                />
            );
        }}
    />
}
