import React from 'react';
import {InputField} from "../../../../../../../shared";
import {Col, Row} from "reactstrap";

export const PrivateEntrepreneur = ({ submitting }) => (
    <React.Fragment>
        <InputField name="name" label="Наименование" disabled={submitting}/>
        <Row>
            <Col>
                <InputField name="inn" label="ИНН" disabled={submitting}/>
            </Col>
            <Col>
            </Col>
            <Col>
                <InputField name="ogrn" label="ОГРН" disabled={submitting}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <InputField name="bankName" label="Банк" disabled={submitting}/>
            </Col>
            <Col>
                <InputField name="bik" label="БИК" disabled={submitting}/>
            </Col>
        </Row>
        <InputField name="legalAddress" label="Юридический адрес" disabled={submitting}/>
        <Row>
            <Col>
                <InputField name="checkingAccount" label="№ счета" disabled={submitting}/>
            </Col>
            <Col>
                <InputField name="correspondentAccount" label="Корреспондентский счет" disabled={submitting}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <InputField name="directorPosition" label="Должность руководителя" disabled={submitting}/>
            </Col>
            <Col>
                <InputField name="directorName" label="ФИО руководителя" disabled={submitting}/>
            </Col>
        </Row>
    </React.Fragment>
);
