import React from 'react'
import {Button, CardImg} from 'reactstrap'
import "cropperjs/dist/cropper.css";
import noPhoto from '../../../../../../../../assets/img/no-photo.png';

const Image = ({image}) => {

    function valueIsPdfLink(str, ext = '.pdf') {
        if (ext.length > str.length) return false;
        return str.substr(str.length - ext.length) === ext;
    }

    if (
        image.indexOf('data:application/pdf') === 0 ||
        valueIsPdfLink(image)
    ) {
        return <iframe title={'file'} src={image}/>
    }
    return <CardImg top width="100%" src={image ? image : noPhoto} alt="Документ"/>
}

export const DocumentFileInput = ({value, onChange, label = 'Загрузить документ'}) => {
    const inputRef = React.createRef()

    const onChangeImage = async (e) => {
        e.preventDefault()
        let files
        if (e.dataTransfer) {
            files = e.dataTransfer.files
        } else if (e.target) {
            files = e.target.files
        }
        const result = await fileListToBase64(files)
        onChange(result)
    }

    async function fileListToBase64(fileList) {
        function getBase64(file) {
            const reader = new FileReader()
            return new Promise(resolve => {
                reader.onload = ev => {
                    resolve(ev.target.result)
                }
                reader.readAsDataURL(file)
            })
        }

        const promises = []
        for (let i = 0; i < fileList.length; i++) {
            promises.push(getBase64(fileList[i]))
        }
        return await Promise.all(promises)
    }

    const selectFileClick = (e) => {
        e.preventDefault()
        inputRef.current.click()
    }

    const getImages = (value) => {
        if (!Array.isArray(value)) {
            return ''
        }
        return <div>{value.map((image, index) => {
            return <div className={'mb-2'} key={index}>
                <Image image={image}/>
                {
                    image.indexOf('http') === 0 &&
                    <a href={image}
                       target={'_blank'}
                       rel="noopener noreferrer">
                        Ссылка на файл
                    </a>
                }
            </div>
        })}</div>
    }

    return (
        <div style={{width: 200}}>
            {getImages(value)}
            <Button onClick={selectFileClick} block>{label}</Button>
            <input
                type="file"
                ref={inputRef}
                onChange={onChangeImage}
                accept="image/png, image/jpeg, application/pdf"
                hidden
                multiple
            />
        </div>
    )
}
