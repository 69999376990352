import React from 'react';
import {ForgetPasswordForm} from '../../components';
import { Form } from 'react-final-form';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useValidationSchema from '../../../../helpers/use-validation-schema';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import API from '../../../../api';

export const ForgetPasswordPage = () => {
  const history = useHistory();
  const initialValues = {
    email: ''
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Обязательное поле')
      .email('Неправильный формат E-mail')
  });

  const validate = useValidationSchema(schema);

  const onSubmit = async values => {
    toast.warn('Отключено');
    const response = await API.auth.signup();
    const { access, success, errors } = response;
    if (success) {
      localStorage.setItem('token', access.token);
      history.push('/');
    } else {
      for (let error in errors) {
        if (errors.hasOwnProperty(error)) {
          toast.error(errors[error][0]);
        }
      }
    }
  };

  return (
    <div className="text-center">
      <h5 className="mb-0"> Забыли ваш пароль?</h5>
      <small>Введите email и нажмите кнопку сброса.</small>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values, submitting, validating, valid }) => {
          return (
            <ForgetPasswordForm
              handleSubmit={handleSubmit}
              values={values}
              submitting={submitting}
              validating={validating}
              valid={valid}
            />
          );
        }}
      />
      <Row className="justify-content-between align-items-center">
        <Col xs="auto" />
        <Col xs="auto">
          <Link className="fs--1" to={`/auth/sign-in`}>
            Авторизация
          </Link>
        </Col>
      </Row>
    </div>
  );
};
