import * as React from 'react';
import avatar from '../../../../../../assets/img/team/avatar.png';
import moment from 'moment';

export const UserComment = ({comment}) => {
    const {text, created, author_name} = comment;
    return (
        <div className="d-flex p-3">
            <div className="avatar avatar-l mr-2">
                <img className="rounded-circle" src={avatar} alt={''}/>
            </div>
            <div className="flex-1">
                <div className="w-xxl-75">
                    <div className="hover-actions-trigger d-flex align-items-center">
                        <div className="chat-message p-2 rounded-lg bg-200 ">
                            <h6 className="mb-0 text-700">
                                {author_name}
                            </h6>
                            {text}
                        </div>
                    </div>
                    <div className="text-400 fs--2">{moment(created).format('DD.MM.YYYY HH:mm')}</div>
                </div>
            </div>
        </div>
    );
};
