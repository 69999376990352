import instance from '../axiosInstance';

export const companyComment = {
  async index(companyId) {
    return await instance().get(`/api/companies/${companyId}/comments`);
  },
  async create(companyId, values) {
    return await instance().post(`/api/companies/${companyId}/comments`, values);
  }
};
