import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingCart,
  faTag,
  faTachometerAlt,
  faSpellCheck,
  faPlusCircle,
  faFrog
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const icons = {
  0: {
    icon: faShoppingCart,
    title: 'Купон'
  },
  1: {
    icon: faTag,
    title: 'Промокод'
  },
  2: {
    icon: faTachometerAlt,
    title: 'Быстрокод'
  },
  3: {
    icon: faSpellCheck,
    title: 'Адмитад'
  },
  4: {
    icon: faFrog,
    title: 'Черновик'
  },
  5: {
    icon: faPlusCircle,
    title: 'Free'
  }
};

export const TypeLeadIcon = ({ id, typeLead, count }) => {
  const color = count > 0 ? 'success' : 'warning';
  return (
    <React.Fragment>
      <div className={'d-inline-block ml-1'} id={id}>
        <UncontrolledTooltip placement="top" target={id}>
          {icons[typeLead].title}
        </UncontrolledTooltip>
        <div>
          <div className="avatar avatar-xl">
            <div className={`avatar-name rounded-circle bg-soft-${color} border border-${color}`}>
              <span className={`fs-0 text-${color}`}>
                <FontAwesomeIcon icon={icons[typeLead].icon} />
              </span>
            </div>
          </div>
          <div className={'fs--2 text-center'}>{count}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
