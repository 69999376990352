import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import {CardBody} from "reactstrap";

export const Table = ({users, columns, loading}) => <CardBody className="p-0">
    <div className="table-responsive">
        <BootstrapTable
            remote
            loading={loading}
            bootstrap4
            keyField="id"
            data={users}
            columns={columns}
            bordered={false}
            classes="table-dashboard table-hover table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
            rowClasses="btn-reveal-trigger border-top border-200"
            headerClasses="bg-200 text-900 border-y border-200"
        />
    </div>
</CardBody>