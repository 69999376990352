import React from 'react';
import {Media} from 'reactstrap';
import _ from "lodash";
import {Avatar, Flex} from "../../../../shared";

export const NameFormatter = (dataField, {name, patronymic, last_name}) => {
    let avatarName = _.isEmpty(name) ? '' : name[0] + _.isEmpty(last_name) ? '' : last_name[0];
    return (
        <Media tag={Flex} align="center" className={'cursor-pointer'}>
            <Avatar size={'xl'} name={avatarName} isExact={true}/>
            <Media body className="ml-2">
                <h5 className="mb-0 fs--1">
                    {name} {patronymic} {last_name}
                </h5>
            </Media>
        </Media>

    );
};