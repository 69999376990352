import React, { Fragment } from 'react';
import { Button, CardHeader, Col, CustomInput, InputGroup, Row } from 'reactstrap';
import {ButtonIcon} from "../../../../../shared";
import {Filter} from "./Filter";

const Header = ({ title, totalCount, isSelected, onClickExport, enableReportBtn = true, onClickReport }) => {
  return (
    <CardHeader className={'bg-light'}>
      <Row className="align-items-center">
        <Col className={'col-auto'}><b className={'mr-2'}>{title}</b><sup>{totalCount}</sup></Col>
        <Col>
          <Filter />
        </Col>
        <Col className={`text-right`}>
          {isSelected ? (
            <InputGroup size="sm" className="input-group input-group-sm">
              <CustomInput type="select" id="bulk-select">
                <option>Действия</option>
                <option value="delete">Удалить</option>
              </CustomInput>
              <Button color="falcon-default" size="sm" className="ml-2">
                Применить
              </Button>
            </InputGroup>
          ) : (
            <Fragment>
              <ButtonIcon
                  className={'mr-2'}
                  icon="external-link-alt"
                  transform="shrink-3 down-2"
                  color="falcon-default"
                  size="sm"
                  onClick={() => onClickReport()}
                  disabled={!enableReportBtn}
              >
                Скачать сверки
              </ButtonIcon>
              <ButtonIcon
                icon="external-link-alt"
                transform="shrink-3 down-2"
                color="falcon-default"
                size="sm"
                onClick={() => onClickExport()}
              >
                Export
              </ButtonIcon>
            </Fragment>
          )}
        </Col>
      </Row>
    </CardHeader>
  );
};

export {Header};
