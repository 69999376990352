import React, {Fragment} from 'react';
import { Label } from 'reactstrap';
import { Field as FinalFormField } from 'react-final-form';
import {ImageCropperInput} from "./ImageCropperInput";

export const ImageCropperField = ({ name, label }) => {

  return (
    <Fragment>
      {label && <Label>{label}</Label>}
      <FinalFormField name={name}>
        {({ input, meta }) => (
          <Fragment>
            <ImageCropperInput
              {...input}
              invalid={meta.error && meta.touched}
            />
            {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
          </Fragment>
        )}
      </FinalFormField>
    </Fragment>
  );
};
