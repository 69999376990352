import React, {useEffect, useState} from 'react'
import {LeadManagerChartCard} from './LeadManagerChartCard'
import { useSelector } from 'react-redux'
import API from "../../../api";
import {Row} from "reactstrap";

export const LeadManagerChartReport = () => {

  const isSuperManager = useSelector(state => {
    return 'superManager' in state.auth.roles;
  });
  const isAdmin = useSelector(state => {
    return 'admin' in state.auth.roles;
  });

  const [managerChart, setManagerChart] = useState(null);

  useEffect(() => {
   /* API.ewayReport.managerChart().then(response => {
      setManagerChart(response.data);
    });*/
  }, [])

  const getData = (periodName, type = 'all') => {
    if (managerChart && periodName in managerChart) {
      const periodData = managerChart[periodName]
      if (periodData && type in periodData) {
        const periodTypeData = periodData[type]

        const result = []
        Object.keys(periodTypeData).map((key) => {
          return result.push(periodTypeData[key])
        })
        return result
      }
      return null
    }
    return null
  }

  return (
      <Row>
      {(isSuperManager || isAdmin) && (
        <LeadManagerChartCard
          title="Сегодня"
          subtitle="Вчера"
          data={getData('day')}
        />
      )}
      {(isSuperManager || isAdmin) && (
        <LeadManagerChartCard
          title="Текущий месяц"
          subtitle="Прошлый месяц"
          data={getData('month')}
        />
      )}
      {(isSuperManager || isAdmin) && (
        <LeadManagerChartCard
          title="Сегодня"
          subtitle="Вчера"
          data={getData('day', 'admitad')}
        />
      )}
      {(isSuperManager || isAdmin) && (
        <LeadManagerChartCard
          title="Текущий месяц"
          subtitle="Прошлый месяц"
          data={getData('month', 'admitad')}
        />
      )}
    </Row>
  )
}
