import {createSlice} from '@reduxjs/toolkit';
import API from '../../api';

const initialState = {
    leads: [],
    isLoaded: false,
    totalCount: 0,
    pageCount: 0,
    currentPage: 1,
    perPage: 50,
    managers: [],
    companies: [],
    types: [],
};
const {actions, reducer} = createSlice({
    name: 'leadReleases',
    initialState,
    reducers: {
        setIsLoaded: (state, action) => {
            state.isLoaded = action.payload;
        },
        setList: (state, action) => {
            const { managers, companies, types, items } = action.payload
            const {data, current_page, per_page, total} = items;
            let optionsCompanies = []
            for (let key in companies) {
                if (companies.hasOwnProperty(key)) {
                    const company = companies[key]
                    optionsCompanies.push({
                        value: company['id'],
                        label: company['name'],
                    })
                }
            }
            let optionsManagers = []
            for (let key in managers) {
                if (managers.hasOwnProperty(key)) {
                    const manager = managers[key]
                    optionsManagers.push({
                        value: manager['ID'],
                        label: manager['NAME'] + ' ' + manager['LAST_NAME'],
                    })
                }
            }
            let leads = []
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const lead = data[key]
                    leads.push({
                        id: lead['ID'],
                        status: lead['STATUS'],
                        name: lead['NAME'],
                        phone: lead['PHONE'],
                        amount: lead['AMOUNT'],
                        created: lead['CREATED'],
                        comment: lead['COMMENT'],
                        type: lead['TYPE'],
                        city: lead['city'],
                        company: lead['company'],
                        offer: lead['offer'],
                        manager: lead['manager'],
                    })
                }
            }
            state.managers = optionsManagers
            state.companies = optionsCompanies
            state.types = types
            state.leads = leads;
            state.totalCount = parseInt(total);
            state.pageCount = Math.ceil(parseInt(total) / parseInt(per_page));
            state.currentPage = parseInt(current_page);
            state.perPage = parseInt(per_page);
        }
    }
});

const {setIsLoaded, setList} = actions;

export const fetchLeadsList = (
    page,
    sizePerPage,
    phone = null,
    company = null,
    dataStart = null,
    dataEnd = null,
    manager = null,
    type = null,
) => async dispatch => {

    dispatch(setIsLoaded(true));
    const request = await API.ewayLead.index(
        page,
        sizePerPage,
        phone,
        company,
        dataStart,
        dataEnd,
        manager,
        type,
    );
    dispatch(setList(request));
    dispatch(setIsLoaded(false));
};

export default reducer;
