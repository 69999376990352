import * as React from 'react';
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTrashAlt, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchRestoreFromTrashCompany } from '../../../../../state/ducks/company';
import { useHistory } from 'react-router-dom';
import API from '../../../../../api';
import { CompanyRefusal } from './CompanyRefusal';

const DeleteActionButton = ({ id, label, color, icon, onClick }) => {
  return (
    <React.Fragment>
      <Button color={`falcon-${color}`} id={id} size="sm" className="ml-1" onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
      </Button>
      <UncontrolledTooltip target={id}>{label}</UncontrolledTooltip>
    </React.Fragment>
  );
};

const DeleteActions = ({ companyId, status }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  async function deleteCompany() {
    if (window.confirm('Удалить компанию?')) {
      await API.company.delete(companyId);
      toast.warn('Компания удалена');
      history.goBack();
    }
  }

  function restoreFromTrashCompany() {
    if (window.confirm('Восстановить компанию из корзины?')) {
      dispatch(fetchRestoreFromTrashCompany(companyId));
      toast.success('Компания восстановлена из корзины');
    }
  }

  async function markForDeletionCompany() {
    if (window.confirm('Отправить компанию в корзину?')) {
      await API.company.markToTrash(companyId);
      toast.warn('Компания отправлена на удаление');
      history.goBack();
    }
  }

  if (status === 15) {
    return (
      <React.Fragment>
        <DeleteActionButton
          id={'company-action-delete'}
          label={'Удалить'}
          color="danger"
          icon={faTrash}
          onClick={deleteCompany}
        />
        <DeleteActionButton
          id={'company-action-restore-from-trash'}
          label={'Восстановить из корзины'}
          color="primary"
          icon={faTrashRestore}
          onClick={restoreFromTrashCompany}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <DeleteActionButton
        id={'company-action-mark-from-deletion'}
        label={'На удаление'}
        color="warning"
        icon={faTrashAlt}
        onClick={markForDeletionCompany}
      />
    </React.Fragment>
  );
};

export const StatusBlock = ({ company }) => {
  const { id, global_status } = company;
  const getStatusLabel = status => {
    switch (status) {
      case 1:
        return 'Просмотрена';
      case 2:
        return 'В работе';
      case 3:
        return 'ЛПР';
      case 4:
        return 'Отказ';
      case 5:
        return 'Подписание';
      case 6:
        return 'Договор';
      case 15:
        return 'На удаление';
      default:
        return 'Новая';
    }
  };

  return (
    <Card className="mb-2">
      <CardBody className="py-2">
        <Row className="align-items-center">
          <Col className="pt-2 pb-2">
            <span className={'fs--1'}>статус: </span>
            <span className={'text-primary'}>{getStatusLabel(global_status)}</span>
          </Col>
          <Col xs={'auto'} className={`text-right`}>
            <CompanyRefusal id={id} status={global_status} />
            <DeleteActions companyId={id} status={global_status} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
