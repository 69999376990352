import * as React from 'react';
import {Card, CardBody, Col, Form, ModalBody, ModalFooter, Row} from 'reactstrap';

import {ButtonSubmit, ImageCropperField, InputField, QuillEditorField} from "../../../../../shared";

export const ContentForm = ({handleSubmit, submitting, valid}) => {

    return (
        <Form onSubmit={handleSubmit}>
            <ModalBody className="m-3">
                <Row className={'mb-2'}>
                    <Col xs={'3'}>
                        <ImageCropperField name={'logo'}/>
                    </Col>
                    <Col>
                        <Card className="mb-2">
                            <CardBody>
                                <InputField
                                    label={'Ссылка на страницу с адресами'}
                                    name={'addressLink'}
                                />
                                <InputField
                                    label={'Почта для отправки оповещения в компанию'}
                                    name={'emailForNotification'}
                                />
                                <QuillEditorField
                                    name={'description'}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <ButtonSubmit label={'Продолжить'} disabled={submitting || !valid}/>
            </ModalFooter>
        </Form>

    );
};
