import instance from '../axiosInstance';

export const companyLegals = {
  async index(companyId) {
    return await instance().get(
        `/api/legal_entities`,
        {
          params: {
            company: companyId
          }
        });
  },
  async create(values) {
    return await instance().post(`/api/legal_entities`, values);
  },
  async update(legalEntityId, values) {
    return await instance().put(legalEntityId, values);
  },
  async get(legalEntityId) {
    return await instance().get(legalEntityId);
  },
  async delete(legalEntityId, values) {
    return await instance().delete(legalEntityId, values);
  }
};
