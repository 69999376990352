import React from 'react'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import {Background} from "../../../../shared";
import {Card, CardBody, Col} from "reactstrap";
import bgImage from '../../../../assets/img/illustrations/corner-1.png';

export const LeadManagerChartCard = ({ data, title, subtitle }) => {

  return (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
        <Background image={bgImage} className="bg-card" />
        <CardBody className="position-relative">
          <BarChart width={350} height={200} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" hide={true} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar name={subtitle} dataKey="period_1" fill="#82ca9d" />
            <Bar name={title} dataKey="period_0" fill="#8884d8" />
          </BarChart>
        </CardBody>
      </Card>
    </Col>
  )
}
