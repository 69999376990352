import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Table} from "../../../../shared";
import {offerTypeFormatter} from "../../../Leads/pages/IndexPage/LeadsTable/helpers/formaters";
import {list} from "../../store";
import {useHistory} from "react-router-dom";
import {ActionFormatter} from "../ActionFormatter";

const OffersTable = () => {

    const history = useHistory();
    const dispatch = useDispatch()

    const offers = useSelector(list.selectors.items)
    const isLoading = useSelector(list.selectors.isLoading)
    const {total, page, perPage} = useSelector(list.selectors.pagination)


    const onCelClick = ({id}) => {
        history.push(`/offers/${id}`);
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Наименование',
            events: {onClick: (e, column, columnIndex, row) => onCelClick(row)},
        },
        {
            dataField: 'company',
            text: 'Компания',
            events: {onClick: (e, column, columnIndex, row) => onCelClick(row)},
        },
        {
            dataField: 'createdAt',
            text: 'Создано',
            events: {onClick: (e, column, columnIndex, row) => onCelClick(row)},
        },
        {
            dataField: 'public_at',
            text: 'Дата публикации',
        },
        {
            dataField: 'type',
            text: 'Тип',
            formatter: offerTypeFormatter,
        },
        {
            dataField: 'leadsCount',
            text: 'Лидов',
        },
        {
            dataField: 'manager',
            text: 'Менеджер',
        },
        {
            dataField: '',
            text: '',
            align: 'right',
            headerClasses: 'border-0',
            formatter: ActionFormatter,
            formatExtraData: {
                onActivateClick: (id, active) => dispatch(list.methods.handleActivate(id, active)),
                onDeleteClick: (id, name) => dispatch(list.methods.handleDelete(id, name)),
                disable: false
            },
        }
    ];

    return (
        <Table
            columns={columns}
            items={offers}
            loading={isLoading}
            page={page}
            perPage={perPage}
            total={total}
            handleTableChange={(eventType, tableData) => dispatch(list.methods.handlePagination(eventType, tableData))}
        />
    );
};

export {OffersTable};
