import React, {createRef, useState} from 'react';
import {Card, CardBody, FormGroup, Input} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import {Header} from './header';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedStatuses} from '../../../../state/ducks/companies';
import {pageButtonRenderer, sizePerPageRenderer, paginationTotalRenderer} from '../../../../helpers/paginations';
import Label from "reactstrap/es/Label";

const SelectRowInput = ({indeterminate, rowIndex, ...rest}) => (
    <div className="custom-control custom-checkbox">
        <input
            className="custom-control-input"
            {...rest}
            onChange={() => {
            }}
            ref={input => {
                if (input) input.indeterminate = indeterminate;
            }}
        />
        <label className="custom-control-label"/>
    </div>
);

export const CompaniesTable = ({
                                   title,
                                   items,
                                   page,
                                   sizePerPage,
                                   totalSize,
                                   loading,
                                   columns,
                                   rowEvents,
                                   rowClasses = 'btn-reveal-trigger border-top border-200',
                                   handleTableChange,
                                   cellEdit,
                                   onClickAddBtn,
                                   visibleWordstatTitle,
                                   onChangeVisibleWordstatTitle
                               }) => {
    const dispatch = useDispatch();
    let table = createRef();
    const [isSelected, setIsSelected] = useState(false);

    const {isAdmin, isSuperManager} = useSelector(state => state.auth);

    const dev = true;

    const selectRow = onSelect => {
        return {
            hideSelectColumn: (!isAdmin && !isSuperManager) || dev,
            mode: 'checkbox',
            columnClasses: 'py-2 align-middle',
            clickToSelect: false,
            selectionHeaderRenderer: ({mode, ...rest}) => <SelectRowInput type="checkbox" {...rest} />,
            selectionRenderer: ({mode, ...rest}) => <SelectRowInput type={mode} {...rest} />,
            headerColumnStyle: {border: 0, verticalAlign: 'middle'},
            selectColumnStyle: {border: 0, verticalAlign: 'middle'},
            onSelect: onSelect,
            onSelectAll: onSelect
        };
    };

    const onSelect = () => {
        setImmediate(() => {
            checkSelectedRowsStatus(table.current.selectionContext.selected, items);
            setIsSelected(!!table.current.selectionContext.selected.length);
        });
    };

    function checkSelectedRowsStatus(selectedIds, companies) {
        let seenStatuses = {};
        let statuses = [];
        let len = companies.length;
        let j = 0;
        for (let i = 0; i < len; i++) {
            let {id, global_status} = companies[i];
            if (selectedIds.indexOf(id) > -1 && seenStatuses[global_status] !== 1) {
                seenStatuses[global_status] = 1;
                statuses[j++] = global_status;
            }
        }
        dispatch(setSelectedStatuses(statuses));
    }

    return (
        <Card className="mb-3">
            <Header title={title} totalCount={totalSize} isSelected={isSelected} items={items}
                    onClickAddBtn={onClickAddBtn}/>
            <PaginationProvider
                pagination={paginationFactory({
                    custom: true,
                    page,
                    sizePerPage,
                    totalSize,
                    sizePerPageRenderer,
                    pageButtonRenderer,
                    paginationTotalRenderer,
                    sizePerPageList: [{
                        text: '50', value: 50
                    }, {
                        text: '100', value: 100
                    }, {
                        text: '200', value: 200
                    }]
                })}
            >
                {({paginationProps, paginationTableProps}) => {
                    return (
                        <React.Fragment>
                            <CardBody className="p-0">
                                <div className="table-responsive">
                                    <BootstrapTable
                                        remote
                                        loading={loading}
                                        rowEvents={rowEvents}
                                        ref={table}
                                        cellEdit={cellEdit}
                                        bootstrap4
                                        keyField="id"
                                        data={items}
                                        columns={columns}
                                        selectRow={selectRow(onSelect)}
                                        bordered={false}
                                        classes="table-dashboard table-hover table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                                        rowClasses={rowClasses}
                                        headerClasses="bg-200 text-900 border-y border-200"
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                    />
                                </div>
                            </CardBody>
                            <div className="card-footer d-flex align-items-center justify-content-between">
                                <style>{'.pagination{ margin: 0}'}</style>
                                <SizePerPageDropdownStandalone {...paginationProps} />
                                <PaginationTotalStandalone {...paginationProps} />
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        </React.Fragment>
                    );
                }}
            </PaginationProvider>
            <CardBody>
                <FormGroup className="form-check">
                    <Input
                        type="checkbox"
                        name="visibleWordstatTitle"
                        id="visibleWordstatTitle"
                        onChange={onChangeVisibleWordstatTitle}
                        checked={visibleWordstatTitle}/>
                    <Label for="visibleWordstatTitle" check>
                        Показать заголовок в поисковой выдаче
                    </Label>
                </FormGroup>
            </CardBody>
        </Card>
    );
};
