import {createSlice} from '@reduxjs/toolkit';

const initialItem = {}

const initialState = {
    loading: false,
    brands: [],
    categories: [],
    companies: [],
    item: initialItem
};

const {actions, reducer} = createSlice({
    name: 'Offers/form',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            console.log(action.payload)
            state.loading = action.payload;
        },
        setItem: (state, action) => {
            state.item = action.payload ? action.payload : initialItem
        },
        setReferences: (state, action) => {
            const {brands = [], categories = [], companies = []} = action.payload
            companies.unshift({value: '', label: ''})
            state.brands = brands;
            state.categories = categories;
            state.companies = companies;
        },
    },
});

export const {setLoading, setItem, setReferences} = actions;

export default reducer;




