import React from 'react';
import {
    CardHeader,
    Col,
    Row
} from 'reactstrap';
import {ButtonIcon} from "../../../../shared";
import {useHistory} from "react-router-dom";

const OffersTableHeader = () => {


    const history = useHistory();

    const onAdd = () => {
        history.push(`/offers/create`);
    }

    return (
        <CardHeader className={'bg-light'}>
            <Row className="align-items-center">
                <Col className={'col-auto'}><b className={'mr-2'}>Предложения</b><sup>{0}</sup></Col>
                <Col>


                </Col>
                <Col className={`text-right`}>
                    <ButtonIcon
                        icon="plus"
                        transform="shrink-3 down-2"
                        color="falcon-default"
                        size="sm"
                        className="mx-2"
                        onClick={onAdd}
                    >
                        Добавить
                    </ButtonIcon>
                </Col>
            </Row>
        </CardHeader>
    );
};

export {OffersTableHeader};
