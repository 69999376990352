import * as React from 'react';
import moment from 'moment';

const LABELS = {
    'created': 'Создана',
    'looked': 'Посмотрена',
    'in_work': 'В работе',
    'lpr': 'ЛПР',
    'rejection': 'Отказано',
    'signing_agreement': 'Отправлено соглашение',
    'signed_agreement': 'Соглашение подписано',
    'sng': '',
    'mark_deleted': 'На удаление',
    'deleted': '',
}

const getStatusLabel = (status) => {
    if (LABELS.hasOwnProperty(status)) {
        return LABELS[status]
    }
    return 'Не определен'
}

const COLORS = {
    'created': 'success',
    'looked': 'success',
    'in_work': 'success',
    'lpr': 'success',
    'rejection': 'danger',
    'signing_agreement': 'success',
    'signed_agreement': 'success',
    'sng': 'danger',
    'mark_deleted': 'danger',
    'deleted': 'danger',
}

const getStatusColor = (status) => {
    if (COLORS.hasOwnProperty(status)) {
        return COLORS[status]
    }
    return ''
}

export const StatusAction = ({action}) => {

    const {date, actor_name, set_status} = action;
    const name = actor_name ? actor_name : 'Автоматически';

    const color = getStatusColor(set_status);

    return (
        <div className="d-flex p-3">
            <div className="flex-1 d-flex justify-content-end">
                <div className="w-100 w-xxl-75">
                    <div className="hover-actions-trigger d-flex flex-end-center">
                        <div
                            className={`chat-message p-2 rounded-lg text-${color} border border-${color}`}
                        >
                            <h6 className="mb-0">{name}</h6>
                            статус: {getStatusLabel(set_status)}
                        </div>
                    </div>
                    <div className="text-400 fs--2 text-right">
                        {moment(date).format('DD.MM.YYYY HH:mm')}
                    </div>
                </div>
            </div>
        </div>
    );
};
