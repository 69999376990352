import React from 'react';
import {ButtonIcon} from "../ButtonIcon";

const HeaderCreateBtn = ({onClick}) => <ButtonIcon
    icon="plus"
    transform="shrink-3 down-2"
    color="falcon-default"
    size="sm"
    className="mx-2"
    onClick={onClick}
>
    Добавить
</ButtonIcon>


export {HeaderCreateBtn};
