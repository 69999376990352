import React, {useState} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {FilterDropdownButton} from "../FilterDropdownButton";

export const FilterDropdown = ({children, countFilter, onClearClick}) => {
    const [isOpen, setIsOpen] = useState(false);
    return   <Dropdown isOpen={isOpen} toggle={() => {}}>
        <FilterDropdownButton
            countFilter={countFilter}
            onClearClick={() => onClearClick()}
            onClick={() => setIsOpen(!isOpen)}
        />
        <DropdownToggle tag={'div'}>{''}</DropdownToggle>
        <DropdownMenu right style={{ minWidth: 300, minHeight: 500 }}>
            <FontAwesomeIcon
                icon={faWindowClose}
                onClick={() => setIsOpen(false)}
                style={{
                    top: 5,
                    left: 5,
                    position: 'absolute',
                }}
            />
            {children}
        </DropdownMenu>
    </Dropdown>
}
