import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import {ButtonIcon} from '../../../../../../../shared';
import { Button, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Field } from '../Field';

export const Emails = ({ pop, push, submitting, showRemoveBtn = false }) => {
  return (
    <React.Fragment>
      <FieldArray name="emails">
        {({ fields }) =>
          fields.map((name, index) => (
            <InputGroup key={name} className={'mb-2'}>
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </div>
              <Field
                name={`${name}.email`}
                placeholder="Email"
                disabled={submitting}
              />
              <Field
                name={`${name}.comment`}
                placeholder="Комментарий"
                disabled={submitting}
              />
              {showRemoveBtn && (
                <InputGroupAddon addonType="append">
                  <Button onClick={() => fields.remove(index)} color="danger" disabled={submitting}>
                    <FontAwesomeIcon icon={faWindowClose} />
                  </Button>
                </InputGroupAddon>
              )}
            </InputGroup>
          ))
        }
      </FieldArray>
      <div className="d-flex justify-content-end mb-2">
        {showRemoveBtn && (
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="minus"
            transform="shrink-3"
            className={'mr-2'}
            onClick={() => pop('emails')}
            disabled={submitting}
          />
        )}
        <ButtonIcon
          color="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
          onClick={() => push('emails', undefined)}
          disabled={submitting}
        >
          Email
        </ButtonIcon>
      </div>
    </React.Fragment>
  );
};
