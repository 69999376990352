import React from 'react';
import { FormGroup } from 'reactstrap';
import Label from 'reactstrap/es/Label';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

export const DataRangePicker = ({
  label,
  startData,
  endData,
  onChangeDataPeriod,
  onClearDataPeriod
}) => {
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY')
    );
  };

  const handleCancel = (event, picker) => {
    picker.element.val('');
    onClearDataPeriod();
  };

  const defaultValue = () => {
    if (startData && endData) {
      return moment(startData).format('DD.MM.YYYY') + ' - ' + moment(endData).format('DD.MM.YYYY');
    }
    return '';
  };

  function getInitialData(value) {
    return value
      ? moment(value)
          .startOf('hour')
          .toDate()
      : moment()
          .startOf('hour')
          .toDate();
  }

  return (
    <FormGroup className="p-1 mb-0">
      <Label className={'ml-2 mb-0'}>{label}</Label>
      <DateRangePicker
        initialSettings={{
          autoUpdateInput: false,
          startDate: getInitialData(startData),
          endDate: getInitialData(endData),
          locale: {
            format: 'DD.MM.YYYY',
            cancelLabel: 'Очистить',
            applyLabel: 'Применить'
          }
        }}
        onApply={handleApply}
        onCancel={handleCancel}
        onCallback={onChangeDataPeriod}
      >
        <input type="text" className="form-control w-100" defaultValue={defaultValue()} />
      </DateRangePicker>
    </FormGroup>
  );
};
