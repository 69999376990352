import instance from '../axiosInstance';

export const lead = {
  async index(page, sizePerPage, sort) {
    return await instance().get(`/lead`, {
      params: {
        page,
        'per-page': sizePerPage,
        sort
      }
    });
  }
};
