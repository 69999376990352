import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {CreatePage, IndexPage, UpdatePage} from "./pages";
import {Helmet} from "react-helmet";

export const Root = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Бренды</title>
            </Helmet>
            <Switch>
                <Route path="/brands" exact component={IndexPage}/>
                <Route path="/brands/create" exact component={CreatePage}/>
                <Route path="/brands/:brandId" exact component={UpdatePage}/>
            </Switch>
        </React.Fragment>
    );
};
