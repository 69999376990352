import React, {useContext} from 'react';
import {Collapse, Navbar, NavItem, Nav, NavLink} from 'reactstrap';
import AppContext from '../../../context/Context';
import {ProfileDropdown} from './ProfileDropdown';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Logo} from "../../../shared";

const items = [
    {
        label: 'Главная',
        url: '/'
    },
    {
        label: 'Лиды',
        url: '/leads',
    },
    {
        label: 'Компании',
        url: '/companies'
    },
    {
        label: 'Предложения',
        url: '/offers',
    },
    {
        label: 'Категории',
        url: '/categories',
    },
    {
        label: 'Бренды',
        url: '/brands',
    },
    {
        label: 'Поисковая выдача',
        url: '/serp-task'
    },
    {
        label: 'Проекты',
        url: '/projects',
    },
];

const MenuItem = ({url, label}) => {
    return (
        <NavItem>
            <NavLink tag={Link} to={url}>
                {label}
            </NavLink>
        </NavItem>
    );
};

export const NavbarTop = () => {
    const {showBurgerMenu, setShowBurgerMenu} = useContext(AppContext);

    const {isAdmin} = useSelector(state => state.auth);

    const getMenu = () => {
        let newItems = [...items.filter((item) => item.hide !== true  || isAdmin)];
        if (isAdmin) {
            newItems.push({
                label: 'Пользователи',
                url: '/users'
            });
            newItems.push({
                label: 'Настройки',
                url: '/settings'
            });
        }
        return newItems;
    };

    return (
        <Navbar
            light
            className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
            expand
        >
            <div className="toggle-icon-wrapper d-xl-none">
                <button
                    className="navbar-vertical-toggle btn btn-link d-flex align-item-center justify-content-center "
                    onClick={() => {
                        setShowBurgerMenu(!showBurgerMenu);
                    }}
                    id="burgerMenu"
                >
          <span className="navbar-toggle-icon">
            <span className="toggle-line"/>
          </span>
                </button>
            </div>
            <Logo at="navbar-top" width={40} id="topLogo"/>
            <Collapse navbar>
                <Nav navbar>
                    {getMenu().map((item, index) => (
                        <MenuItem key={index} {...item} />
                    ))}
                </Nav>
                <Nav navbar className="align-items-center ml-auto">
                    <ProfileDropdown/>
                </Nav>
            </Collapse>
        </Navbar>
    );
};
