import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import {ROLE} from "../../modules/Users/constants";

const initialState = {
  isAppInit: false,
  isAuthorized: false,
  id: '',
  email: '',
  fullName: '',
  roles: [],
  isUser: false,
  isManager: false,
  isSuperManager: false,
  isAdmin: true,
};

const initialUser = {
  id: '',
  email: '',
  fullName: '',
  roles: [],

  isUser: false,
  isManager: false,
  isSuperManager: false,
  isAdmin: false,
};

const { actions, reducer } = createSlice({
  name: 'authReleases',
  initialState,
  reducers: {
    appInitRunning: state => {
      state.isAppInit = false;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
      state.isAppInit = true;
    },
    setUserData: (state, action) => {
      const { id = '', email = '', name = '',  role = ROLE.USER} = action.payload;
      state.id = id;
      state.roles = [role];
      state.email = email;
      state.fullName = name;
      state.isUser = role === ROLE.USER
      state.isManager = role === ROLE.MANAGER
      state.isSuperManager = role === ROLE.SUPER_MANAGER
      state.isAdmin = true
    }
  }
});

const { appInitRunning, setIsAuthorized, setUserData } = actions;

export default reducer;

export const initialized = () => async dispatch => {
  dispatch(appInitRunning());
  const token = localStorage.getItem('token');
  if (token) {
    const response = await API.auth.initialized();
    dispatch(setUserData(response));
    dispatch(setIsAuthorized(true));
  } else {
    dispatch(setIsAuthorized(false));
  }
};

export const logOut = () => async dispatch => {
  localStorage.removeItem('token');
  dispatch(setUserData(initialUser));
  dispatch(setIsAuthorized(false));
};

export const setUser = (token, userData) => async dispatch => {
  localStorage.setItem('token', token);
  dispatch(setUserData(userData));
  dispatch(setIsAuthorized(true));
};
