import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {CompanyLayout} from './layouts';
import {IndexPage, InfoPage, ContentPage, OffersPage} from './pages/';
import {Helmet} from "react-helmet";

export const CompanyRoute = ({component: RouteComponent, layout, ...rest}) => {
    return (
        <Route
            {...rest}
            render={() => (
                <CompanyLayout>
                    <RouteComponent/>
                </CompanyLayout>
            )}
        />
    );
};

export const Root = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Компании</title>
            </Helmet>
            <Switch>
                <Route path="/companies" exact component={IndexPage}/>
                <CompanyRoute path="/companies/:companyId" exact component={InfoPage}/>
                <CompanyRoute path="/companies/:companyId/content" exact component={ContentPage}/>
                <CompanyRoute path="/companies/:companyId/offers" exact component={OffersPage}/>
            </Switch>
        </React.Fragment>
    );
};
