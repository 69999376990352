import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';

const initialState = {
  items: [],
  isLoaded: false,
  totalCount: 0,
  pageCount: 0,
  currentPage: 1,
  perPage: 50
};
const { actions, reducer } = createSlice({
  name: 'userReleases',
  initialState,
  reducers: {
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    setList: (state, action) => {
      const { items, pagination } = action.payload;
      const { totalCount, pageCount, currentPage, perPage } = pagination;
      state.items = items;
      state.totalCount = totalCount;
      state.pageCount = pageCount;
      state.currentPage = currentPage;
      state.perPage = perPage;
    }
  }
});

const { setIsLoaded, setList } = actions;

export const fetchUserList = () => async dispatch => {
  dispatch(setIsLoaded(true));
  const request = await API.user.index(1, 100, null);
  dispatch(setList(request));
  dispatch(setIsLoaded(false));
};

export default reducer;
