import React from 'react';
import {
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';
import {useEffect, useState} from 'react';
import API from '../../../../../api';
import {CompanyLegal} from './CompanyLegal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FalconCardHeader} from '../../../../../shared';
import {LegalForm} from "./LegalForm";

export const LegalType = {LEGAL_ENTITY: 'legal_entity', PRIVATE_ENTREPRENEUR: 'private_entrepreneur'};

export const CompanyLegals = ({companyId}) => {

    const [legals, setLegals] = useState([]);
    const [editLegal, setEditLegal] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const fetchLegals = companyId => {
        API.companyLegals.index(companyId).then((response) => {
            setLegals(response['hydra:member']);
        });
    };

    useEffect(() => {
        //fetchLegals(companyId);
    }, [companyId]);

    const onClose = () => {
        setIsOpen(false);
    };

    const onSave = () => {
        //fetchLegals(companyId);
        setIsOpen(false);
    };

    const onEdit = async (legalId) => {
        const response = await API.companyLegals.get(legalId)
        setEditLegal(response);
        setIsOpen(true);
    }

    const openNewForm = type => {
        setEditLegal({type});
        setIsOpen(true);
    };

    return (
        <Card className="mb-2">
            <FalconCardHeader title="Юридические лица" titleTag="h6" className="py-2">
                <UncontrolledDropdown className="text-sans-serif btn-reveal-trigger">
                    <DropdownToggle color="falcon-default" size="sm" className="btn-reveal">
                        <FontAwesomeIcon icon={faPlus} className="fs--2"/>
                    </DropdownToggle>
                    <DropdownMenu right className="border py-0">
                        <div className="bg-white py-2">
                            <DropdownItem onClick={() => openNewForm(LegalType.LEGAL_ENTITY)}>Юр. лицо</DropdownItem>
                            <DropdownItem onClick={() => openNewForm(LegalType.PRIVATE_ENTREPRENEUR)}>ИП</DropdownItem>
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FalconCardHeader>
            <CardBody className="py-2">
                <LegalForm
                    companyId={companyId}
                    initialValues={editLegal}
                    onClose={onClose}
                    onSave={() => onSave()}
                    isOpen={isOpen}
                />
                {legals.map((legal, index) => (
                    <CompanyLegal
                        legal={legal}
                        key={index}
                        onEdit={onEdit}
                    />
                ))}
            </CardBody>
        </Card>
    );
};
