import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Card} from 'reactstrap';
import {ActionFormatter, BrandsTable, NameFormatter} from "../../components";
import {Header} from "../../components/BrandsTable/Header";
import {
    brandsChangeActivate,
    brandsFetchDelete,
    brandsSetPagination,
    fetchBrands
} from "../../../../state/ducks/brands";

export const IndexPage = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const {
        loading,
        name,
        brands,
        page,
        perPage,
        total
    } = useSelector(({brands}) => brands)

    const {isAdmin, isSuperManager} = useSelector(({auth}) => auth);

    const columns = [
        {
            dataField: 'name',
            text: 'Наименование',
            align: 'left',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
            formatter: NameFormatter,
            events: {
                onClick: (e, column, columnIndex, row) => {
                    onCelClick(row);
                }
            }
        },
        {
            dataField: 'created',
            text: 'Добавлен',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
            events: {
                onClick: (e, column, columnIndex, row) => {
                    onCelClick(row);
                }
            }
        },
        {
            dataField: '',
            text: '',
            align: 'right',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
            formatter: ActionFormatter,
            formatExtraData: {
                onActivateClick: (id, active) => dispatch(brandsChangeActivate(id, active)),
                onDeleteClick: (id, name) => dispatch(brandsFetchDelete(id, name)),
                disable: !isAdmin && !isSuperManager
            },
        }
    ];

    useEffect(() => {
        dispatch(fetchBrands())
    }, [dispatch, name, page, perPage]);


    const onCelClick = ({id}) => {
        history.push(`/brands/${id}`);
    };

    const onClickAddBtn = () => {
        history.push(`/brands/create`);
    }

    const handleTableChange = (eventType, tableData) => {
        const {page, sizePerPage} = tableData;
        switch (eventType) {
            case 'pagination':
                dispatch(brandsSetPagination(page, sizePerPage))
                break;
            default:
                console.log('Не обработано событие таблицы ' + eventType);
        }
    };

    return (
        <Card className="mb-3">
            <Header title={'Бренды'} onClickAddBtn={onClickAddBtn}/>
            <BrandsTable
                items={brands}
                page={page}
                sizePerPage={perPage}
                totalSize={total}
                loading={loading}
                columns={columns}
                handleTableChange={handleTableChange}
            />
        </Card>
    );
};
