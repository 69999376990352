import React from 'react';
import { Input } from 'reactstrap';
import { Field as FinalFormField } from 'react-final-form';

export const Field = ({ name, placeholder, disabled }) => {
  return (
    <FinalFormField name={name}>
      {({ input, meta }) => (
        <React.Fragment>
          <Input
            {...input}
            type="text"
            placeholder={placeholder}
            invalid={meta.error && meta.touched}
            disabled={disabled}
          />
          {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
        </React.Fragment>
      )}
    </FinalFormField>
  );
};
