import React from 'react';
import {Media} from 'reactstrap';
import {Avatar, Flex} from "../../../../shared";

export const NameFormatter = (dataField, {name, logo}) => (
    <Media tag={Flex} align="center">
        <Avatar src={logo} rounded={''}/>
        <Media body className="ml-2">
            <h5 className="mb-0 fs--1">{name}</h5>
        </Media>
    </Media>
);