import React from 'react';
import {Button} from "reactstrap";

const ButtonSubmit = ({label = 'Сохранить', disabled = false}) => (
    <Button color="falcon-success" size="sm" disabled={disabled} type={'submit'}>
        {label}
    </Button>
);

export {ButtonSubmit}
