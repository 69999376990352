import React from 'react';
import {
    Form as BootstrapForm,
    Nav,
    Navbar,
} from 'reactstrap';
import {
    ButtonBack,
    ButtonSubmit,
} from "../../../../shared";
import {Config} from "./Config";
import {Content} from "./Content";

export const Form = ({handleSubmit, onClickBack, brands, categories, companies, loading}) => {
    return (
        <BootstrapForm onSubmit={handleSubmit}>
            <Navbar color="white" light className={'mb-2'}>
                <Nav navbar className="mr-3">
                    <ButtonBack onClick={onClickBack}/>
                </Nav>
                <div className={'text-primary'}>{'Заголовок'}</div>
                <Nav navbar className="align-items-center ml-auto">
                    <ButtonSubmit/>
                </Nav>
            </Navbar>
            <Config
                brands={brands}
                companies={companies}
                categories={categories}
                loading={loading}
            />
            <Content
                loading={loading}
            />
        </BootstrapForm>
    )
}
