import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  status: null,
  statusSelected: null,
  paramCheck: null,
  paramCheckSelected: null,
  paramPublic: null,
  paramPublicSelected: null,
  manager: null,
  managerSelected: null,
  rangeDataCreateStart: null,
  rangeDataCreateEnd: null,
  rangeDataCheckStart: null,
  rangeDataCheckEnd: null,
  rangeDataPublicStart: null,
  rangeDataPublicEnd: null,
  countQueryInterval: [0, 100],
  countQueryIntervalMinValue: '',
  countQueryIntervalMaxValue: '',
  competitionInterval: [0, 100],
  competitionIntervalMinValue: '',
  competitionIntervalMaxValue: '',
  visitorsInterval: [0, 100],
  visitorsIntervalMinValue: '',
  visitorsIntervalMaxValue: ''
};

const { actions, reducer } = createSlice({
  name: 'companyFilterReleases',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setStatus: (state, action) => {
      const { payload } = action;
      state.status = convertSelectedToString(payload);
      state.statusSelected = payload;
    },
    setParamCheck: (state, action) => {
      const { payload } = action;
      state.paramCheck = convertSelectedToString(payload);
      state.paramCheckSelected = payload;
    },
    setParamPublic: (state, action) => {
      const { payload } = action;
      state.paramPublic = convertSelectedToString(payload);
      state.paramPublicSelected = payload;
    },
    setManager: (state, action) => {
      const { payload } = action;
      state.manager = convertSelectedToString(payload);
      state.managerSelected = payload;
    },
    setRangeDataCreate: (state, action) => {
      const { dataStart, dataEnd } = action.payload;
      state.rangeDataCreateStart = dataStart;
      state.rangeDataCreateEnd = dataEnd;
    },
    setRangeDataCheck: (state, action) => {
      const { dataStart, dataEnd } = action.payload;
      state.rangeDataCheckStart = dataStart;
      state.rangeDataCheckEnd = dataEnd;
    },
    setRangeDataPublic: (state, action) => {
      const { dataStart, dataEnd } = action.payload;
      state.rangeDataPublicStart = dataStart;
      state.rangeDataPublicEnd = dataEnd;
    },
    setCountQueryInterval: (state, action) => {
      state.countQueryInterval = action.payload;
    },
    setCountQueryFilterIntervalMinValue: (state, action) => {
      state.countQueryIntervalMinValue = action.payload;
    },
    setCountQueryFilterIntervalMaxValue: (state, action) => {
      state.countQueryIntervalMaxValue = action.payload;
    },
    setCompetitionInterval: (state, action) => {
      state.competitionInterval = action.payload;
    },
    setCompetitionFilterIntervalMinValue: (state, action) => {
      state.competitionIntervalMinValue = action.payload;
    },
    setCompetitionFilterIntervalMaxValue: (state, action) => {
      state.competitionIntervalMaxValue = action.payload;
    },
    setVisitorsInterval: (state, action) => {
      state.visitorsInterval = action.payload;
    },
    setVisitorsFilterIntervalMinValue: (state, action) => {
      state.visitorsIntervalMinValue = action.payload;
    },
    setVisitorsFilterIntervalMaxValue: (state, action) => {
      state.visitorsIntervalMaxValue = action.payload;
    },
    clearFilters: (state) => {
      state.name = ''
      state.status = null
      state.statusSelected = null
      state.paramCheck = null
      state.paramCheckSelected = null
      state.paramPublic = null
      state.paramPublicSelected = null
      state.manager = null
      state.managerSelected = null
      state.rangeDataCreateStart = null
      state.rangeDataCreateEnd = null
      state.rangeDataCheckStart = null
      state.rangeDataCheckEnd = null
      state.rangeDataPublicStart = null
      state.rangeDataPublicEnd = null
      state.countQueryInterval = [0, 100]
      state.countQueryIntervalMinValue = ''
      state.countQueryIntervalMaxValue = ''
      state.competitionInterval = [0, 100]
      state.competitionIntervalMinValue = ''
    },
  }
});

const {
  setName,
  setStatus,
  setParamCheck,
  setParamPublic,
  setManager,
  setRangeDataCreate,
  setRangeDataCheck,
  setRangeDataPublic,
  setCountQueryInterval,
  setCountQueryFilterIntervalMinValue,
  setCountQueryFilterIntervalMaxValue,
  setCompetitionInterval,
  setCompetitionFilterIntervalMinValue,
  setCompetitionFilterIntervalMaxValue,
  setVisitorsInterval,
  setVisitorsFilterIntervalMinValue,
  setVisitorsFilterIntervalMaxValue,
  clearFilters
} = actions;

export const onClearCompanyAllFilters = () => async dispatch => {
  dispatch(clearFilters());
};

export const onChangeCompanyFilterName = value => async dispatch => {
  if (value.indexOf('https://') > -1) {
    value = value.replace('https://', '');
    value = value.replace('/', '');
  }
  if (value.indexOf('http://') > -1) {
    value = value.replace('http://', '');
    value = value.replace('/', '');
  }
  dispatch(setName(value));
};

export const onChangeVisitorsFilterInterval = value => async dispatch => {
  dispatch(setVisitorsInterval(value));
};

export const onChangeVisitorsFilterIntervalMinValue = value => async dispatch => {
  dispatch(setVisitorsFilterIntervalMinValue(value));
};

export const onChangeVisitorsFilterIntervalMaxValue = value => async dispatch => {
  dispatch(setVisitorsFilterIntervalMaxValue(value));
};

export const onChangeCompetitionFilterInterval = value => async dispatch => {
  dispatch(setCompetitionInterval(value));
};

export const onChangeCompetitionFilterIntervalMinValue = value => async dispatch => {
  dispatch(setCompetitionFilterIntervalMinValue(value));
};

export const onChangeCompetitionFilterIntervalMaxValue = value => async dispatch => {
  dispatch(setCompetitionFilterIntervalMaxValue(value));
};

export const onChangeCountQueryFilterInterval = value => async dispatch => {
  dispatch(setCountQueryInterval(value));
};

export const onChangeCountQueryFilterIntervalMinValue = value => async dispatch => {
  dispatch(setCountQueryFilterIntervalMinValue(value));
};

export const onChangeCountQueryFilterIntervalMaxValue = value => async dispatch => {
  dispatch(setCountQueryFilterIntervalMaxValue(value));
};

export const onChangeCompanyFilterStatus = selected => async dispatch => {
  dispatch(setStatus(selected));
};

export const onChangeCompanyFilterParamCheck = selected => async dispatch => {
  dispatch(setParamCheck(selected));
};

export const onChangeCompanyFilterParamPublic = selected => async dispatch => {
  dispatch(setParamPublic(selected));
};

export const onChangeCompanyFilterManager = selected => async dispatch => {
  dispatch(setManager(selected));
};

export const onChangeCompanyFilterRangeDataCreate = (dataStart, dataEnd) => async dispatch => {
  dispatch(setRangeDataCreate({ dataStart, dataEnd }));
};

export const onChangeCompanyFilterRangeDataCheck = (dataStart, dataEnd) => async dispatch => {
  dispatch(setRangeDataCheck({ dataStart, dataEnd }));
};

export const onChangeCompanyFilterRangeDataPublic = (dataStart, dataEnd) => async dispatch => {
  dispatch(setRangeDataPublic({ dataStart, dataEnd }));
};

const convertSelectedToString = selected => {
  let value = null;
  if (selected !== null) {
    let result = [];
    selected.map(item => {
      return result.push(item.value);
    });
    value = result.join(',');
  }
  return value;
};

export default reducer;
