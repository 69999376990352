import * as React from 'react';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { Form } from 'react-final-form';
import API from '../../../../../api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import {CheckParamIcon} from "../../CheckParamIcon";
import {InputField} from "../../../../../shared";
import {useSelector} from "react-redux";
import _ from "lodash";

const CheckParam = ({ id, check_param, check_data }) => {
  const getData = () => (check_data ? moment(check_data).format('DD.MM.YYYY') : '');

  return (
    <React.Fragment>
      <Col className={'fs--2'}>
        <CheckParamIcon companyId={id} checkParam={check_param} />
        <span className={'ml-1'}>{getData()}</span>
      </Col>
    </React.Fragment>
  );
};

export const MainForm = ({ company, fetchCompany }) => {
  const history = useHistory();
  const content = useSelector(state => state.company.content);
  const { id, check_param, check_data } = company;

  const nameCompanyFormatter = (name_ru, name_en) => {
    return _.isEmpty(name_ru)
        ? '' + (_.isEmpty(name_en) ? '' : name_en)
        : name_ru + (_.isEmpty(name_en) ? '' : ' (' + name_en + ')');
  };


  const onSubmit = async values => {

    const { id, method, nameRu, nameEn, promoCodeTitle, phoneInSite } = values;
    const data = { nameRu, nameEn, promoCodeTitle, phoneInSite };
    if (method === 'save') {
      const response = await API.company.update(id, data);
      const {name_ru, name_en} = response

      const companyName = nameCompanyFormatter(name_ru, name_en)

      if (Object.keys(content).length > 0 &&  companyName !== content['NAME']){
        const values = {...content, "NAME": companyName}
        const updateResponse = await API.ewayCompany.update(values)
        if ('errors' in updateResponse && updateResponse['errors'].length > 0){
          if ('errors' in updateResponse && updateResponse['errors'].length > 0) {
            updateResponse['errors'].map(error => toast.error(error))
          }
        }
      }
      fetchCompany();

    } else if (method === 'check') {
      await API.company.update(id, data);
      const checkResponse = await API.company.startCheck(id, data);
      if ('success' in checkResponse && checkResponse.success) {
        toast.success('Компания отправлена на проверку');
        history.goBack();
      } else {
        if ('errors' in checkResponse) {
          for (let key in checkResponse.errors) {
            if (checkResponse.errors.hasOwnProperty(key)) {
              toast.error(checkResponse.errors[key]);
            }
          }
        }
      }
    }
  };

  const inCheck = () => {
    const { status } = company;
    return status !== 0 && status !== 30;
  };

  return (
    <Card className={'mb-2'}>
      <Form
        initialValues={company}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <CardBody>
              <InputField name="nameRu" placeholder="Наименование RU" disabled={submitting} />
              <InputField name="nameEn" placeholder="Наименование EN" disabled={submitting} />
              <InputField name="phoneInSite" placeholder="Телефон на сайте" disabled={submitting} />
              <InputField
                name="promoCodeTitle"
                placeholder="Заголовок промокода"
                disabled={submitting}
              />
            </CardBody>
            <CardFooter className="bg-light py-2">
              <Row className="flex-between-center">
                <Col xs="auto">
                  <Button
                    type="submit"
                    color="falcon-primary"
                    size="sm"
                    disabled={submitting || inCheck()}
                    onClick={() => {
                      form.change('method', 'check');
                    }}
                  >
                    Отправить на проверку
                  </Button>
                </Col>
                <CheckParam id={id} check_data={check_data} check_param={check_param} />
                <Col xs="auto">
                  <Button
                    type="submit"
                    color="falcon-success"
                    size="sm"
                    disabled={submitting || pristine}
                    onClick={() => {
                      form.change('method', 'save');
                    }}
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </form>
        )}
      />
    </Card>
  );
};
