export const managerFormatter = cell => {
    if (cell !== null && typeof cell === 'object') {
        let name = ''
        if (cell.hasOwnProperty('NAME')) {
            name += cell.NAME + ' '
        }
        if (cell.hasOwnProperty('LAST_NAME')) {
            name += cell.LAST_NAME
        }
        return name
    }
    return ''
};
