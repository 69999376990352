import React, {useState} from 'react';

import {Form} from 'react-final-form';

import {
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent, TabPane
} from 'reactstrap';

import * as Yup from 'yup';
import useValidationSchema from '../../../../../../helpers/use-validation-schema';
import {FileField} from "./FileField";
import API from "../../../../../../api";
import {fetchCompany} from "../../../../../../state/ducks/company";
import {useDispatch} from "react-redux";
import {InputField, SelectField} from "../../../../../../shared";
import classnames from 'classnames';

export const AgreementsForm = ({
                                   companyId,
                                   onClose,
                                   onSave,
                                   initialValues,
                                   legals,
                                   isOpen
                               }) => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const onSubmit = async values => {
        const agreementId = values['@id']

        values.userDiscount = Number(values.userDiscount)
        values.agentCommission = Number(values.agentCommission)
        values.additionalUserDiscount = Number(values.additionalUserDiscount)
        values.additionalAgentCommission = Number(values.additionalAgentCommission)

        if (agreementId) {
            await API.companyAgreement.update(agreementId, values);
        } else {
            await API.companyAgreement.create(companyId, values);
        }
        dispatch(fetchCompany(companyId));
        onSave();
        return null
    };

    const getFile = async (data) => {
        const agreementId = data['@id']
        const response = await API.companyAgreement.getFile(agreementId);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(
            new Blob([response], {type: "application/octet-stream"})
        );
        link.download = "Соглашение.docx";

        document.body.appendChild(link);

        link.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(link);
        }, 200);
    }

    const schema = Yup.object().shape({
        userDiscount: Yup.number().required('Обязательное поле'),
        agentCommission: Yup.number().required('Обязательное поле'),
        additionalUserDiscount: Yup.number().required('Обязательное поле'),
        additionalAgentCommission: Yup.number().required('Обязательное поле'),
    });

    const validate = useValidationSchema(schema);

    return (
        <Modal isOpen={isOpen} size={'lg'}>
            <ModalHeader toggle={onClose}>Добавление соглашения</ModalHeader>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
                render={({
                             handleSubmit,
                             pristine,
                             submitting
                         }) => {

                    return (
                        <form
                            onSubmit={handleSubmit}
                        >
                            <ModalBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'})}
                                            onClick={() => toggle('1')}
                                        >
                                            Соглашение
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '2'})}
                                            onClick={() => toggle('2')}
                                        >
                                            Результат
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabAgreement id={'1'}
                                                  legals={legals}
                                                  submitting={submitting}
                                                  onGetFile={() => getFile(initialValues)}/>
                                    <TabResult id={'2'} s
                                               submitting={submitting}/>
                                </TabContent>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="falcon-success"
                                    size="sm"
                                    type="submit"
                                    disabled={submitting || pristine}
                                >
                                    Сохранить
                                </Button>
                            </ModalFooter>
                        </form>
                    );
                }}
            />
        </Modal>
    );
};

const TabAgreement = ({id, legals, submitting, onGetFile}) => {
    return <TabPane tabId={id}>
        <SelectField
            name="legalEntity"
            className={'mb-2'}
            label={'Юр лицо'}
            options={legals}
        />
        <Row>
            <Col>
                <InputField name="userDiscount" type="number" label="Скидка пользователя" disabled={submitting}/>
            </Col>
            <Col>
                <InputField name="agentCommission" label="Комиссия агента" disabled={submitting}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <InputField name="additionalUserDiscount" label="Дополнительная скидка пользователя"
                            disabled={submitting}/>
            </Col>
            <Col>
                <InputField name="additionalAgentCommission" label="Дополнительная комиссия агента"
                            disabled={submitting}/>
            </Col>
        </Row>
        <Button
            onClick={onGetFile}
            color="falcon-success"
            size="sm"
            type="button"
            disabled={submitting}
        >
            Скачать соглашение
        </Button>
    </TabPane>
}

const TabResult = ({id, submitting}) => {
    return <TabPane tabId={id}>
        <Row>
            <Col>
                <InputField name="promoCode" label="Промокод" disabled={submitting}/>
            </Col>
            <Col>
                <InputField name="emailForSendingLead"
                            label="Email для отправки лидов"
                            disabled={submitting}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <InputField name="managerPhone"
                            label="Телефон менеджера"
                            disabled={submitting}/>
            </Col>
            <Col>
                <InputField name="managerName"
                            label="Имя менеджера"
                            disabled={submitting}/>
            </Col>
        </Row>
        <InputField name="region"
                    label="Регион"
                    disabled={submitting}/>
        <FileField name={'file'}/>
    </TabPane>
}
