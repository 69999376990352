import React, {Fragment} from "react";
import {ButtonIcon} from "../../../../shared";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

export  const ActionFormatter = (dataField, row, rowIndex, formatExtraData) => {

    const {id, name} = row

    return (
        <Fragment>
            <ButtonIcon
                icon={faEdit}
                transform="shrink-3 down-2 mr-2"
                color="falcon-success"
                size="sm"
                onClick={() => {
                    formatExtraData.onEditClick(id, name);
                }}
            />
            <ButtonIcon
                icon={faTrash}
                transform="shrink-3 down-2"
                color="falcon-danger"
                size="sm"
                onClick={() => {
                    formatExtraData.onDeleteClick(id, name);
                }}
            />
        </Fragment>
    );
};