import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {IndexPage, ViewPage} from "./pages";
import {Helmet} from "react-helmet";

export const Root = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Поисковая выдача</title>
            </Helmet>
            <Switch>
                <Route path="/serp-task" exact component={IndexPage}/>
                <Route path="/serp-task/:serpTaskId" exact component={ViewPage}/>
            </Switch>
        </React.Fragment>
    );
};
