import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Field as FinalFormField } from 'react-final-form';
import DatePicker from 'reactstrap-date-picker';

const dayLabels = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
const monthLabels = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

export const DateField = ({ name, label }) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      <FinalFormField name={name}>
        {({ input, meta }) => (
          <React.Fragment>
            <DatePicker
              {...input}
              dateFormat={'DD.MM.YYYY'}
              dayLabels={dayLabels}
              monthLabels={monthLabels}
              invalid={meta.error && meta.touched}
              placeholder={label}
            />

            {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
          </React.Fragment>
        )}
      </FinalFormField>
    </FormGroup>
  );
};
