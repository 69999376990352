import React, {useEffect, useState} from 'react';
import {Card} from 'reactstrap';
import {ActionFormatter, Header, ProjectForm, Table} from "../../components";
import {Dialog} from "../../../../shared";
import * as Yup from "yup";
import useValidationSchema from "../../../../helpers/use-validation-schema";
import API from "../../../../api";

const initialValues = {
    id: null,
    name: ''
};

export const IndexPage = () => {

    const columns = [
        {
            dataField: 'name',
            text: 'Наименование',
            align: 'left',
            headerClasses: 'border-0',
            classes: 'py-2 align-middle',
        },
        {
            dataField: '',
            text: '',
            align: 'right',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
            formatter: ActionFormatter,
            formatExtraData: {
                onDeleteClick: (id, name) => handleDeleteClick(id, name),
                onEditClick: (id, name) => handleEditClick(id, name),
            },
        }
    ];

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);


    const fetchProjects = async () => {
        setLoading(true)
        const {items = []} = await API.projects.index()
        setProjects(items);
        setLoading(false)
    };

    useEffect(() => {
        fetchProjects().then();
    }, []);

    const handleEditClick = async (id, name) => {
        setProject({id, name})
        setOpenDialog(true)
    }

    const handleDeleteClick = async (id, name) => {
        const result = window.confirm(
            `Вы действительно хотите удалить проект ${name}?`
        )
        if (result) {
            await API.projects.delete(id)
            await fetchProjects()
        }
    }

    const onClickAddBtn = () => {
        setOpenDialog(true);
    }

    const schema = Yup.object().shape({
        name: Yup.string().required('Обязательное поле')
    });

    const validate = useValidationSchema(schema);

    const onSubmit = async values => {
        setLoading(true)
        const {id} = values
        if (id) {
            await API.projects.update(id, values);
        } else {
            await API.projects.create(values);
        }
        await fetchProjects()
        setOpenDialog(false)
        setProject(initialValues)
    }

    const onCloseDialog = () => {
        setOpenDialog(false);
    }

    return (
        <Card className="mb-3">
            <Dialog
                title={'Добавление проекта'}
                isOpen={openDialog}
                closeDialog={onCloseDialog}
                onSubmit={onSubmit}
                initialValues={project}
                validate={validate}
                formComponent={<ProjectForm/>}
            />
            <Header title={'Проекты'} totalCount={0} items={projects} onClickAddBtn={onClickAddBtn}/>
            <Table
                handleTableChange={()=>console.log('handleTableChange')}
                items={projects}
                page={1}
                sizePerPage={50}
                totalSize={0}
                loading={loading}
                columns={columns}
            />
        </Card>
    );
};
