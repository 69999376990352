import API from "../../../../../api";
import {setItems, setLoading, setPagination} from "./store";

export const fetchOffers = (companyId = null) => async (dispatch, getState) => {
    const state = getState()
    const {name, page, perPage} = state['Offers/list']
    dispatch(setLoading(true));
    const request = await API.offers.index({name, page, perPage, companyId});
    dispatch(setItems(request));
    dispatch(setLoading(false));
};

export const handlePagination = (eventType, tableData) => dispatch => {
    const {page, sizePerPage} = tableData;
    switch (eventType) {
        case 'pagination':
            dispatch(setPagination({page, perPage: sizePerPage}))
            break;
        default:
            console.log('Предложения. Не обработано событие таблицы ' + eventType);
    }
};

export const handleActivate = (id, active) => async dispatch => {
    const success = window.confirm(
        active ? 'Деактивировать предложение?' : 'Активировать предложение?'
    )
    if (!success) {
        return
    }
    if (active) {
        await API.offers.deactivate(id)
    } else {
        await API.offers.activate(id)
    }
    dispatch(fetchOffers())
}

export const handleDelete = (id, offerName) => async dispatch => {
    const success = window.confirm(
        `Вы действительно хотите удалить предложение ${offerName}?`
    )
    if (success) {
        await API.offers.delete(id)
        dispatch(fetchOffers());
    }
}