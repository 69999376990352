import instance from '../axiosInstance';

const URI = '/serp/serp_tasks'

export const serpTask = {
  async index(page, sizePerPage, sort) {
    return await instance().get(`${URI}`, {
      params: {
        page,
        'per-page': sizePerPage,
        sort
      }
    });
  },
  async get(id) {
    return await instance().get(`${URI}/${id}`);
  },
  async create(values) {
    return await instance().post(`${URI}`, values);
  }
};
