import React from 'react';
import {Route} from 'react-router-dom';
import {IndexPage} from "./pages";
import {Helmet} from "react-helmet";

export const Root = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Лиды</title>
            </Helmet>
            <Route path="/leads" exact component={IndexPage}/>
        </React.Fragment>

    );
};
