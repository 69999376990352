import React, {Fragment} from 'react';
import {ChangePasswordForm} from "./ChangePasswordForm";
import {Col, Row} from "reactstrap";
import {Helmet} from "react-helmet";

export const ProfilePage = () => {

    return <Fragment>
        <Helmet>
            <title>Профиль</title>
        </Helmet>
        <h5>Профиль</h5>
        <Row>
            <Col xs={12} sm={6} lg={4} xl={3}>
                <ChangePasswordForm/>
            </Col>
        </Row>
    </Fragment>;
};
