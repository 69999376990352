import React from 'react';

import arrayMutators from 'final-form-arrays';
import API from '../../../../../../api';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Form} from 'reactstrap';
import {Form as FinalForm} from 'react-final-form';
import {PrivateEntrepreneur} from './PrivateEntrepreneur';
import {LegalEntity} from './LegalEntity';
import {LegalType} from "../CompanyLegals";
import * as Yup from "yup";
import useValidationSchema from "../../../../../../helpers/use-validation-schema";

export const LegalForm = ({
                              companyId,
                              onClose,
                              onSave,
                              initialValues = {},
                              isOpen
                          }) => {
    const {type} = initialValues;

    const schema = Yup.object().shape({
        name: Yup.string().required('Обязательное поле'),
        inn: Yup.string().required('Обязательное поле'),
        ogrn: Yup.string().required('Обязательное поле'),
        bankName: Yup.string().required('Обязательное поле'),
        bik: Yup.string().required('Обязательное поле'),
        legalAddress: Yup.string().required('Обязательное поле'),
        checkingAccount: Yup.string().required('Обязательное поле'),
        correspondentAccount: Yup.string().required('Обязательное поле'),
        directorPosition: Yup.string().required('Обязательное поле'),
        directorName: Yup.string().required('Обязательное поле'),
    });

    const validate = useValidationSchema(schema);

    const onSubmit = async values => {
        const legalId = values['@id'];
        values['type'] = 'legal_entity'

        if (legalId) {
            await API.companyLegals.update(legalId, values);
        } else {
            values['company'] = '/api/companies/' + companyId
            await API.companyLegals.create(values);
        }
        onSave();
    };

    const getForm = (type, submitting) => {
        if (type === LegalType.LEGAL_ENTITY) {
            return <LegalEntity submitting={submitting}/>;
        }
        return <PrivateEntrepreneur submitting={submitting}/>;
    };

    function getTitle(id, type) {
        let title = id ? 'Изменение ' : 'Добавление '
        title += type === LegalType.LEGAL_ENTITY ? 'Юр. лица' : 'ИП'
        return title
    }

    return (
        <Modal isOpen={isOpen} size={'lg'}>
            <ModalHeader toggle={onClose}>{getTitle(initialValues['@id'], type)}</ModalHeader>
            <FinalForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
                mutators={{
                    ...arrayMutators
                }}
                render={({handleSubmit, pristine, submitting}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <ModalBody>
                                {getForm(type, submitting)}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="falcon-success"
                                    size="sm"
                                    type="submit"
                                    disabled={submitting || pristine}
                                >
                                    Сохранить
                                </Button>
                            </ModalFooter>
                        </Form>
                    );
                }}
            />
        </Modal>
    );
};
