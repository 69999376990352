import React from 'react';
import {Button, Media} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';

export const CompanyLegal = ({legal, onEdit}) => {

    const {name} = legal;

    return (
        <Media className="mb-3 hover-actions-trigger align-items-center">
            <Media body className="ml-3">
                <h6 className="mb-0 font-weight-semi-bold">{name}</h6>
                <div className={'hover-actions r-0 absolute-vertical-center'}>
                    <Button
                        color={'falcon-primary'}
                        size="sm"
                        onClick={() => onEdit(legal['@id'])}
                    >
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                </div>
            </Media>
        </Media>
    );
};
