import * as React from 'react';
import { Col, Row } from 'reactstrap';
import {MainForm} from './MainForm';
import { CompanyLegals } from './CompanyLegals';
import { DomainInfo } from './DomainInfo';
import {Agreements} from './Agreements';
import { StatusBlock } from './StatusBlock';
import { useDispatch } from 'react-redux';
import { fetchCompany } from '../../../../state/ducks/company';
import {Contacts} from "./Contacts";

export const Company = ({ company }) => {
  const dispatch = useDispatch();

  const { id } = company;

  const getCompany = () => {
    dispatch(fetchCompany(id));
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          {id && (
            <React.Fragment>
              <MainForm company={company} fetchCompany={getCompany} />
              <DomainInfo company={company} />
            </React.Fragment>
          )}
        </Col>
        <Col>
          <StatusBlock company={company} />
          {id && (
            <React.Fragment>
              <Contacts companyId={id} />
              <CompanyLegals companyId={id} />
              <Agreements companyId={id} />
            </React.Fragment>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};
