import React, {Fragment} from "react";
import {CustomInput} from "reactstrap";
import {ButtonIcon} from "../../../../shared";

export const ActionFormatter = (dataField, row, rowIndex, formatExtraData) => {
    const {id, active, name} = row
    return (
        <Fragment>
            <CustomInput
                id={`active-brand=${id}`}
                className={'d-inline'}
                label=""
                onChange={() => {
                    formatExtraData.onActivateClick(id, active);
                }}
                checked={active}
                disabled={formatExtraData.disable}
                type="switch"
            />
            {!formatExtraData.disable &&
            <ButtonIcon
                icon="trash"
                transform="shrink-3 down-2"
                color="falcon-danger"
                size="sm"
                onClick={() => {
                    formatExtraData.onDeleteClick(id, name);
                }}
            />
            }
        </Fragment>
    );
};