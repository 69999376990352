import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {Logo, Section} from '../../shared';
import {Error404Page, Error500Page} from "./pages";

const Root = ({ match: { url } }) => (
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
        <Logo />
        <Switch>
          <Route path={`${url}/404`} component={Error404Page} />
          <Route path={`${url}/500`} component={Error500Page} />
          {/*Redirect*/}
          <Redirect to={`${url}/404`} />
        </Switch>
      </Col>
    </Row>
  </Section>
);

Root.propTypes = { match: PropTypes.object };

export default Root;
