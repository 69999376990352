import React from 'react';
import {ButtonIcon} from "../../../../shared";

export const PrevButton = ({onClick}) => {
    return <ButtonIcon
        color="falcon-info"
        icon={'chevron-left'}
        iconAlign="left"
        transform="down-1 shrink-4"
        size="sm"
        onClick={onClick}
    >
        Назад
    </ButtonIcon>
}