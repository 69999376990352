import API from "../../../../../api";
import {setItem, setLoading, setReferences} from "./store";
import {list} from "../../index";

export const submit = (values, history) => async (dispatch) => {
    dispatch(setLoading(true));
    const {id} = values
    try {
        if (id) {
            await API.offers.update(id, values)
        } else {
            await API.offers.create(values)
        }
        dispatch(list.methods.fetchOffers());
        dispatch(setLoading(false));
        history.goBack()
    } catch (e) {
    }
    dispatch(setLoading(false));
};

export const init = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    if (id) {
        const {brands = [], categories = [], companies = [], item = null} = await API.offers.get(id)
        dispatch(setReferences({brands, categories, companies}));
        dispatch(setItem(item))
    } else {
        const {brands = [], categories = [], companies = []} = await API.offers.references()
        dispatch(setReferences({brands, categories, companies}));
        dispatch(setItem(null))
    }
    dispatch(setLoading(false));
};
