import React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import {QuillEditor} from "../../FalconEditor";

export const QuillEditorField = ({ name, height = 300 }) => {

  return (
      <FinalFormField name={name}>
        {({ input, meta }) => (
          <React.Fragment>
              {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
              <QuillEditor
                  style={{height}}
                  {...input}
              />
          </React.Fragment>
        )}
      </FinalFormField>
  );
};
