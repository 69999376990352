import React, { useEffect } from 'react';
import {LeadCountReport} from "../../components";
import {LeadManagerChartReport} from "../../components/LeadMangerChartReport";

export const HomePage = () => {

  useEffect(() => {
    /*API.ewayReport.managerChart().then((response) => {
      console.log('ewayReport.managerChart',response.data)
    })

    API.ewaySms.balance().then((response) => {
      console.log('ewaySms.balance',response.data)
    })
    API.ewayAuth.info().then((response) => {
      console.log('ewayAuth.info',response.data)
    })*/
  }, []);

  return <React.Fragment>
    <LeadCountReport />
    <LeadManagerChartReport />
  </React.Fragment>
};
