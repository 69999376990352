const moduleState = state => state['Offers/list']

export const items = state => {
    const {items} = moduleState(state)
    return items
}

export const isLoading = state => {
    const {loading} = moduleState(state)
    return loading
}

export const pagination = state => {
    const {total, page, perPage} = moduleState(state)
    return {total, page, perPage}
}