import * as React from 'react';
import { Button, ButtonGroup, Form, Input } from 'reactstrap';
import { Field as FinalFormField, Form as FinalForm } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export const CommentsForm = ({ initialValues, validate, onSubmit }) => (
  <FinalForm
    initialValues={initialValues}
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit, submitting, valid, form }) => {
      return (
        <Form
          onSubmit={event => {
            handleSubmit(event).then(() => {
              form.reset();
            });
          }}
        >
          <FinalFormField name="message">
            {({ input }) => (
              <ButtonGroup className="w-100">
                <Input {...input} type="text" placeholder="Сообщение" />
                <Button
                  size="sm"
                  type={'submit'}
                  color="falcon-success"
                  disabled={submitting || !valid}
                >
                  <FontAwesomeIcon icon={faPaperPlane} transform="shrink-3 down-2" />
                </Button>
              </ButtonGroup>
            )}
          </FinalFormField>
        </Form>
      );
    }}
  />
);
