import React from 'react';
import {Route, Switch} from 'react-router-dom';
import { IndexPage} from "./pages";
import {Helmet} from "react-helmet";

export const Root = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Менеджеры</title>
            </Helmet>
            <Switch>
                <Route path="/users" exact component={IndexPage}/>
            </Switch>
        </React.Fragment>
    );
};
