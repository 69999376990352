import React from 'react';
import {InputField, SelectField} from "../../../../../shared";
import {ROLE_LABELS} from "../../../constants";

export const UserForm = () => {
    return (
        <React.Fragment>
            <InputField name="email" label="Email"/>
            <InputField name="firstName" label="Имя"/>
            <InputField name="lastName" label="Фамилия"/>
            <SelectField name="role" options={ROLE_LABELS} label="Роль"/>
        </React.Fragment>
    );
};
