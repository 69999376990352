import React, { useState } from 'react';
import { Button, Col, Collapse, Media, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ContactForm } from '../ContactForm';

export const Contact = ({ person, companyId, onSave }) => {
  const { name, position, phones = [], emails = [] } = person;
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Media className="mb-3 hover-actions-trigger align-items-center">
          <Media body className="ml-3">
            <Row>
              <Col>
                <h6 className="mb-0 font-weight-semi-bold">{name}</h6>
                <p className="text-500 fs--2 mb-0">{position}</p>
              </Col>
              <Col>
                {phones.map((contact, index) => {
                  const { value } = contact;
                  return (
                    <p key={index} className="font-weight-semi-bold fs--1 mb-0">
                      {value}
                    </p>
                  );
                })}
                {emails.map((contact, index) => {
                  const { value } = contact;
                  return (
                    <p key={index} className="font-weight-semi-bold fs--1 mb-0">
                      {value}
                    </p>
                  );
                })}
              </Col>
            </Row>

            <div className={(isOpen ? '' : 'hover-actions ') + 'r-0 absolute-vertical-center'}>
              <Button
                color={isOpen ? 'falcon-danger' : 'falcon-primary'}
                size="sm"
                onClick={() => setIsOpen(!isOpen)}
              >
                <FontAwesomeIcon icon={isOpen ? faMinus : faEdit} />
              </Button>
            </div>
          </Media>
        </Media>
      </React.Fragment>
      <Collapse isOpen={isOpen}>
        <ContactForm
          companyId={companyId}
          onClose={onClose}
          onSave={() => onSave()}
          initialValues={person}
          showCancel={false}
        />
      </Collapse>
    </React.Fragment>
  );
};
