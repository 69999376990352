import React from 'react';

import * as Yup from 'yup';
import {Form} from 'react-final-form';
import useValidationSchema from "../../../../helpers/use-validation-schema";
import {ContentForm} from "../../components";
import API from "../../../../api";
import {useHistory} from "react-router-dom";

export const CreatePage = () => {
    const history = useHistory();

    const initialValues = {
        id: null,
        name: '',
        description: '',
        image: '',
        active: true
    };

    const schema = Yup.object().shape({
        name: Yup.string().required('Обязательное поле'),
    });

    const validate = useValidationSchema(schema);

    const onSubmit = async values => {
        await API.brands.create(values)
        history.push(`/brands/`);
    };

    return (
        <React.Fragment>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
                render={({handleSubmit, values, submitting, validating, valid}) => {
                    return (
                        <ContentForm
                            handleSubmit={handleSubmit}
                            values={values}
                            submitting={submitting}
                            validating={validating}
                            valid={valid}
                        />
                    );
                }}
            />
        </React.Fragment>
    );
};
