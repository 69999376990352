export const STATUS = {
    WAIT: "wait",
    ACTIVE: "active",
    BLOCKED: "blocked",
}

 /*const STATUS_LABELS = [
    {
        value: STATUS.WAIT,
        label: 'Не подтвержден'
    },
    {
        value: STATUS.ACTIVE,
        label: 'Активен'
    },
    {
        value: STATUS.BLOCKED,
        label: 'Заблокирован'
    },
]*/
