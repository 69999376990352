import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone';

import useValidationSchema from '../../../../helpers/use-validation-schema';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import API from '../../../../api';
import {SignUpForm} from "../../components";

export const SignUpPage = () => {
  const history = useHistory();
  const initialValues = {
    email: '',
    phone: '',
    name: '',
    patronymic: '',
    last_name: '',
    birthdate: '',
    password: '',
    password_confirm: ''
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Обязательное поле')
      .email('Неправильный формат E-mail'),
    phone: Yup.string()
      .phone('RU', true, 'Неверный номер телефона')
      .required('Обязательное поле'),
    name: Yup.string().required('Обязательное поле'),
    patronymic: Yup.string().required('Обязательное поле'),
    last_name: Yup.string().required('Обязательное поле'),
    birthdate: Yup.string().required('Обязательное поле'),
    password: Yup.string().required('Обязательное поле'),
    password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Пароль не совпадает')
  });

  const validate = useValidationSchema(schema);

  const onSubmit = async values => {
    const response = await API.auth.signup(values);
    const { access, success, errors } = response;
    if (success) {
      localStorage.setItem('token', access.token);
      history.push('/');
      toast.success('Аккаунт успешно зарестрирован');
    } else {
      for (let error in errors) {
        if (errors.hasOwnProperty(error)) {
          toast.error(errors[error][0]);
        }
      }
    }
  };

  return (
    <Fragment>
      <Row className="text-left justify-content-between">
        <Col xs="auto">
          <h5>Регистарция аккаунта</h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600" />
        </Col>
      </Row>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values, submitting, validating, valid }) => {
          return (
            <SignUpForm
              handleSubmit={handleSubmit}
              values={values}
              submitting={submitting}
              validating={validating}
              valid={valid}
            />
          );
        }}
      />
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Link className="fs--1" to={`/auth/sign-up`} />
        </Col>
        <Col xs="auto">
          <Link className="fs--1" to={`/auth/sign-in`}>
            Есть аккаунт. Войти.
          </Link>
        </Col>
      </Row>
    </Fragment>
  );
};
