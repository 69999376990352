import React from 'react';
import PropTypes from 'prop-types';
import {ButtonIcon} from "../ButtonIcon";

const ButtonBack = ({onClick}) => (
    <ButtonIcon
        icon="chevron-left"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="mx-2"
        onClick={onClick}
    >
        Назад
    </ButtonIcon>
);

ButtonBack.propTypes = {
    onClick: PropTypes.func.isRequired
};

export {ButtonBack}
