import React from 'react';
import {Button, Card} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

export const Image = ({item, onRemove}) => {
    const {draggableId, image} = item
    return (
        <Card>
            <Button
                style={{
                    top: 5,
                    position: 'absolute',
                    right: 5,
                    fontSize: '0.5rem',
                    paddingLeft: 8,
                    paddingRight: 8,
                }}
                className={'rounded-capsule'}
                onClick={() => onRemove(draggableId)}
                color={'danger'}>
                <FontAwesomeIcon icon={faTrashAlt}/>
            </Button>
            <img src={image} alt=""/>
        </Card>
    );
};
