import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import {Auth, Errors} from "../modules";
import {Dashboard} from "../Dashboard";
import {toast, ToastContainer} from "react-toastify";
import {CloseButton, Fade} from "./Toast";
import {Main} from "./Main";
import {PrivateRoute} from "./PrivateRoute";

export const App: React.FC = () => (
    <Main>
        <Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route path="/auth" component={Auth}/>
                <Route path="/errors" component={Errors}/>
                <PrivateRoute component={Dashboard}/>
                <Redirect to="/errors/404"/>
            </Switch>
            <ToastContainer
                transition={Fade}
                closeButton={<CloseButton/>}
                position={toast.POSITION.BOTTOM_LEFT}
            />
        </Router>
    </Main>
);
