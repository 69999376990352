import React, {Fragment} from 'react';
import {Button, CardHeader, Col, CustomInput, InputGroup, Row} from 'reactstrap';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {Filter} from './Filter';
import {ButtonIcon} from '../../../../../shared';
import {useSelector} from 'react-redux';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {Sheets: {data: ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
};

const SelectedActions = ({actions}) => {
    return (
        <InputGroup size="sm" className="input-group input-group-sm">
            <CustomInput type="select" id="bulk-select">
                <option>Действия</option>
                {actions.map(({label}, index) => (
                    <option key={index} value="delete">
                        {label}
                    </option>
                ))}
            </CustomInput>
            <Button color="falcon-default" size="sm" className="ml-2">
                Применить
            </Button>
        </InputGroup>
    );
};

export const Header = ({title, totalCount, items, onClickAddBtn}) => {
    const selectedStatuses = useSelector(state => state.companies.selectedStatuses);

    const actions = () => {
        if (selectedStatuses.indexOf(15) > -1) {
            if (selectedStatuses.length === 1) {
                return [{label: 'Удалить'}, {label: 'Восстановить'}];
            }
            return [];
        }
        return [{label: 'В корзину'}];
    };

    const debug = false

    return (
        <CardHeader className={'bg-light'}>
            <Row className="align-items-center">
                <Col className={'col-auto'}>
                    <b className={'mr-2'}>{title}</b><sup>{totalCount}</sup>
                </Col>
                <Col>
                    <Filter/>
                </Col>
                <Col className={`text-right`}>
                    {selectedStatuses.length > 0 ? (
                        <SelectedActions actions={actions()}/>
                    ) : (
                        <Fragment>
                            <ButtonIcon
                                icon="plus"
                                transform="shrink-3 down-2"
                                color="falcon-default"
                                size="sm"
                                className="mx-2"
                                onClick={onClickAddBtn}
                            >
                                Добавить
                            </ButtonIcon>
                            {debug &&
                            <ButtonIcon
                                icon="external-link-alt"
                                transform="shrink-3 down-2"
                                color="falcon-default"
                                size="sm"
                                onClick={() => exportToCSV(items, 'export')}
                            >
                                Export
                            </ButtonIcon>
                            }
                        </Fragment>
                    )}
                </Col>
            </Row>
        </CardHeader>
    );
};
