import React from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userAvatar from '../../../../assets/img/team/avatar.png';
import { logOut } from '../../../../state/ducks/auth';
import { useDispatch, useSelector } from 'react-redux';
import {Avatar} from "../../../../shared";
import {Link} from "react-router-dom";

export const ProfileDropdown = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logOut());
  };

  const fullName = useSelector(state => state.auth.fullName);

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav className="pr-0">
        <Avatar src={userAvatar} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem className="font-weight-bold text-warning">
            <FontAwesomeIcon icon="user" className="mr-1" />
            <span>{fullName}</span>
          </DropdownItem>
          <DropdownItem className="font-weight-bold text-warning">
            <Link tag={Link} to={'/profile'}>
              Профиль
            </Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={logout}>Выход</DropdownItem>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
