import instance from '../importInstance';

const URL = 'eway?action=amigo:api.manager.';

export const ewayOffer = {
  async index(page, perPage, company) {
    const response = await instance().get(
        `${URL}offer.index&page=${page}&per_page=${perPage}&company=${company}`
    )
    return response.data
  },
  async get(id) {
    const response = await instance().get(
        `${URL}offer.view&id=${id}`
    )
    return response.data
  },
  async create(values, images) {
    const body = new FormData()
    Object.keys(values).map((key) => {
      return body.append(key, values[key])
    })
    if (images) {
      body.append('images', JSON.stringify(images))
    }
    const response = await instance().post(
        `${URL}offer.create`,
        body,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    )
    return response.data
  },
  async update(values, images) {
    const body = new FormData()
    Object.keys(values).map((key) => {
      return body.append(key, values[key])
    })
    if (images) {
      body.append('images', JSON.stringify(images))
    }
    const response = await instance().post(
        `${URL}offer.update`,
        body,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    )
    return response.data
  },
  async activate(id, status) {
    const response = await instance().get(
        `${URL}offer.activate&id=${id}&status=${status}`
    )
    return response.data.data
  },
  async delete(id) {
    return await instance().get(`${URL}offer.delete&id=${id}`)
  },
  async categoriesTree() {
    const response = await instance().get(`${URL}offer.categoriesTree`)
    return response.data
  },
};
