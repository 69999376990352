import * as React from 'react';
import {CustomInput, Nav, Navbar} from 'reactstrap';
import {useHistory, useParams} from 'react-router-dom';
import {ButtonIcon} from '../../../../../shared';
import {Manager} from "./Manager";
import {MenuItem} from "./MenuItem";
import {useDispatch, useSelector} from "react-redux";
import API from "../../../../../api";
import {fetchCompany} from "../../../../../state/ducks/company";
import {Publication} from "../../../components";

const items = [
        {
            label: 'Инфо',
            url: '',
        },
        {
            label: 'Предложения',
            url: '/offers',
        },
        {
            label: 'Контент',
            url: '/content',
        }

    ]
;

export const Header = () => {

    const dispatch = useDispatch();
    const {companyId} = useParams();

    const {company, content} = useSelector(state => state.company);

    const {id, host, manager, external_id} = company;
    const {ACTIVE} = content;
    const history = useHistory();

    const {isAdmin, isSuperManager} = useSelector(state => state.auth);

    const handleClickBack = () => {
        history.push('/companies');
    };

    const handleActiveClick = async (id, active) => {
        const result = window.confirm(
            active ? 'Деактивировать компанию?' : 'Активировать компанию?'
        )
        if (result) {
            await API.ewayCompany.activate(id, !active);
            dispatch(fetchCompany(companyId))
        }
    }

    const wasNotPublished = (company) => {
        const {publication = {}} = company
        const {status} = publication
        return status === 'not'
    }

    return (
        <React.Fragment>
            <Navbar color="white" light className={'mb-2'}>
                <Nav navbar className="mr-3">
                    <ButtonIcon
                        icon="chevron-left"
                        transform="shrink-3 down-2"
                        color="falcon-default"
                        size="sm"
                        className="mx-2"
                        onClick={handleClickBack}
                    >
                        Назад
                    </ButtonIcon>
                </Nav>
                <div className="mr-3">
                    <a href={'//' + host} rel="noopener noreferrer" target="_blank">
                        {host}
                    </a>
                </div>
                {wasNotPublished(company) && <Publication companyId={companyId}/>}
                {!wasNotPublished(company) &&
                <React.Fragment>
                    <CustomInput
                        id={'company-active'}
                        label={'Активно'}
                        className={'mx-2'}
                        onChange={() => handleActiveClick(external_id, ACTIVE === 'Y')}
                        checked={ACTIVE === 'Y'}
                        type="switch"
                        disabled={!isAdmin && !isSuperManager}
                    />
                    <Nav pills>
                        {items.map(({url, label}, index) => {
                            return <MenuItem
                                key={index}
                                to={`/companies/${companyId}${url}`}
                                label={label}/>;
                        })}
                    </Nav>
                </React.Fragment>
                }
                <Nav navbar className="align-items-center ml-auto">
                    {manager && <Manager companyId={id} manager={manager}/>}
                </Nav>
            </Navbar>
        </React.Fragment>
    );
};
