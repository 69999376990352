import React from 'react';
import styled from 'styled-components';

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-color: #dfdfdf;
  background: ${props =>
    props.index === 2 ? '#f9fafd' : props.index === 1 ? '#edf2f9' : '#f9fafd'};
  border-radius: 3px;
`;

export const Track = (props, state) => <StyledTrack {...props} index={state.index} />;
