import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';

import {IntervalSlider} from './IntervalSlider';

import 'bootstrap-daterangepicker/daterangepicker.css';
import {useDispatch, useSelector} from 'react-redux';

import {
    onChangeCompanyFilterManager,
    onChangeCompanyFilterName,
    onChangeCompanyFilterParamCheck,
    onChangeCompanyFilterParamPublic,
    onChangeCompanyFilterRangeDataCheck,
    onChangeCompanyFilterRangeDataCreate, onChangeCompanyFilterRangeDataPublic,
    onChangeCompanyFilterStatus,
    onChangeCompetitionFilterInterval,
    onChangeCompetitionFilterIntervalMaxValue,
    onChangeCompetitionFilterIntervalMinValue,
    onChangeCountQueryFilterInterval,
    onChangeCountQueryFilterIntervalMaxValue,
    onChangeCountQueryFilterIntervalMinValue,
    onChangeVisitorsFilterInterval,
    onChangeVisitorsFilterIntervalMaxValue,
    onChangeVisitorsFilterIntervalMinValue, onClearCompanyAllFilters
} from '../../../../../../state/ducks/companyFilter';

import {DataRangePicker} from './DataRangePicker';
import API from '../../../../../../api';
import {FilterDropdown} from "../../../../../../shared/FilterDropdown";

const statusOptions = [
    {value: 0, label: 'Новая'},
    {value: 1, label: 'Просмотрена'},
    {value: 2, label: 'В работе'},
    {value: 3, label: 'ЛПР'},
    {value: 4, label: 'Отказ'},
    {value: 5, label: 'Подписание'},
    {value: 6, label: 'Договор'},
    {value: 10, label: 'Неликвид'},
    {value: 15, label: 'На удаление'}
];

const checkParamOptions = [
    {value: 0, label: 'Не проверялся'},
    {value: 5, label: 'В процессе'},
    {value: 10, label: 'Проверен'}
];

const publicParamOptions = [
    {value: 'not_published', label: 'Не публиковалась'},
    {value: 'active', label: 'Активна'},
    {value: 'not_active', label: 'Неактивна'}
];

export const Filter = () => {
    const dispatch = useDispatch();

    const {
        name,
        statusSelected,
        managerSelected,
        paramPublicSelected,
        paramCheckSelected,
        rangeDataCreateStart,
        rangeDataCreateEnd,
        rangeDataCheckStart,
        rangeDataCheckEnd,
        rangeDataPublicStart,
        rangeDataPublicEnd,
        countQueryInterval,
        countQueryIntervalMinValue,
        countQueryIntervalMaxValue,
        competitionInterval,
        competitionIntervalMinValue,
        competitionIntervalMaxValue,
        visitorsInterval,
        visitorsIntervalMinValue,
        visitorsIntervalMaxValue
    } = useSelector(state => state.companyFilter);

    const {countQuery, competition, visitors} = useSelector(state => state.companies);
    const [managers, setManagers] = useState([]);

    const countFilterSelected = () => {
        let result = 0
        if (name) {
            result++
        }
        if (statusSelected) {
            result++
        }
        if (managerSelected) {
            result++
        }
        if (paramPublicSelected) {
            result++
        }
        if (paramCheckSelected) {
            result++
        }
        if (rangeDataCreateStart) {
            result++
        }
        if (rangeDataCreateEnd) {
            result++
        }
        if (rangeDataCheckStart) {
            result++
        }
        if (rangeDataCheckEnd) {
            result++
        }
        if (rangeDataPublicStart) {
            result++
        }
        if (rangeDataPublicEnd) {
            result++
        }
        if (countQueryIntervalMinValue) {
            result++
        }
        if (countQueryIntervalMaxValue) {
            result++
        }
        if (competitionIntervalMinValue) {
            result++
        }
        if (competitionIntervalMaxValue) {
            result++
        }
        if (visitorsIntervalMinValue) {
            result++
        }
        if (visitorsIntervalMaxValue) {
            result++
        }
        return result
    }

    const clearAllFilters = () => {
        dispatch(onClearCompanyAllFilters())
    }

    const {isAdmin, isSuperManager} = useSelector(state => state.auth);

    const fetchUsers = () => {
        API.manager.index().then(response => {
            let result = [];
            for (let key in response.items) {
                if (response.items.hasOwnProperty(key)) {
                    let item = response.items[key];
                    result.push({value: item.id, label: item.fullName});
                }
            }
            setManagers(result);
        });
    };

    useEffect(() => {
       // fetchUsers();
    }, []);

    const onChangePublicParam = selected => {
        dispatch(onChangeCompanyFilterParamPublic(selected));
    };

    const onChangeCheckParam = selected => {
        dispatch(onChangeCompanyFilterParamCheck(selected));
    };

    const onChangeManager = selected => {
        dispatch(onChangeCompanyFilterManager(selected));
    };

    const onChangeStatus = selected => {
        dispatch(onChangeCompanyFilterStatus(selected));
    };

    const onChangeName = event => {
        dispatch(onChangeCompanyFilterName(event.target.value));
    };

    const onChangeDataCreatePeriod = (start, end) => {
        dispatch(
            onChangeCompanyFilterRangeDataCreate(
                start.format('YYYY-MM-DD HH:mm:ss'),
                end.format('YYYY-MM-DD HH:mm:ss')
            )
        );
    };

    const onClearDataCreatePeriod = () => {
        dispatch(onChangeCompanyFilterRangeDataCreate(null, null));
    };

    const onChangeDataCheckPeriod = (start, end) => {
        dispatch(
            onChangeCompanyFilterRangeDataCheck(
                start.format('YYYY-MM-DD HH:mm:ss'),
                end.format('YYYY-MM-DD HH:mm:ss')
            )
        );
    };

    const onClearDataCheckPeriod = () => {
        dispatch(onChangeCompanyFilterRangeDataCheck(null, null));
    };

    const onChangeDataPublicPeriod = (start, end) => {
        dispatch(
            onChangeCompanyFilterRangeDataPublic(
                start.format('YYYY-MM-DD HH:mm:ss'),
                end.format('YYYY-MM-DD HH:mm:ss')
            )
        );
    };

    const onClearDataPublicPeriod = () => {
        dispatch(onChangeCompanyFilterRangeDataPublic(null, null));
    };

    const onChangeCountQueryInterval = value => {
        dispatch(onChangeCountQueryFilterInterval(value));
    };

    const onChangeCountQueryMinValue = value => {
        dispatch(onChangeCountQueryFilterIntervalMinValue(value));
    };

    const onChangeCountQueryMaxValue = value => {
        dispatch(onChangeCountQueryFilterIntervalMaxValue(value));
    };

    const onChangeCompetitionInterval = value => {
        dispatch(onChangeCompetitionFilterInterval(value));
    };

    const onChangeCompetitionMinValue = value => {
        dispatch(onChangeCompetitionFilterIntervalMinValue(value));
    };

    const onChangeCompetitionMaxValue = value => {
        dispatch(onChangeCompetitionFilterIntervalMaxValue(value));
    };

    const onChangeVisitorsInterval = value => {
        dispatch(onChangeVisitorsFilterInterval(value));
    };

    const onChangeVisitorsMinValue = value => {
        dispatch(onChangeVisitorsFilterIntervalMinValue(value));
    };

    const onChangeVisitorsMaxValue = value => {
        dispatch(onChangeVisitorsFilterIntervalMaxValue(value));
    };

    return (
        <React.Fragment>
            <Row noGutters>
                <Col xs={'auto'}>
                    <Form inline className="search-box">
                        <div>
                            <Input
                                value={name}
                                type="search"
                                placeholder="Поиск..."
                                aria-label="Поиск"
                                className="rounded-pill search-input"
                                onChange={onChangeName}
                            />
                            <FontAwesomeIcon
                                icon="search"
                                className="position-absolute text-400 search-box-icon"
                            />
                        </div>
                    </Form>
                </Col>
                <Col xs={'auto'}>
                    <FilterDropdown countFilter={countFilterSelected()} onClearClick={clearAllFilters}>
                        <Select
                            isMulti
                            value={statusSelected}
                            className={'m-1'}
                            onChange={onChangeStatus}
                            options={statusOptions}
                            placeholder={'Статус'}
                            isClearable
                        />
                        <Select
                            isMulti
                            value={paramCheckSelected}
                            className={'m-1'}
                            onChange={onChangeCheckParam}
                            options={checkParamOptions}
                            placeholder={'Проверка'}
                            isClearable
                        />
                        <Select
                            isMulti
                            value={paramPublicSelected}
                            className={'m-1'}
                            onChange={onChangePublicParam}
                            options={publicParamOptions}
                            placeholder={'Публикация'}
                            isClearable
                        />
                        {(isAdmin || isSuperManager) && (
                            <Select
                                isMulti
                                value={managerSelected}
                                className={'m-1'}
                                onChange={onChangeManager}
                                options={managers}
                                placeholder={'Менеджер'}
                                isClearable
                            />
                        )}

                        <DataRangePicker
                            label="Дата добавления"
                            startData={rangeDataCreateStart}
                            endData={rangeDataCreateEnd}
                            onChangeDataPeriod={onChangeDataCreatePeriod}
                            onClearDataPeriod={onClearDataCreatePeriod}
                        />
                        <DataRangePicker
                            label="Дата проверки"
                            startData={rangeDataCheckStart}
                            endData={rangeDataCheckEnd}
                            onChangeDataPeriod={onChangeDataCheckPeriod}
                            onClearDataPeriod={onClearDataCheckPeriod}
                        />
                        <DataRangePicker
                            label="Дата публикации"
                            startData={rangeDataPublicStart}
                            endData={rangeDataPublicEnd}
                            onChangeDataPeriod={onChangeDataPublicPeriod}
                            onClearDataPeriod={onClearDataPublicPeriod}
                        />
                        <IntervalSlider
                            label={'Запросов всего'}
                            interval={countQueryInterval}
                            currentMin={countQueryIntervalMinValue}
                            currentMax={countQueryIntervalMaxValue}
                            onChangeInterval={onChangeCountQueryInterval}
                            onChangeMinValue={onChangeCountQueryMinValue}
                            onChangeMaxValue={onChangeCountQueryMaxValue}
                            min={0}
                            max={countQuery}
                        />
                        <IntervalSlider
                            label={'Конкуренция'}
                            interval={competitionInterval}
                            currentMin={competitionIntervalMinValue}
                            currentMax={competitionIntervalMaxValue}
                            onChangeInterval={onChangeCompetitionInterval}
                            onChangeMinValue={onChangeCompetitionMinValue}
                            onChangeMaxValue={onChangeCompetitionMaxValue}
                            min={0}
                            max={competition}
                        />
                        <IntervalSlider
                            label={'Посетители'}
                            interval={visitorsInterval}
                            currentMin={visitorsIntervalMinValue}
                            currentMax={visitorsIntervalMaxValue}
                            onChangeInterval={onChangeVisitorsInterval}
                            onChangeMinValue={onChangeVisitorsMinValue}
                            onChangeMaxValue={onChangeVisitorsMaxValue}
                            min={0}
                            max={visitors}
                        />
                    </FilterDropdown>
                </Col>
            </Row>
        </React.Fragment>
    );
};
